import { Component, OnInit, OnDestroy } from '@angular/core';
import { BoxesByFilterModel, SwitchBoxRequestModel, GetBoxesRequestModel } from 'src/app/models/shipment.model';
import { ToastService } from 'src/app/core/toast.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popup-switch-box',
  templateUrl: './popup-switch-box.component.html',
  styleUrls: ['./popup-switch-box.component.scss']
})
export class PopupSwitchBoxComponent implements OnInit, OnDestroy {

  public box: BoxesByFilterModel;
  public account: string;
  public isOverszd: number;
  public ship: string;
  public newBox = '';
  public ddBoxesValues: number[];
  private destroy$ = new Subject<void>();

  constructor(
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    public dialogRef: MatDialogRef<PopupSwitchBoxComponent>) { }

  ngOnInit() {
    const data: GetBoxesRequestModel = {
      account: this.account,
      isOverszd: this.isOverszd,
      ship: this.ship
    };
    this.shipmentService.GetListBoxes(data)
      .pipe(takeUntil(this.destroy$)).subscribe((value: number[]) => {
        this.ddBoxesValues = [...value];
        const index = this.ddBoxesValues.indexOf(this.box.firstBox);
        if (index > -1) {
          this.ddBoxesValues.splice(index, 1);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onSaveClick() {
    const data: SwitchBoxRequestModel = {
      skus: [this.box.sku],
      ships: [this.ship],
      accounts: [this.account],
      isOverszds: [this.isOverszd],
      oldBoxes: [this.box.firstBox],
      newBoxes: [this.newBox ? parseInt(this.newBox, 10) : null],
      qtys: [this.box.qty]
    };
    this.shipmentService.SwitchBox(data)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.toastService.showToaster(`Box switched successfully.`);
        this.dialogRef.close(true);
      });
  }

  public closePopup() {
    this.dialogRef.close();
  }

}
