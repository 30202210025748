import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportComponent } from './report/report.component';
import { InventoryHomeComponent } from './inventory-home/inventory-home.component';


const routes: Routes = [
  { path: '', component: ReportComponent },
  { path: 'inventory-home', component: InventoryHomeComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
