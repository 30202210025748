import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentService } from '../../../services/shipment.service';
import { AuthService } from '../../../services/auth.service';
import { PalletLabelRequestModel } from 'src/app/models/shipment.model';
import { saveAs } from 'file-saver';
import { MainService } from '../../../services/main.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-popup-create-pallets',
  templateUrl: './popup-create-pallets.component.html',
  styleUrls: ['./popup-create-pallets.component.scss']
})
export class PopupCreatePalletsComponent implements OnDestroy {

  public lableType = 'PackageLabel_Plain_Paper';
  public amountToPrint: string;
  public shipmentId: string;
  public account: string;
  public externalMode: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PopupCreatePalletsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentService: ShipmentService,
    private authService: AuthService,
    public main: MainService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onDownloadClick() {
    const user = this.authService.getUserInfo();
    const data: PalletLabelRequestModel = {
      userId: user.userId,
      tenantId: user.tenantId,
      account: this.account,
      shipmentId: this.shipmentId,
      amountToPrint: parseFloat(this.amountToPrint),
      lableType: this.lableType
    };
    this.dialogRef.close(data);
    if (this.externalMode) return;
    this.shipmentService.PalletLabel(data)
      .subscribe((value: any) => {
        const contentDisposition = value.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        const fileName = (matches[1] || 'untitled').trim();
        saveAs(value.body, fileName);
      });
  }

  public closePopup() {
    this.dialogRef.close();
  }


  public incDecValue(incDec, model) {
    const value = this[model] !== undefined ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === undefined ? 1 : value + 1 : this[model] === undefined ? 0 : value > 0 ? value - 1 : 0;
  }

}
