import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-popup-configure-column',
    templateUrl: './popup-configure-column.component.html',
    styleUrls: ['./popup-configure-column.component.scss']
})
export class PopupConfigureColumnComponent implements OnInit {
    constructor() {
    }

    @Input() public columns: any;
    @Input() showColumn: any;
    @Input() columnLength: any;
    @Input() tableHeading: any;
    @Output() showColumnResult: EventEmitter<any[]> = new EventEmitter();
    public selectedArray: number[] = [];
    public selectedAll: boolean;


    ngOnInit() {
        if (this.showColumn) {
            this.selectedArray = this.showColumn;
        } else {
            this.selectedArray = [...Array(this.tableHeading.length).fill(0).map((d, i) => i + 1)];
        }
        this.calculateSelectedAll();
    }

    public selectAllChange() {
        if (this.selectedAll) {
            this.selectAll();
        } else {
            this.removeAll();
        }
    }

    public selectColumns(no) {
        const addToArray = () => this.selectedArray.push(no);
        const removeFromArray = (i) => this.selectedArray.splice(i, 1);
        this.selectedArray.indexOf(no) === -1 ? addToArray() : removeFromArray(this.selectedArray.indexOf(no));
        this.showColumnResult.emit(this.columns);
    }
    updated = () => {
        this.calculateSelectedAll();
        this.showColumnResult.emit(this.columns);
    }
    public selectAll() {
        this.columns = this.columns.map(x => {
            x.visible = true;
            return x;
        });
        this.showColumnResult.emit(this.columns);
    }

    public removeAll() {
        this.columns = this.columns.map(x => {
            x.visible = false;
            return x;
        });
        this.showColumnResult.emit(this.columns);
    }

    drop($event: CdkDragDrop<any>) {
        moveItemInArray(this.columns, $event.previousIndex, $event.currentIndex);
        this.columns.forEach((el, i) => {
            el.displayOrder = i + 1;
        });
        this.showColumnResult.emit(this.columns);
    }

    private calculateSelectedAll() {
        if (this.columns) {
            const cnt = this.columns.filter(x => x.visible === true).length;
            if (cnt === this.columns.length) {
                this.selectedAll = true;
            } else {
                this.selectedAll = false;
            }
        }
    }
}

