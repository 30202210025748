import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';
import { MatDialogRef } from '@angular/material/dialog';
import { NewMapPriceSchedulePopUpComponent } from '../new-map-price-schedule-pop-up/new-map-price-schedule-pop-up.component';
import { MapPricingScheduleDateRangeComponent } from '../map-pricing-schedule-date-range/map-pricing-schedule-date-range.component';
import { MapPricingScheduleWeeklyComponent } from '../map-pricing-schedule-weekly/map-pricing-schedule-weekly.component';
import { PricingService } from 'src/app/services/pricing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-pricingmodelsettings',
  templateUrl: './pricingmodelsettings.component.html',
  styleUrls: ['./pricingmodelsettings.component.scss']
})
export class PricingmodelsettingsComponent implements OnInit {
  private destroy$ = new Subject<void>();
  showRepBody = false;
  showBuisnessBody = false;
  showOversockBody = false;
  showotherSetBody = false;
  showModelRules = false;

  public id: number;
  public name: string;
  public fvf: number;
  public fvfIf: boolean;
  public fvm: number;
  public fvmIf: boolean;
  public fva: number;
  public fvaIf: boolean;
  public mvf: number;
  public mvfIf: boolean;
  public mvm: number;
  public mvmIf: boolean;
  public mva: number;
  public mvaIf: boolean;
  public mvmYf: number;
  public mvmYfIf: boolean;
  public noBb: number;
  public minutes: number;
  public last: string;
  public ison: boolean;
  public minIsCost: boolean;
  public isCostDefault: boolean;
  public underMin: boolean;
  public isOverStocked: boolean;
  public isCostDefault90: boolean;
  public isCostDefault180: boolean;
  public cos180: number;
  public cos180If: boolean;
  public isCostDefault365: boolean;
  public cos365: number;
  public cos365If: boolean;
  public isBsn: boolean;
  public isShipsLater: boolean;
  public bsnPrc: number;
  public bsnIf: boolean;
  public bsnMin: number;
  public bsnMinIf: boolean;
  public bsnMax: number;
  public bsnMaxIf: boolean;
  public bsn1: number;
  public bsn1Qt: number;
  public bsn1If: boolean;
  public bsn2: number;
  public bsn2Qt: number;
  public bsn2If: boolean;
  public bsn3: number;
  public bsn3Qt: number;
  public bsn3If: boolean;
  public bsn4: number;
  public bsn4Qt: number;
  public bsn4If: boolean;
  public bsn5: number;
  public bsn5Qt: number;
  public bsn5If: boolean;
  public lowVelDay: number;
  public bbVsMax: boolean;
  public mVsAdFlt: boolean;
  public minVsAdFlt: boolean;
  public hideMap: boolean;
  public getBb: boolean;
  public sfpIsa: boolean;
  public getBball: boolean;
  public ovrIgn: number;
  public sfpIsBb: boolean;
  public tmpHd: boolean = null;
  public tmpMp: boolean = null;
  public type: any;

  constructor(
    public dialogRef: MatDialogRef<PricingmodelsettingsComponent>,
    public main: MainService,
    public priceService: PricingService
  ) { }

  ngOnInit() {
  }

  toggleRepBody() {
    this.showRepBody = !this.showRepBody;
  }

  toggleBusinessBody() {
    this.showBuisnessBody = !this.showBuisnessBody;
  }

  toggleOversockBody() {
    this.showOversockBody = !this.showOversockBody;
  }

  toggleOtherSettingsBody() {
    this.showotherSetBody = !this.showotherSetBody;
  }

  toggleModelRules() {
    this.showModelRules = !this.showModelRules;
  }

  closePopup() {
    this.dialogRef.close();
  }

  openNewPopUp() {
    this.main.openBroadcastPopup(NewMapPriceSchedulePopUpComponent, 'auto', 480, '90%', 'new_map_price_schedule');
  }

  openPricingScheduleDateRangePopUp() {
    this.main.openBroadcastPopup(MapPricingScheduleDateRangeComponent, 'auto', 450, '90%', 'new_map_price_schedule');
  }

  openPricingScheduleWeeklyPopUp() {
    this.main.openBroadcastPopup(MapPricingScheduleWeeklyComponent, 'auto', 450, '90%', 'new_map_price_schedule');
  }

  public save() {

    const data = {
      name: this.name,
      fvf: Number(this.fvf),
      fvfIf: this.toBoolean(Number(this.fvfIf)),
      fvm: Number(this.fvm),
      fvmIf: this.toBoolean(Number(this.fvmIf)),
      fva: Number(this.fva),
      fvaIf: this.toBoolean(Number(this.fvaIf)),
      mvf: Number(this.mvf),
      mvfIf: this.toBoolean(Number(this.mvfIf)),
      mvm: Number(this.mvm),
      mvmIf: this.toBoolean(Number(this.mvmIf)),
      mva: Number(this.mva),
      mvaIf: this.toBoolean(Number(this.mvaIf)),
      mvmYf: Number(this.mvmYf),
      mvmYfIf: this.toBoolean(Number(this.mvmYfIf)),
      noBb: Number(this.noBb),
      minutes: Number(this.minutes),
      last: this.last,
      ison: this.toBoolean(Number(this.ison)),
      minIsCost: this.toBoolean(Number(this.minIsCost)),
      isCostDefault: this.toBoolean(Number(this.isCostDefault)),
      underMin: this.toBoolean(Number(this.underMin)),
      isOverStocked: this.toBoolean(Number(this.isOverStocked)),
      isCostDefault90: this.toBoolean(Number(this.isCostDefault90)),
      isCostDefault180: this.toBoolean(Number(this.isCostDefault180)),
      cos180: Number(this.cos180),
      cos180If: this.toBoolean(Number(this.cos180If)),
      isCostDefault365: this.toBoolean(Number(this.isCostDefault365)),
      cos365: Number(this.cos365),
      cos365If: this.toBoolean(Number(this.cos365If)),
      isBsn: this.toBoolean(Number(this.isBsn)),
      isShipsLater: this.toBoolean(Number(this.isShipsLater)),
      bsnPrc: Number(this.bsnPrc),
      bsnIf: this.toBoolean(Number(this.bsnIf)),
      bsnMin: Number(this.bsnMin),
      bsnMinIf: this.toBoolean(Number(this.bsnMinIf)),
      bsnMax: Number(this.bsnMax),
      bsnMaxIf: this.toBoolean(Number(this.bsnMaxIf)),
      bsn1: Number(this.bsn1),
      bsn1Qt: Number(this.bsn1Qt),
      bsn1If: this.toBoolean(Number(this.bsn1If)),
      bsn2: Number(this.bsn2),
      bsn2Qt: Number(this.bsn2Qt),
      bsn2If: this.toBoolean(Number(this.bsn2If)),
      bsn3: Number(this.bsn3),
      bsn3Qt: Number(this.bsn3Qt),
      bsn3If: this.toBoolean(Number(this.bsn3If)),
      bsn4: Number(this.bsn4),
      bsn4Qt: Number(this.bsn4Qt),
      bsn4If: this.toBoolean(Number(this.bsn4If)),
      bsn5: Number(this.bsn5),
      bsn5Qt: Number(this.bsn5Qt),
      bsn5If: this.toBoolean(Number(this.bsn5If)),
      lowVelDay: Number(this.lowVelDay),
      bbVsMax: this.toBoolean(Number(this.bbVsMax)),
      mVsAdFlt: this.toBoolean(Number(this.mVsAdFlt)),
      minVsAdFlt: this.toBoolean(Number(this.minVsAdFlt)),
      hideMap: this.toBoolean(Number(this.hideMap)),
      getBb: this.toBoolean(Number(this.getBb)),
      sfpIsa: this.toBoolean(Number(this.sfpIsa)),
      getBball: this.toBoolean(Number(this.getBball)),
      ovrIgn: Number(this.ovrIgn),
      sfpIsBb: this.toBoolean(Number(this.sfpIsBb)),
      tmpHd: this.tmpHd,
      tmpMp: this.tmpMp,

    };
    console.log(data);
    this.priceService.savePrcModel(data).pipe(takeUntil(this.destroy$)).subscribe((value) => {
      console.log(value);
    });
  }

  public toBoolean(value: number) {
    return Boolean(value);
  }


}
