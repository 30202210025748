





import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-no-functional-table',
  templateUrl: './no-functional-table.component.html',
  styleUrls: ['./no-functional-table.component.scss']
})
export class NoFunctionalTableComponent implements OnInit {
  @Input() keys: string[];
  @Input() objkeys: string[];
  @Input() arrayData: any[];
  @ViewChild('tableHeader') tableHeader: ElementRef;

  constructor() { }

  ngOnInit() {

  }

  public scroll($e) {
    this.tableHeader.nativeElement.scrollLeft = $e.target.scrollLeft;
  }

}

