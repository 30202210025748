import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-percent-inc-dec',
  templateUrl: './percent-inc-dec.component.html',
  styleUrls: ['./percent-inc-dec.component.scss']
})
export class PercentIncDecComponent implements OnInit, OnChanges {
  @Input() control: any;
  @Input() total: number;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();

  public inputValue: number = null;
  public temp: number = null;

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    this.inputValue = this.control.value;
    console.log(this.total);
  }

  public changeValue() {
    this.valueChange.emit(this.inputValue);
  }

  public incDecValue(incDec, model) {
    const value = this[model] !== null ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === null ? 1 : value + 1 : this[model] === null ? 0 : value > 0 ? value - 1 : 0;
    this.changeValue();
  }

}
