import { Component, OnInit, OnDestroy } from '@angular/core';
import { SkuLabelModel, SkuModel, GetSkuLabelRequestModel } from 'src/app/models/shipment.model';
import { PrintService } from 'src/app/services/print.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { take, takeUntil, switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-bulk-sku-label-print',
  templateUrl: './bulk-sku-label-print.component.html',
  styleUrls: ['./bulk-sku-label-print.component.scss']
})
export class BulkSkuLabelPrintComponent implements OnInit, OnDestroy {
  skuLabels: SkuLabelModel[];
  skuModels: SkuModel[];
  total: number;
  numbers: number[];
  calls: any[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    private printService: PrintService,
    private shipmentService: ShipmentService
  ) { }

  ngOnInit() {
    this.printService.arrayData$.pipe(
      switchMap((value: SkuModel[]) => {
        if (value) {
          this.skuModels = value;
          const data: GetSkuLabelRequestModel = {
            skus: [],
            accounts: []
          };
          value.forEach(element => {
            data.skus.push(element.skuId);
            data.accounts.push(element.account);
          });

          return this.shipmentService.GetSkuLabel(data);
        } else {
          return of([]);
        }
      }),
      take(1),
      takeUntil(this.destroy$)
    ).subscribe((value: SkuLabelModel[]) => {
      this.skuLabels = [...value];
      this.skuLabels.forEach(el => {
        el.amt = _.find(this.skuModels, {fnSku: el.id}).qty || 0;
        el.amtArray = this.getArray(_.find(this.skuModels, {fnSku: el.id}).qty || 0);
      })
      this.printService.onDataReady();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  getArray = (count) => {
    count = parseInt(count, 10)
    return Array(count).fill(0).map((x, i) => i);
  }
  public getTotal(index: number) {
    if (this.skuModels[index].qprnt && this.skuModels[index].qprnt > 0) {
      this.total = this.skuModels[index].qprnt;
    } else {
      this.total = 0;
    }
    return Array(this.total).fill(0).map((x, i) => i);
  }

  public getBarcode(value: SkuLabelModel) {
    return value.idType + ': ' + value.id + ' - new ';
  }

}
