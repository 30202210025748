import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-order-import-data',
  templateUrl: './popup-order-import-data.component.html',
  styleUrls: ['./popup-order-import-data.component.scss']
})
export class PopupOrderImportDataComponent implements OnInit {

  public sampleArray: any[] = [1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7];
  public sampleArray2: any[] = [1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7, 1, 2, 3, 4, 5, 6, 7];
  constructor() { }


  ngOnInit() {
  }


}
