import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-header-bulk-popup-two',
  templateUrl: './header-bulk-popup-two.component.html',
  styleUrls: ['./header-bulk-popup-two.component.scss']
})
export class HeaderBulkPopupTwoComponent implements OnInit {

  constructor(private dialog: MatDialogRef<HeaderBulkPopupTwoComponent>) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialog.close();
  }

}
