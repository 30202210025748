import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private url = 'api/settings';

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  options = { headers: this.headers };

  constructor(private http: HttpClient) { }

  public GetAllAccounts() {
    return this.http.get(`${environment.apiUrl}/${this.url}/getallaccounts`, this.options);
  }

  public GetAllVendors() {
    return this.http.get(`${environment.apiUrl}/${this.url}/getallvendors`,this.options);
  }

  public GetAllWarehouses() {
    return this.http.get(`${environment.apiUrl}/${this.url}/getallwarehouses`, this.options);
  }

  public GetWarehouse(id: any) {
    return this.http.get(`${environment.apiUrl}/${this.url}/getwarehouse/${id}`, this.options);
  }

  public DeleteAccount(acccount: any) {
    return this.http.delete(`${environment.apiUrl}/${this.url}/deleteaccount/${acccount}`, this.options);
  }

  public DeleteWh(id: any) {
    return this.http.delete(`${environment.apiUrl}/${this.url}/deletewh/${id}`, this.options);
  }

  public UpdateWh(request: any) {
    const data = JSON.stringify(request);
    return this.http.put(`${environment.apiUrl}/${this.url}/updatewh`, data, this.options);
  }

  public ToggleWhsIsFba(id: string) {
    return this.http.put(`${environment.apiUrl}/${this.url}/togglewhsisfba/${id}`, this.options);
  }

}
