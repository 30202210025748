import { Component, OnInit, OnDestroy } from '@angular/core';
import { SkuModel } from 'src/app/models/shipment.model';
import { Subject } from 'rxjs';
import { PrintService } from 'src/app/services/print.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pick-list-print',
  templateUrl: './pick-list-print.component.html',
  styleUrls: ['./pick-list-print.component.scss']
})
export class PickListPrintComponent implements OnInit, OnDestroy {
  skuModels: SkuModel[];
  private destroy$ = new Subject<void>();

  constructor(private printService: PrintService) { }

  ngOnInit() {
    this.printService.arrayData$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: SkuModel[]) => {
      this.skuModels = [...value];
      this.printService.onDataReady();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
