import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-order-return-popup',
  templateUrl: './order-return-popup.component.html',
  styleUrls: ['./order-return-popup.component.scss']
})
export class OrderReturnPopupComponent implements OnInit {
public orderType = 'tab-orderreturn-return';
  constructor() { }

  ngOnInit() {
  }

}
