import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { InventorySkuSortEnum } from '../enums/inventory-sku-sort-enum';

@Injectable({
  providedIn: 'root'
})
export class InventorySortService {

  public skuSortColumn$ = new Subject<InventorySkuSortEnum>();
  public skuSortColumnCurrent$ = new BehaviorSubject<InventorySkuSortEnum>(null);
  public showSendAll$ = new BehaviorSubject<any>(null);

  constructor() { }
}
