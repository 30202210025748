import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InventoryService } from '../../../services/inventory.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-inventory-new-vendor',
  templateUrl: './popup-inventory-new-vendor.component.html',
  styleUrls: ['./popup-inventory-new-vendor.component.scss']
})
export class PopupInventoryNewVendorComponent implements OnInit {
  public vendorForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    public inServ: InventoryService,
    public dialogRef: MatDialogRef<PopupInventoryNewVendorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }
  public totalOn = 0;
  public totalMa = 0;
  ngOnInit() {
    this.vendorForm = this.fb.group({
      Sid: [null],
      Id: [null],
      Name: [null],
      Address: [null],
      Phone: [null],
      Phone2: [null],
      Email: [null],
      On1: [null],
      On2: [null],
      On3: [null],
      On4: [null],
      LeadDays: [null],
      PurchaseDays: [null],
      IsManuFact: [true],
      ManufDays: [null],
      Ma1: [null],
      Ma2: [null],
      Ma3: [null],
      Ma4: [null],
      Url: [null],
      AccountNumber: [null],
      City: [null],
      State: [null],
      Zip: [null],
      Country: [null],
      ContactName: [null],
      ContactPhone: [null],
      Fax: [null],
      Notes: [null]
    });

    if (this.data.type === 'edit') {
      const {
        sid, id, name, address, phone, phone2, email, on1, on2, on3, on4,
        leadDays, purchaseDays, isManuFact, manufDays, ma1, ma2, ma3, ma4
      } = this.data;
      const tempObject2 = this.upperLowerViceVersa({
        sid, id, name, address, phone, phone2, email, on1, on2, on3, on4, leadDays,
        purchaseDays, isManuFact, manufDays, ma1, ma2, ma3, ma4
      });
      this.vendorForm.setValue(tempObject2);
      this.totalMa = this.totalOn = 100;
    }

  }

  public valueChange($e, param) {
    console.log(param);
    const obj = {};
    obj[param] = $e;
    this.vendorForm.patchValue({ ...obj });
    this.vendorForm.get(param).markAsDirty();
    this.vendorForm.get(param).markAsTouched();

    if (param !== 'LeadDays' && param !== 'PurchaseDays' && param !== 'ManufDays') {
      const name = [...param.split('')]; name.pop();
      this.commonChange(name);
    }

  }

  public commonChange(name) {
    console.log(name);
    const joined = [...name].join('');
    let total = 0;
    for (let i = 1; i <= 4; i++) {
      total = total + this.vendorForm.get(joined + i).value;
    }
    this['total' + joined] = total;
  }


  public submit(type = 'add') {
    const data = type === 'add' ? this.inServ.addNewVendor(this.vendorForm.value) : this.inServ.editNewVendor(this.vendorForm.value);
    this.submitData(data);
  }

  public submitData(data) {
    data.subscribe(res => {
      if (res === null) {
        console.log(res);
        this.closePopup(true); this.inServ.vendorEditDataSync.next({});
      }
    });
  }


  public closePopup(refresh = false) {
    this.dialogRef.close(refresh);
  }

  public upperLowerViceVersa(data, type = 'upper') {
    const tempObject = { ...data };
    const tempArray = Object.keys(tempObject);
    let tempObject2 = {};
    tempArray.forEach(key => tempObject2 = {
      ...tempObject2,
      ...{
        [(type === 'upper' ? key.split('')[0].toUpperCase() :
          key.split('')[0].toLowerCase()) + key.split('').slice(1).join('')]: tempObject[key]
      }
    });
    return tempObject2;
  }


}
