import { Component, OnInit } from '@angular/core';
import { PopupOrderImportDataComponent } from '../popup-order-import-data/popup-order-import-data.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-popup-order-import-orders',
  templateUrl: './popup-order-import-orders.component.html',
  styleUrls: ['./popup-order-import-orders.component.scss']
})
export class PopupOrderImportOrdersComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }


  public openOrderImportData() {
    this.openBroadcastPopup(PopupOrderImportDataComponent, 'auto', 1236, '90%', '');
  }
  public openBroadcastPopup(component: any, height: any, width: number, maxHeight: string, customClass: string) {
    this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '95vw',
      panelClass: customClass !== undefined ? customClass : ''
    });

  }
}
