import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupInventoryNewSeasonComponent } from '../popup-inventory-new-season/popup-inventory-new-season.component';

@Component({
  selector: 'app-popup-inventory-set-season',
  templateUrl: './popup-inventory-set-season.component.html',
  styleUrls: ['./popup-inventory-set-season.component.scss']
})
export class PopupInventorySetSeasonComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }


  public openInventoryNewSeason() {
    this.openBroadcastPopup(PopupInventoryNewSeasonComponent, 'auto', 672, '90%', 'bulk_two_popup');
  }

  public openBroadcastPopup(component: any, height: any, width: number, maxHeight: string, customClass: string) {
    this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '92vw',
      panelClass: customClass !== undefined ? customClass : ''
    });

  }

}
