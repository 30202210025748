import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-print-box-label-two',
  templateUrl: './print-box-label-two.component.html',
  styleUrls: ['./print-box-label-two.component.scss']
})
export class PrintBoxLabelTwoComponent implements OnInit {

  constructor(private dialogref: MatDialogRef<PrintBoxLabelTwoComponent>) { }

  ngOnInit() {
  }

  closeDIalog() {
    this.dialogref.close();
  }
}
