import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-create-new-box',
  templateUrl: './create-new-box.component.html',
  styleUrls: ['./create-new-box.component.scss']
})
export class CreateNewBoxComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<CreateNewBoxComponent>) { }

  ngOnInit() {
  }

  public closePopup() {
    this.dialogRef.close();
  }

}
