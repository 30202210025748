import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ShipmentService } from '../../../services/shipment.service';
import { CloseShipRequestModel } from 'src/app/models/shipment.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-close-shipment',
  templateUrl: './close-shipment.component.html',
  styleUrls: ['./close-shipment.component.scss']
})
export class CloseShipmentComponent implements OnDestroy {
  public qty = 1;
  public checkedOne = true;
  public checkedTwo = false;
  public shipmentId: string;
  public account: string;
  private destroy$ = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<CloseShipmentComponent>,
    private shipmentService: ShipmentService,
    private authService: AuthService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }


  public incDecValue(incDec, model) {
    const value = this[model] !== undefined ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === undefined ? 1 : value + 1 : this[model] === undefined ? 0 : value > 0 ? value - 1 : 0;
  }

  public changeChecked($e, type) {
    $e.preventDefault();
    if (type === 'checkedTwo') {
      if (this.checkedOne === true) { this.checkedTwo = !this.checkedTwo; }
    } else {
      this.checkedOne = !this.checkedOne;
      if (this.checkedOne === false) { this.checkedTwo = false; }
    }
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public onCloseShipClick() {
    if (this.account && this.shipmentId) {
      const user = this.authService.getUserInfo();
      const data: CloseShipRequestModel = {
        userId: user.userId,
        tenantId: user.tenantId,
        account: this.account,
        shipmentId: this.shipmentId
      };
      // this.shipmentService.CloseShip(data).pipe(takeUntil(this.destroy$)).subscribe((value: string) => {});
      this.shipmentService.CloseShip(data).subscribe((value: string) => { this.dialogRef.close(value); });
    }
  }

}
