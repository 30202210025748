import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainService } from '../../../services/main.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InventoryService } from '../../../services/inventory.service';

@Component({
  selector: 'app-popup-inventory-items-new-vendor',
  templateUrl: './popup-inventory-items-new-vendor.component.html',
  styleUrls: ['./popup-inventory-items-new-vendor.component.scss']
})
export class PopupInventoryItemsNewVendorComponent implements OnInit {
  public testForm: FormGroup;
  public id: string;
  public searchData: any[] = [];
  public searchDataInserted: any[] = [];
  public httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
  };
  public setTimeoutInter: any;

  constructor(
    private fb: FormBuilder,
    public main: MainService,
    public inServ: InventoryService,
    public dialogRef: MatDialogRef<PopupInventoryItemsNewVendorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private http: HttpClient) { }

  ngOnInit() {
    this.testForm = this.fb.group({
      ItemId: [null],
      Id: [null, Validators.required],
      Name: [null],
      VendId: [null],
      Vendor: [null],
      Qty: [null],
      FbmQty: [null],
      Price: [null],
      CaseQ: [null],
      Length: [null],
      Height: [null],
      Width: [null],
      Weight: [null],
      Enforce: [0],
      Note: [null],
      ItemGrp: [null]
    });

    if (this.data.type === 'edit') {
      const {
        itemId, id, name, vendId, vendor, qty, fbmQty,
        price, caseQ, length, height, width, weight,
        enforce, note, itemGrp } = this.data;
      const tempObject2 = this.upperLowerViceVersa({
        itemId, id, name, vendId, vendor, qty, fbmQty, price, caseQ, length, height,
        width, weight, enforce, note, itemGrp
      });
      this.id = id; this.testForm.setValue(tempObject2);
    }
  }

  public valueChange($e, param) {
    const obj = {};
    obj[param] = $e;
    this.testForm.patchValue({ ...obj });
    this.testForm.get(param).markAsDirty();
    this.testForm.get(param).markAsTouched();
  }

  public submit(type = 'add') {
    const tempData = { ...this.testForm.value, ...{ Enforce: parseInt(this.testForm.value.Enforce, 10) } };
    const ItemsIdTemp = this.searchDataInserted.map(item => item.id);
    const data = { ...tempData, ...{ ItemsId: ItemsIdTemp } };
    if (type === 'add') {
      console.log(data);
      this.inServ.addInventoryItems(data).subscribe(res => {
        if (res === null) {
          console.log(res);
          this.closePopup(true);
        }
      });
    } else {
      this.inServ.editInventoryItems(data).subscribe(res => {
        if (res === null) {
          const newData = this.upperLowerViceVersa(tempData, 'lower');
          this.inServ.itemEditDataSync.next(newData); this.closePopup(true);
        }
      });
    }
  }

  public searchItem(value) {
    if (value !== '') {
      clearTimeout(this.setTimeoutInter);
      this.setTimeoutInter = setTimeout(() => {
        this.inServ.searchItem(value).subscribe(res => {
          this.searchData = [...this.searchData, ...res];
        });
      }, 1000);
    } else {
      this.searchData = [...[]];
    }
  }

  public addToSearchDataInserted(data) {
    const index = this.searchDataInserted.findIndex(obj => obj.id === data.id);
    if (index === -1) { this.searchDataInserted = [...this.searchDataInserted, ...[data]]; }
  }

  public deleteFromSearchDataInserted(i) {
    const tempData = [...this.searchDataInserted]; tempData.splice(i, 1);
    this.searchDataInserted = [...tempData];
  }

  public upperLowerViceVersa(data, type = 'upper') {
    const tempObject = { ...data };
    const tempArray = Object.keys(tempObject);
    let tempObject2 = {};
    tempArray.forEach(key => tempObject2 = {
      ...tempObject2,
      ...{
        [(type === 'upper' ? key.split('')[0].toUpperCase() :
          key.split('')[0].toLowerCase()) + key.split('').slice(1).join('')]: tempObject[key]
      }
    });
    return tempObject2;
  }

  public closePopup(refresh = false) {
    this.dialogRef.close(refresh);
  }

  public clearSearchItem(searchInput) {
    searchInput.value = '';
    this.searchData = [...[]];
  }
}
