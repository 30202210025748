import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopupOrderImportOrdersComponent } from '../../shared/popup-order-import-orders/popup-order-import-orders.component';
import { MatDialog } from '@angular/material/dialog';
import { PopupOrderNewOrderComponent } from '../../shared/popup-order-new-order/popup-order-new-order.component';
import { PopupOrderNewCustomerComponent } from '../../shared/popup-order-new-customer/popup-order-new-customer.component';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit, OnDestroy {
  public sidebarTopic = '';
  public slideToggle = false;
  public reportType = 'inventory';
  public paramType = 'order';
  private destroy$ = new Subject<void>();

  constructor(public dialog: MatDialog, private aroute: ActivatedRoute) { }

  ngOnInit() {
    this.aroute.queryParams.pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        this.reportType = params.type === undefined ? 'inventory' : 'home';
      });
  }

  public openPopupOrderImportOrders() {
    this.openBroadcastPopup(PopupOrderImportOrdersComponent, 'auto', 1236, '90%', 'inventory_sku_error');
  }

  public openBroadcastPopup(component: any, height: any, width: number, maxHeight: string, customClass: string) {
    this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '95vw',
      panelClass: customClass !== undefined ? customClass : ''
    });

  }

  public openPopupOfOrders(param) {
    if (param === 'orders') {
      this.openBroadcastPopup(PopupOrderNewOrderComponent, 'auto', 922, '90%', 'oder_new_orders');
    } else if (param === 'customers') {
      this.openBroadcastPopup(PopupOrderNewCustomerComponent, 'auto', 604, '90%', 'order_new_customers');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
