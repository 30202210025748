import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from '../../../services/inventory.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-inventory-sku-error',
  templateUrl: './inventory-sku-error.component.html',
  styleUrls: ['./inventory-sku-error.component.scss']
})
export class InventorySkuErrorComponent implements OnInit, OnDestroy {

  public skuErrorData: any;
  public skuErrorCount: number;
  public isLoading: boolean;
  public accounts: any;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<InventorySkuErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private inventoryService: InventoryService,
    private settingsService: SettingsService
  ) { }

  ngOnInit() {
    this.settingsService.GetAllAccounts().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.accounts = res;
    });
    this.inventorySKUErrorsFetch();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public inventorySKUErrorsFetch() {
    this.inventoryService.GetSkuErrors()
      .pipe(takeUntil(this.destroy$)).subscribe(res => {
        this.skuErrorData = res;
        this.skuErrorCount = Object.keys(res).length;
        this.isLoading = false;
      });
  }

  public dismisSkuError(item: any) {
    if (item !== 'dismiss_all') {
      this.isLoading = true;
      this.inventoryService.SkuErrorsDismiss(item.sku, item.account, item.shipGoodType)
        .pipe(takeUntil(this.destroy$)).subscribe(res => {
          if (res === null) {
            this.inventorySKUErrorsFetch();
          } else {
            this.isLoading = false;
          }
        });
    }
  }
}
