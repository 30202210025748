import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-ch-settings-tab',
  templateUrl: './ch-settings-tab.component.html',
  styleUrls: ['./ch-settings-tab.component.scss']
})
export class ChSettingsTabComponent implements OnInit {
  @Output() closeEv = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() { }

  onCancel() {
    this.closeEv.emit();
  }
}
