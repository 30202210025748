import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from '../../../services/main.service';
import { ChSettingsComponent } from '../../channels/ch-settings/ch-settings.component';
@Component({
  selector: 'app-addnewchannel',
  templateUrl: './addnewchannel.component.html',
  styleUrls: ['./addnewchannel.component.scss']
})
export class AddnewchannelComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AddnewchannelComponent>, private main: MainService) { }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addNewCHannel() {
    this.main.openBroadcastPopup(ChSettingsComponent, 'auto', 500, '90%', 'add_new_channel_popup_sc', true);
  }

}
