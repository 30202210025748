import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-common-search-new',
  templateUrl: './common-search-new.component.html',
  styleUrls: ['./common-search-new.component.scss']
})
export class CommonSearchNewComponent implements OnInit, OnDestroy {
  public setTimeoutInter: any;
  public search1 = '';
  public search2 = '';
  public radioOption = 'or';
  public select1 = '==';
  public select2 = '==';
  private destroy$ = new Subject<void>();
  @Output() closeColumnFilter: EventEmitter<any> = new EventEmitter();
  @Output() searchValue: EventEmitter<any> = new EventEmitter();

  constructor(public main: MainService) { }

  ngOnInit() {
  }

  public search() {
    if (this.search1 !== '' || this.search2 !== '') {
      clearTimeout(this.setTimeoutInter);
      this.setTimeoutInter = setTimeout(() => {
        const data: any[] = [this.search1, this.search2, this.radioOption, this.select1, this.select2];
        this.searchValue.emit(JSON.stringify(data));
      }, 1000);
    } else {
      this.searchValue.emit('');
    }
  }

  ngOnDestroy(): void {
    this.main.filterChangeData.next('initial');
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  close() {
    this.closeColumnFilter.emit();
  }

}

