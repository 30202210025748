import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportComponent } from './report/report.component';
import { ReportRoutingModule } from './report-routing.module';
import { SharedModule } from '../shared/shared.module';
import { InventoryWidgetsComponent } from './inventory-widgets/inventory-widgets.component';
import { InventoryReportComponent } from './inventory-report/inventory-report.component';
import { InventorySheetsComponent } from './inventory-sheets/inventory-sheets.component';
import { InventoryTabComponent } from './inventory-tab/inventory-tab.component';
import { InventoryHomeComponent } from './inventory-home/inventory-home.component';
import { ReportsDetailComponent } from './reports-detail/reports-detail.component';



@NgModule({
  declarations: [
    ReportComponent,
    InventoryWidgetsComponent,
    InventoryReportComponent,
    InventorySheetsComponent,
    InventoryTabComponent,
    InventoryHomeComponent,
    ReportsDetailComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReportRoutingModule
  ]
})
export class ReportModule { }
