import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PricingService } from '../../../services/pricing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-new-map-price-schedule-pop-up',
    templateUrl: './new-map-price-schedule-pop-up.component.html',
    styleUrls: ['./new-map-price-schedule-pop-up.component.scss']
})
export class NewMapPriceSchedulePopUpComponent implements OnInit {
    public name: string;
    public type: number;
    public allDays: boolean;
    public sun: boolean;
    public mon: boolean;
    public tue: boolean;
    public wed: boolean;
    public thu: boolean;
    public fri: boolean;
    public sat: boolean;
    public weekDays: any[];
    public wdays: string;

    public timeFrom: string;
    public timeTo: string;
    public ignore: boolean;
    public ignHide: boolean;
    public ison: boolean;

    public destroy$ = new Subject<void>();


    constructor(
        private dialog: MatDialogRef<NewMapPriceSchedulePopUpComponent>,
        private pricingService: PricingService) { }

    ngOnInit() {
    }

    closeDialog() {
        this.dialog.close();
    }
    saveMapSchedule() {
        this.getSelectedWeekDays();
        const mapData = {
            name: this.name,
            type: Number(this.type),
            wdays: this.wdays,
            timeFrom: this.timeFrom,
            timeTo: this.timeTo,
            ignore: this.ignore,
            ignHide: this.ignHide,
            ison: this.ison
        };
        console.log(mapData);
        this.pricingService.saveMapModel(mapData).pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
            console.log(value);
        }, err => {
            console.log(err);
        });
    }

    getSelectedWeekDays() {
        this.wdays = '';
        if (this.allDays) {
            this.wdays = '1234567';
        } else {
            if (this.sun) {
                this.wdays = '1';
            }
            if (this.mon) {
                this.wdays += '2';
            }
            if (this.tue) {
                this.wdays += '3';
            }
            if (this.wed) {
                this.wdays += '4';
            }
            if (this.thu) {
                this.wdays += '5';
            }
            if (this.fri) {
                this.wdays += '6';
            }
            if (this.sat) {
                this.wdays += '7';
            }
        }
    }
}
