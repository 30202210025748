import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tab-orderreturn-order',
  templateUrl: './tab-orderreturn-order.component.html',
  styleUrls: ['./tab-orderreturn-order.component.scss']
})
export class TabOrderreturnOrderComponent implements OnInit {
  testForm = this.fb.group({
    weightLB: [36],
    weightOZ: [2],
    length: [36],
    width: [2],
    height: [2],
  });
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
  }

  public valueChange($e, param) {
    const obj = {};
    obj[param] = $e;
    this.testForm.patchValue({ ...obj });
    this.testForm.get(param).markAsDirty();
    this.testForm.get(param).markAsTouched();
  }
}
