import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MainService } from '../../../services/main.service';
import { LoginModel } from 'src/app/models/login.model';
import { MatDialog } from '@angular/material/dialog';
import { InventoryService } from 'src/app/services/inventory.service';
import { InventorySkuErrorComponent } from '../inventory-sku-error/inventory-sku-error.component';
import { ChangePasswordPopupComponent } from '../../settings/change-password-popup/change-password-popup.component';
@Component({
  selector: 'app-common-sidebar',
  templateUrl: './common-sidebar.component.html',
  styleUrls: ['./common-sidebar.component.scss']
})
export class CommonSidebarComponent implements OnInit {
  @ViewChild('overlaySubMenu') overlaySubMenu: ElementRef;
  @ViewChild('subMenu') subMenu: ElementRef;
  @ViewChild('replenish') replenish: ElementRef;
  public userInfo: LoginModel;
  status = false;

  enteredButton = false;
  isMatMenuOpen = false;
  isMatMenu2Open = false;
  button: any;
  config: any;
  prevButtonTrigger;
  public skuErrorCount: number;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    public main: MainService,
    private inventoryService: InventoryService
  ) { }

  ngOnInit() {
    this.authService.userInfo$.subscribe(res => this.userInfo = res);
    this.setSubmenuBehaviour();
    this.inventoryService.GetSkuErrors().subscribe(res => {
      if (res) { this.skuErrorCount = Object.keys(res).length; }
    });
  }
  public logout() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  public showSubmenu($e, el) {
  }

  public hideSubmenu() {
    this.overlaySubMenu.nativeElement.style.display = this.subMenu.nativeElement.style.display = 'none';
  }

  public gotoSettings(settingType: string) {
    this.router.navigateByUrl('/settings');
    this.main.settingType$.next(settingType);
  }

  public openNotificationDialog() {
    this.openBroadcastPopup(InventorySkuErrorComponent, 'auto', 720, '90%', 'inventory_sku_error');
  }

  public openBroadcastPopup(component: any, height: any, width: number, maxHeight: string, customClass: string) {
    this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '92vw',
      panelClass: customClass !== undefined ? customClass : ''
    });
  }

  private setSubmenuBehaviour() {
    document.querySelectorAll('.commonsidebar ul li').forEach(function (element: any) {
      element.onmouseenter = function (el) {
        let x = this.querySelector('.sub-menus-content-holder');
        if (x) {
          x.style.display = 'block';
          x.querySelectorAll('a').forEach(el => {
            el.onclick = function (_el) {
              x.style.display = 'none';
            }
          })
        }
      }
      element.onmouseleave = function (el) {
        let x = this.querySelector('.sub-menus-content-holder');
        if (x) {
          x.style.display = 'none';
        }
      }
    })
  }



  openMenu() {
    const element = document.getElementById('accordionSidebar');
    if (element.classList.contains('sidebartoggle')) {
      element.classList.remove('sidebartoggle');
    } else {
      element.classList.add('sidebartoggle');
    }
  }


  // SUb Menu Code
  menuenter() {
    this.isMatMenuOpen = true;
    if (this.isMatMenu2Open) {
      this.isMatMenu2Open = false;
    }
  }

  menuLeave(trigger, button) {
    setTimeout(() => {
      if (!this.isMatMenu2Open && !this.enteredButton) {
        this.isMatMenuOpen = false;
        trigger.closeMenu();
      } else {
        this.isMatMenuOpen = false;
      }
    }, 80);
  }

  menu2enter() {
    this.isMatMenu2Open = true;
  }

  menu2Leave(trigger1, trigger2, button) {
    setTimeout(() => {
      if (this.isMatMenu2Open) {
        trigger1.closeMenu();
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        this.enteredButton = false;

      } else {
        this.isMatMenu2Open = false;
        trigger2.closeMenu();
      }
    }, 100);
  }

  buttonEnter(trigger) {
    setTimeout(() => {
      if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
        this.prevButtonTrigger.closeMenu();
        this.prevButtonTrigger = trigger;
        this.isMatMenuOpen = false;
        this.isMatMenu2Open = false;
        trigger.openMenu();
      } else if (!this.isMatMenuOpen) {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
        trigger.openMenu();
      } else {
        this.enteredButton = true;
        this.prevButtonTrigger = trigger;
      }
    });
  }

  public buttonLeave(trigger, button) {
    setTimeout(() => {
      if (this.enteredButton && !this.isMatMenuOpen) {
        trigger.closeMenu();
      }
      if (!this.isMatMenuOpen) {
        trigger.closeMenu();

      } else {
        this.enteredButton = false;
      }
    }, 100);
  }

  public openChangePasswordPopup() {
    this.dialog.open(ChangePasswordPopupComponent, {
      width: `500px`,
      height: 'auto',
      maxHeight: '900px',
      maxWidth: '92vw'
    });
  }


}
