import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkuModel } from 'src/app/models/shipment.model';
import { PrintService } from '../../../services/print.service';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-popup-bulk-print-sku-list',
  templateUrl: './popup-bulk-print-sku-list.component.html',
  styleUrls: ['./popup-bulk-print-sku-list.component.scss']
})
export class PopupBulkPrintSkuListComponent implements OnInit {

  public skus: SkuModel[];

  constructor(
    public dialogRef: MatDialogRef<PopupBulkPrintSkuListComponent>,
    private printService: PrintService,
    public main: MainService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  public onQprntChange(value: number, sku: SkuModel) {
    sku.qprnt = value;
  }

  public removeItem(sku: SkuModel) {
    this.skus.splice(this.skus.indexOf(sku), 1);
  }

  public onPrintClick() {
    this.dialogRef.close();
    this.printService.setArrayData(this.skus);
    this.printService.printDocument('picklistprint');
  }

  public closePopup() {
    this.dialogRef.close();
  }

}
