import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupInventoryBulkTwoComponent } from '../popup-inventory-bulk-two/popup-inventory-bulk-two.component';

@Component({
  selector: 'app-popup-inventory-bulk-one',
  templateUrl: './popup-inventory-bulk-one.component.html',
  styleUrls: ['./popup-inventory-bulk-one.component.scss']
})
export class PopupInventoryBulkOneComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  public openInventoryBulkTwo() {
    this.openBroadcastPopup(PopupInventoryBulkTwoComponent, 'auto', 1236, '90%', 'bulk_two_popup');
  }

  public openBroadcastPopup(component: any, height: any, width: number, maxHeight: string, customClass: string) {
    this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '92vw',
      panelClass: customClass !== undefined ? customClass : ''
    });

  }

}
