import { Component, OnInit, Inject } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PricingService } from 'src/app/services/pricing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditNewMapPriceSchedulePopUpComponent } from '../edit-new-map-price-schedule-pop-up/edit-new-map-price-schedule-pop-up.component';
import {
  EditMapPricingScheduleDateRangeComponent
} from '../edit-map-pricing-schedule-date-range/edit-map-pricing-schedule-date-range.component';
import { EditMapPricingScheduleWeeklyComponent } from '../edit-map-pricing-schedule-weekly/edit-map-pricing-schedule-weekly.component';
import { PrcModels } from 'src/app/models/pricing.model';

@Component({
  selector: 'app-editpricingmodelsettings',
  templateUrl: './editpricingmodelsettings.component.html',
  styleUrls: ['./editpricingmodelsettings.component.scss']
})
export class EditpricingmodelsettingsComponent implements OnInit {
  private destroy$ = new Subject<void>();
  showRepBody = false;
  showBuisnessBody = false;
  showOversockBody = false;
  showotherSetBody = false;
  showModelRules = false;

  public type: any;
  public prcModels: PrcModels;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditpricingmodelsettingsComponent>,
    private pricingService: PricingService,
    private main: MainService) { }

  ngOnInit() {
    this.getParams();
  }

  toggleRepBody() {
    this.showRepBody = !this.showRepBody;
  }

  toggleBusinessBody() {
    this.showBuisnessBody = !this.showBuisnessBody;
  }

  toggleOversockBody() {
    this.showOversockBody = !this.showOversockBody;
  }

  toggleOtherSettingsBody() {
    this.showotherSetBody = !this.showotherSetBody;
  }

  toggleModelRules() {
    this.showModelRules = !this.showModelRules;
  }

  closePopup() {
    this.dialogRef.close();
  }

  openNewPopUp() {
    this.main.openBroadcastPopup(EditNewMapPriceSchedulePopUpComponent, 'auto', 480, '90%', 'new_map_price_schedule', this.data);
  }

  openPricingScheduleDateRangePopUp() {
    this.main.openBroadcastPopup(EditMapPricingScheduleDateRangeComponent, 'auto', 450, '90%', 'new_map_price_schedule', this.data);
  }

  openPricingScheduleWeeklyPopUp() {
    this.main.openBroadcastPopup(EditMapPricingScheduleWeeklyComponent, 'auto', 450, '90%', 'new_map_price_schedule', this.data);
  }

  getParams() {
    if (this.data.prcModels != null) {
      this.prcModels = this.data.prcModels;
    }
  }

  public updateModel() {
    this.pricingService.updatePrcModel(this.prcModels).pipe(takeUntil(this.destroy$)).subscribe((value) => {
      console.log(value);
    });
  }

  public toBoolean(value: number) {
    return Boolean(value);
  }

}
