import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-left-bg',
  templateUrl: './form-left-bg.component.html',
  styleUrls: ['./form-left-bg.component.scss']
})
export class FormLeftBgComponent implements OnInit {
  @Input() leftImage: string;
  @Input() rightText: any;

  constructor() { }

  ngOnInit() {
    console.log(this.leftImage);
  }

}
