import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { SettingAccount } from 'src/app/models/settings.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from '../../../services/inventory.service';
import { saveAs } from 'file-saver';
import { ShipmentService } from 'src/app/services/shipment.service';
import { InventorySortService } from '../../inventory/services/inventory-sort.service';
import { InventorySkuSortEnum } from '../../inventory/enums/inventory-sku-sort-enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {
  @Output() closeSidebar = new EventEmitter<boolean>();
  @Input() filterType: any;
  @Input() showSelect: any;
  @Input() tabType: any;
  @Input() inventoryFilterData: any;
  @Input() inventoryFilterSelected: any;
  @Output() inventoryMainTableData = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSubmitFilter = new EventEmitter<any>();
  @Output() changeInAcc = new EventEmitter<any>();
  @Output() activeChange = new EventEmitter<boolean>();
  @Output() openBulkModal = new EventEmitter<any>();
  @Input() accounts: SettingAccount[];
  @Input() account: string;
  @Input() vendors: SettingAccount[];
  @Input() vendor: string;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  @Input() status: string | string[];
  @Input() orderType: string;
  @Input() pending: boolean;
  @Input() cancelled: boolean;
  @Input() shipped: boolean;
  @Input() returned: boolean;
  @Input() remainingDays: number;
  @Input() working: boolean;
  @Input() closed: boolean;
  @Input() inAtive: boolean;
  @Input() inStockOnly: boolean;
  @Input() active: boolean;
  @Input() alertOnly: boolean;
  @Output() filterClick = new EventEmitter<any>();
  public selected: any;
  public sortColumn: InventorySkuSortEnum;
  public inventorySkuSortEnum = InventorySkuSortEnum;
  public columns = [
    'SKU', 'ASIN', 'Amazon Status', 'Title', 'FBA Stock', 'Qty in Production', 'Buy Box', 'Minimum Price',
    'Buy Box Seller', 'Rank', 'FBA Type', 'FBA Error', 'Notes', 'Account'
  ];

  public inventoryItemsFilterForm = this.fb.group({
    Dropship: [false],
    Local: [true],
    Archived: [false],
  });
  public shippingFilterForm = this.fb.group({
    FromDate: [null],
    ToDate: [null],
    Account: [null, Validators.required],
    Working: [null, Validators.required],
    Closed: [null, Validators.required],
    AlertOnly: [false, Validators.required]
  });

  public orderFilterForm = this.fb.group({
    FromDate: [null, Validators.required],
    ToDate: [null, Validators.required],
    Account: [null, Validators.required],
    OrderType: [null],
    Status: [null],
    Pending: [null],
    Cancelled: [null],
    Shipped: [null],
    Returned: [null],
  });

  public replenishForm = this.fb.group({
    Account: [null, Validators.required],
    Vendor: [null],
    RemainingDays: [null],
  });

  public pricingFilterForm = this.fb.group({
    Account: [null, Validators.required],
    IsActive: [null],
    InStockOnly: [null],
  });
  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private inventoryService: InventoryService,
    private shipmentService: ShipmentService,
    public inventorySortService: InventorySortService
  ) { }

  ngOnInit() {
    this.inventorySortService.skuSortColumnCurrent$.pipe(takeUntil(this.destroy$))
      .subscribe(value => this.sortColumn = value);

    if (this.filterType === 'shipping') {
      this.shippingFilterForm.patchValue({
        Account: this.account,
        Working: this.working != null ? this.working : true,
        Closed: this.closed != null ? this.closed : true,
        FromDate: this.fromDate ? new Date(this.fromDate) : null,
        ToDate: this.toDate ? new Date(this.toDate) : null,
        AlertOnly: this.alertOnly
      });
    } else if (this.filterType === 'inventory') {
      console.log(this.tabType);
      if (this.tabType === 'items') {
        console.log('looks good');
      }
    } else if (this.filterType === 'order') {
      this.orderFilterForm.patchValue({
        Account: this.account,
        FromDate: this.fromDate,
        ToDate: this.toDate,
        OrderType: this.orderType,
        Status: this.status,
        Pending: this.pending,
        Cancelled: this.cancelled,
        Shipped: this.shipped,
        Returned: this.returned
      });
    } else if (this.filterType === 'replenish') {
      this.replenishForm.patchValue({
        Account: this.account,
        Vendor:this.vendor,
        RemainingDays: this.remainingDays
      });
    } else if (this.filterType === 'pricing') {
      this.pricingFilterForm.patchValue({
        Account: this.account,
        IsActive: this.inAtive,
        InStockOnly: this.inStockOnly
      });
    }
  }

  public changeOrder(value: InventorySkuSortEnum) {
    this.inventorySortService.skuSortColumn$.next(value);
    this.inventorySortService.skuSortColumnCurrent$.next(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onDownloadNEW() {
    this.shipmentService.DownloadNewAmazonSkus(this.inventoryFilterSelected)
      .subscribe((value: any) => {
        const contentDisposition = value.headers.get('content-disposition') || '';
        const matches = /filename=([^;]+)/ig.exec(contentDisposition);
        let fileName: string;
        if (matches) {
          fileName = (matches[1] || 'untitled').trim();
        } else {
          fileName = 'untitled';
        }
        saveAs(value.body, fileName);
      });
  }

  public changeInAccount($e) {
    this.changeInAcc.emit($e.target.value);
  }

  public onSubmitInventoryForm() {
    this.activeChange.emit(this.active);
  }

  public inventoryMainTableDataFetch() {
    if (this.tabType === 'items') {
      this.inventoryService.ListItemsByFilter(this.inventoryItemsFilterForm.value)
        .pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          if (res && res.items && res.itemCount) {
            this.inventoryMainTableData.emit({ data: res.items, count: res.itemCount });
            this.closeSidebar.emit(false);
          }
        });
    }
  }

  public onSubmitInventoryItemsForm() {
    this.inventoryMainTableDataFetch();
  }

  public onSubmitShipmentForm() {
    if (this.shippingFilterForm.valid) {
      const { Account, Working, Closed, AlertOnly } = this.shippingFilterForm.value;
      const { FromDate, ToDate } = this.shippingFilterForm.value;
      const tempDate = { FromDate, ToDate };
      if (!FromDate && !ToDate && !AlertOnly && Working && Closed) {
        this.onSubmitFilter.emit({ ...tempDate, ...{ Account, Working, Closed, AlertOnly } });
      } else {
        this.onSubmitFilter.emit({ ...tempDate, ...{ Account, Working, Closed, AlertOnly, isFilterOn: true } });
      }
      this.closeSidebar.emit(false);
    }
  }

  public onOrderFilterSubmit() {
    if (this.orderFilterForm.valid) {
      this.onSubmitFilter.emit(this.orderFilterForm.value);
      this.closeSidebar.emit(false);
    }
  }

  public onReplenishFilterSubmit() {
   
    if (this.replenishForm.valid) {
      this.onSubmitFilter.emit(this.replenishForm.value);
      this.closeSidebar.emit(false);
    }
  }

  public onClearClick() {
    this.shippingFilterForm.reset({
      Account: this.account,
      Working: this.working != null ? this.working : true,
      Closed: this.closed != null ? this.closed : true,
      FromDate: null,
      ToDate: null,
      AlertOnly: this.alertOnly
    });
    this.onSubmitFilter.emit(this.shippingFilterForm.value);
    this.closeSidebar.emit(false);
  }

  public onReplenishClearClick() {
    this.replenishForm.reset();
    this.onSubmitFilter.emit(this.replenishForm.value);
    this.closeSidebar.emit(false);
  }

  public onOrderFilterCancel() {
    this.orderFilterForm.reset();
    this.closeSidebar.emit(false);
  }

  public onPricingFilterSubmit() {
    if (this.pricingFilterForm.valid) {
      this.onSubmitFilter.emit(this.pricingFilterForm.value);
      this.closeSidebar.emit(false);
    }
  }

  public onPricingClearClick() {
    this.pricingFilterForm.reset();
    this.onSubmitFilter.emit(this.pricingFilterForm.value);
    this.closeSidebar.emit(false);
  }

}
