import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { BoxLabelPrintComponent } from './print-layout/box-label-print/box-label-print.component';
import { BulkSkuLabelPrintComponent } from './print-layout/bulk-sku-label-print/bulk-sku-label-print.component';
import { SkuLabelPrintComponent } from './print-layout/sku-label-print/sku-label-print.component';
import { RouterModule } from '@angular/router';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PickListPrintComponent } from './print-layout/pick-list-print/pick-list-print.component';
import {BoxLabelPrint2dComponent} from './print-layout/box-label-print-2d/box-label-print-2d.component';




@NgModule({
  declarations: [PrintLayoutComponent, BoxLabelPrintComponent, BulkSkuLabelPrintComponent, SkuLabelPrintComponent, PickListPrintComponent, BoxLabelPrint2dComponent],
    imports: [
        CommonModule,
        NgxBarcodeModule,
        RouterModule,
    ]
})
export class PrintModule { }
