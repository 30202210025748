import { NgModule } from '@angular/core';

import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { InviteTeamMembersComponent } from './invite-team-members/invite-team-members.component';
import { SharedModule } from '../shared/shared.module';
import { RegisterTeamMemberComponent } from './register-team-member/register-team-member.component';
import { TwoFactorVerificationComponent } from './two-factor-verification/two-factor-verification.component';
import { OnboardingQuestionsComponent } from './onboarding-questions/onboarding-questions.component';
import { AdvisorLoginComponent } from './advisor-login/advisor-login.component';
import { AdvisorSignupComponent } from './advisor-signup/advisor-signup.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { HelpPageComponent } from './help-page/help-page.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    LoginComponent,
    SignupComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    InviteTeamMembersComponent,
    RegisterTeamMemberComponent,
    TwoFactorVerificationComponent,
    OnboardingQuestionsComponent,
    AdvisorLoginComponent, AdvisorSignupComponent, TermsAndConditionsComponent, PrivacyPolicyComponent, HelpPageComponent
  ],
  imports: [
    SharedModule,
    PerfectScrollbarModule,
    PdfViewerModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: []
})
export class AuthenticationModule { }
