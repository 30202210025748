export enum InventorySkuSortEnum {
    SkuId = 1,
    Title = 2,
    Asin = 3,
    FnSku = 4,
    Qty = 5,
    Total = 6,
    MinPrice = 7,
    Avgcost = 8,
    Note = 9,
    Account = 10,
    Isshiperr = 11,
    Shipgoodtype = 12,
    Rank = 13,
    Category = 14,
    BbIsamzn = 15,
    BuyBox = 16,
    Isoversized = 17
}
