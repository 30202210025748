import { Component, Input, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import {
  BoxesByFilterModel,
  BoxModel,
  UnpackBoxRequestModel,
  SaveWeightRequestModel,
  DeleteFromBoxRequestModel,
  BoxSkuRequestModel
} from 'src/app/models/shipment.model';
import { MatDialog } from '@angular/material/dialog';
import { ShipmentService } from 'src/app/services/shipment.service';
import { ToastService } from 'src/app/core/toast.service';
import { PopupSwitchBoxComponent } from '../popup-switch-box/popup-switch-box.component';
import { PopupSplitIntoBoxComponent } from '../popup-split-into-box/popup-split-into-box.component';
import { PopupUpdateQtyComponent } from '../popup-update-qty/popup-update-qty.component';
import { PrintBoxLabelComponent } from '../print-box-label/print-box-label.component';
import { PopupScanBoxComponent } from '../popup-scan-box/popup-scan-box.component';
import { MainService } from '../../../services/main.service';
import { Subject, of } from 'rxjs';
import { takeUntil, mergeMap } from 'rxjs/operators';
import { CreateNewBoxComponent } from './create-new-box/create-new-box.component';
import { PrintService } from 'src/app/services/print.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { CommonService } from 'src/app/services/common.service';
import { PopupSplitBoxComponent } from '../popup-split-box/popup-split-box.component';


@Component({
  selector: 'app-logistics-parent-child-view',
  templateUrl: './logistics-parent-child-view.component.html',
  styleUrls: ['./logistics-parent-child-view.component.scss']
})
export class LogisticsParentChildViewComponent implements OnChanges, OnDestroy {
  @Input() groupedArray: BoxesByFilterModel[][] = [];
  @Input() boxSums: BoxModel[];
  @Input() account: string;
  @Input() type: string;
  @Input() shipmentId: string;
  @Input() totalBoxByFilterQty: number;
  @Input() totalBoxByFilterSkus: number;
  @Input() totalBoxByFilterBoxes: number;
  @Input() pageType: string;
  @Input() showSmallMenu: boolean;
  @Input() showAddToLogistics: boolean;
  @Input() showCloseShipment: boolean;
  @Input() errDismissed: boolean;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSwitchBoxClose = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSplitIntoBoxClose = new EventEmitter<void>();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onScanBoxClose = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onUnpackBoxClose = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onUpdateQtyRightClickClose = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onTotalWeightChangeClose = new EventEmitter<void>();
  @Output() deleteFromBoxDone = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFilterGoClicked = new EventEmitter<any>();
  @Output() openCreatePallet = new EventEmitter<void>();
  @Output() openAddToLogistics = new EventEmitter<void>();
  @Output() closeShipment = new EventEmitter<void>();
  @Output() addedToShipment = new EventEmitter<void>();
  @Input('creatingPallet') public creatingPallet: boolean

  public filterType = '0';
  public filterFirst = null;
  public filterLast = null;
  public boxSumsFiltered: BoxModel[];
  public boxSumsErr: BoxModel[];

  public expand = [];
  public skus = [];
  public showAll = false;
  public showExpand = true;
  public loadingCloseShipment: string;
  public loadingPalletLabels: string;
  private destroy$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    public main: MainService,
    private printService: PrintService,
    private inventoryService: InventoryService,
    private commonService: CommonService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.boxSums && this.boxSums) {
      this.boxSumsFiltered = [...this.boxSums];
      this.boxSumsErr = [...this.boxSums.filter(x => !x.bx)];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public addToBox(sku: any) {
    const data: BoxSkuRequestModel = {
      ship: this.shipmentId ? (this.shipmentId.indexOf('-') > -1 ? this.shipmentId.split('-')[0] : this.shipmentId) : null,
      account: this.account,
      isOverszd: parseInt(this.type, 10),
      newBox: -1,
      qty: sku.qty,
      sku: sku.sku
    };
    this.shipmentService.BoxSku(data)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.addedToShipment.next();
        this.toastService.showToaster('Operation Successful.');
      });
  }

  public openSplitBox(sku: any) {
    sku.qprnt = sku.qty;
    sku.skuId = sku.sku;
    const shipment = this.shipmentId ? (this.shipmentId.indexOf('-') > -1 ? this.shipmentId.split('-')[0] : this.shipmentId) : null;
    const dialogRef = this.dialog.open(PopupSplitBoxComponent, { width: '600px', maxWidth: '95vw' });
    dialogRef.componentInstance.sku = sku;
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.isOverszd = parseInt(this.type, 10);
    dialogRef.componentInstance.ship = shipment;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== undefined) {
        this.addedToShipment.next();
        if (value === 'save-print') {
          if (this.groupedArray.length > 0 && this.groupedArray[0][0]) {
            this.onPrintBoxLabelClick(this.groupedArray[0][0]);
          }
        }
      }
    });
  }

  public removeSku(sku: any) {
    const shipment = this.shipmentId ? (this.shipmentId.indexOf('-') > -1 ? this.shipmentId.split('-')[0] : this.shipmentId) : null;
    const data = {
      type: 'add to shipment',
      Arg1: shipment,
      Arg2: this.account,
      Arg3: JSON.stringify([{ sku: sku.sku, qty: 0 }])
    };
    this.commonService.DataQueue(data)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.addedToShipment.next();
        this.toastService.showToaster('Operation Successful.');
      });
  }

  public openSideDotOption(elem) {
    if (elem.classList.contains('display_menu')) {
      elem.classList.remove('display_menu');
    } else {
      document.querySelectorAll('.right_click_menu').forEach(elm => elm.classList.remove('display_menu'));
      elem.classList.add('display_menu');
    }
  }

  public closeSideDotOption(elem) {
    setTimeout(() => elem.classList.remove('display_menu'), 200);
  }

  public getTotalQty(arr: BoxesByFilterModel[]) {
    return arr.reduce((acc, cur) => acc + cur.qty, 0);
  }

  public onExpandCollapseClick(id: string) {
    const index = this.expand.indexOf(id);
    if (index !== -1) {
      this.expand.splice(index, 1);
    } else {
      this.expand.push(id);
    }
  }

  public totalBoxes() {
    return this.groupedArray && this.groupedArray.length > 0 ? this.groupedArray.length : 0;
  }

  public totalSkus() {
    if (this.groupedArray && this.groupedArray.length) {
      let skus = 0;
      this.skus=[];
      this.groupedArray.forEach(ele => {
        this.skus.push(ele[0].sku);
      });
      return skus =this.skus.filter((item, i, ar) => ar.indexOf(item) === i).length;
    } else {
      return 0;
    }
  }

  public totalWeight() {
    if (this.groupedArray && this.groupedArray.length) {
      let weight = 0;
      this.groupedArray.forEach(ele => {
        if (ele && ele.length > 0) {
          weight += ele[0].weight;
        }
      });
      return weight;
    } else {
      return 0;
    }
  }

  public expandAll() {
    this.showExpand = false;
    this.groupedArray.forEach(ele => {
      if (this.expand.indexOf(ele[0].firstBox) === -1) {
        this.expand.push(ele[0].firstBox);
      }
    });
  }

  public collapseAll() {
    this.showExpand = true;
    this.expand = [...[]];
  }

  public isDisabledGoButton() {
    if (!this.shipmentId) {
      return true;
    } else {
      if (parseInt(this.filterType, 10) === 1 && !this.filterFirst) {
        return true;
      } else if (parseInt(this.filterType, 10) === 2 && !this.filterLast) {
        return true;
      } else if (parseInt(this.filterType, 10) === 3) {
        if (!this.filterFirst || !this.filterLast) {
          return true;
        } else if (this.filterLast < this.filterFirst) {
          return true;
        } else {
          return false;
        }
      } else if (parseInt(this.filterType, 10) === 5 && (!this.filterFirst)) {
        return true;
      } else {
        return false;
      }
    }
  }

  public onFilterTypeChange() {
    this.filterFirst = null;
    this.filterLast = null;
    if (this.filterType === '0' || this.filterType === '4') {
      this.onFilterGoClick();
    }
  }

  public onFilterGoClick() {
    this.onFilterGoClicked.emit({
      filterType: this.filterType,
      filterFirst: this.filterFirst,
      filterLast: this.filterLast
    });
  }

  public onSwitchBox(box: BoxesByFilterModel) {
    const dialogRef = this.dialog.open(PopupSwitchBoxComponent, { width: '600px', maxWidth: '95vw' });
    dialogRef.componentInstance.box = box;
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.isOverszd = parseInt(this.type, 10);
    dialogRef.componentInstance.ship = this.shipmentId;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.onSwitchBoxClose.emit();
      }
    });
  }

  public onSplitIntoBox(box: BoxesByFilterModel) {
    const dialogRef = this.dialog.open(PopupSplitIntoBoxComponent, { width: '600px', maxWidth: '95vw' });
    dialogRef.componentInstance.sku = box;
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.isOverszd = parseInt(this.type, 10);
    dialogRef.componentInstance.ship = this.shipmentId;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== undefined) {
        this.onSplitIntoBoxClose.emit();
        if (value === 'save-print') {
          if (this.groupedArray.length > 0 && this.groupedArray[0][0]) {
            this.onPrintBoxLabelClick(this.groupedArray[0][0]);
          }
        }
      }
    });
  }

  public onScanAll() {
    const dialogRef = this.dialog.open(PopupScanBoxComponent, { width: '600px', maxWidth: '95vw', autoFocus: false });
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.type = this.type;
    dialogRef.componentInstance.shipmentId = this.shipmentId;
    dialogRef.componentInstance.sku = this.groupedArray[0][0].sku;
    dialogRef.componentInstance.isGlobal = true;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== undefined) {
        this.onScanBoxClose.emit();
        if (value === 'save-print') {
          if (this.groupedArray.length > 0 && this.groupedArray[0][0]) {
            this.onPrintBoxLabelClick(this.groupedArray[0][0]);
          }
        }
      }
    });
  }

  public onScanBox(box: BoxesByFilterModel) {
    const dialogRef = this.dialog.open(PopupScanBoxComponent, { width: '600px', maxWidth: '95vw', autoFocus: false });
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.type = this.type;
    dialogRef.componentInstance.shipmentId = this.shipmentId;
    dialogRef.componentInstance.sku = box.sku;
    dialogRef.componentInstance.onScanBoxClose = this.onScanBoxClose;
    dialogRef.componentInstance.boxId = box.firstBox ? box.firstBox.toString() : '';
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value !== undefined) {
        this.onScanBoxClose.emit();
        if (value === 'save-print') {
          if (this.groupedArray.length > 0 && this.groupedArray[0][0]) {
            this.onPrintBoxLabelClick(this.groupedArray[0][0]);
          }
        }
      }
    });
  }
  public onUnpackBox(box: BoxesByFilterModel) {
    const data: UnpackBoxRequestModel = {
      skus: [box.sku],
      ships: [this.shipmentId],
      accounts: [this.account],
      isOverszds: [parseInt(this.type, 10)],
      oldBoxes: [box.firstBox],
      qtys: [box.qty]
    };
    this.shipmentService.UnpackBox(data)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.toastService.showToaster(`Box unpacked successfully.`);
        this.onUnpackBoxClose.emit();
      });
  }

  public clearBoxes() {
    this.main.openConfirmationPopup('Do you want to unpack all boxes?').afterClosed()
      .pipe(
        mergeMap((confirmation: any) => {
          if (confirmation === 'yes') {
            return this.main.openConfirmationPopup('Are you sure you want to unpack all boxes from table?').afterClosed();
          } else {
            return of('no');
          }
        }),
        takeUntil(this.destroy$)
      ).subscribe(confirmation => {
        if (confirmation === 'yes') {
          this.clearAllBoxes();
        }
      });
  }

  public clearAllBoxes() {
    const data: UnpackBoxRequestModel = {
      skus: [],
      ships: [],
      accounts: [],
      isOverszds: [],
      oldBoxes: [],
      qtys: []
    };
    this.groupedArray.forEach(x => {
      x.forEach(y => {
        data.skus.push(y.sku);
        data.ships.push(this.shipmentId);
        data.accounts.push(this.account);
        data.isOverszds.push(parseInt(this.type, 10));
        data.oldBoxes.push(y.firstBox);
        data.qtys.push(y.qty);
      });
    });
    this.shipmentService.UnpackBox(data)
      .pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.toastService.showToaster(`Boxes unpacked successfully.`);
        this.onUnpackBoxClose.emit();
      });
  }

  public onUpdateQtyRightClick(sku: BoxesByFilterModel) {
    const dialogRef = this.dialog.open(PopupUpdateQtyComponent, { width: '600px', maxWidth: '95vw' });
    dialogRef.componentInstance.skuId = sku.sku;
    dialogRef.componentInstance.oldQty = sku.qty;
    dialogRef.componentInstance.qty = sku.qty ? sku.qty.toString() : null;
    dialogRef.componentInstance.account = this.account;
    dialogRef.componentInstance.isOverszd = parseInt(this.type, 10);
    dialogRef.componentInstance.ship = this.shipmentId;
    dialogRef.componentInstance.box = sku.firstBox;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.onUpdateQtyRightClickClose.emit();
      }
    });
  }

  public onTotalWeightChange(value: string, cbox: BoxesByFilterModel) {
    if (value) {
      const weight = parseInt(value, 10);
      if (weight >= 0) {
        const data: SaveWeightRequestModel = {
          weight,
          ship: this.shipmentId,
          box: cbox.firstBox,
          account: this.account,
          isOverszd: parseInt(this.type, 10)
        };
        this.shipmentService.SaveWeight(data)
          .pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.toastService.showToaster('Total Weight Saved.');
            this.onTotalWeightChangeClose.emit();
          });
      } else {
        cbox.weight = 0;
      }
    }
  }

  public deleteFromBox(box: BoxesByFilterModel) {
    this.main.openConfirmationPopup('Do you really want to delete this?').afterClosed()
      .pipe(takeUntil(this.destroy$)).subscribe(confirmation => {
        if (confirmation === 'yes') {
          const data: DeleteFromBoxRequestModel = {
            skus: [box.sku],
            ships: [this.shipmentId],
            accounts: [this.account],
            isOverszds: [parseInt(this.type, 10)],
            oldBoxes: [box.firstBox],
            qtys: [box.qty]
          };
          this.shipmentService.DeleteFromBox(data).pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.toastService.showToaster(`Box deleted successfully.`);
            this.deleteFromBoxDone.emit();
          });
        }
      });
  }

  public onPrintAllBoxLabelClick() {
    if (this.groupedArray.length > 0 && this.groupedArray[0][0]) {
      this.onPrintBoxLabelClick(this.groupedArray[0][0]);
    }
  }

  public onPrintBoxLabelClick(box: BoxesByFilterModel) {
    const dialogRef = this.dialog.open(PrintBoxLabelComponent, {
      width: '600px',
      maxWidth: '95vw',
    });
    dialogRef.componentInstance.box = { ...box };
    dialogRef.componentInstance.lastBox = { ...this.groupedArray[this.groupedArray.length - 1][0] };
    dialogRef.componentInstance.isOverszd = parseInt(this.type, 10);
  }

  public onSinglePrintBox(box: BoxesByFilterModel) {
    const data = [
      box.ship,
      box.account,
      box.firstBox.toString(),
      box.firstBox.toString(),
      this.type,
      '1'
    ];
    this.printService.printDocument('boxlabel', data);
  }
  public onSingle2DPrintBox(box: BoxesByFilterModel) {
    const data = [box.ship, box.account, box.firstBox.toString(), box.firstBox.toString(), this.type, '1', box.qty.toString(), box.Asin || box.asin];
    this.printService.printDocument('boxlabel2d', data);
    // AMZN,PO:[shipment id],ASIN:[asin in box],QTY,:[qty for this asin/sku],ASIN:[asin in box],QTY,:[qty for this asin/sku]
  }
  public dismiss(bs: BoxModel) {
    this.main.openConfirmationPopup('Do You Really Want To Delete This').afterClosed().subscribe(data => {
      if (data === 'yes') {
        this.inventoryService.SkuErrorsDismiss(bs.sku, this.account, '').pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            bs.isShiperr = true;
            this.toastService.showToaster(`Dismissed successfully.`);
          });
      }
    });
  }

  public numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public positiveOnly(event: any, value: string) {
    switch (value) {
      case 'filterFirst': if (this.filterFirst < 0) { this.filterFirst = 0; } break;
      case 'filterLast': if (this.filterLast < 0) { this.filterLast = 0; } break;
    }
    const charCode = (event.which) ? event.which : event.keyCode;
    const isInalid = this.isDisabledGoButton();
    if (charCode === 13 && !isInalid) {
      this.onFilterGoClick();
    }
  }

  public onCreateNewBox() {
    this.dialog.open(CreateNewBoxComponent, {
      width: '600px',
      maxWidth: '95vw',
    });
  }

  public dismissError(bs: BoxModel) {
    this.shipmentService.DismissShipmentSkuError(this.shipmentId, bs.sku).pipe(takeUntil(this.destroy$))
      .subscribe(() => bs.errDismissed = true);
  }

  public filterItems(value: string) {
    if (value) {
      this.boxSumsFiltered = [...this.boxSums.filter(x => x.sku.toLowerCase().indexOf(value.toLowerCase()) > -1)];
    } else {
      this.boxSumsFiltered = [...this.boxSums];
    }
  }

  public onCloseShipment() {
    this.commonService.CloseShipment(this.shipmentId, this.account, this.type).pipe(takeUntil(this.destroy$))
      .subscribe(() =>
      { this.toastService.showToaster('Shipment closed successfully');
      this.loadingCloseShipment=null;
    }, error => {
      this.toastService.showErrorToaster('Fail to closed Shipment');
      this.loadingCloseShipment = null;
    });
  }

}
