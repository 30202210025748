import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-split-skus',
  templateUrl: './popup-split-skus.component.html',
  styleUrls: ['./popup-split-skus.component.scss']
})
export class PopupSplitSkusComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopupSplitSkusComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  }

  public closePopup() {
    this.dialogRef.close();
  }
}
