import { Component, OnInit, Inject, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetBoxesRequestModel, SearchFnSkuRequestModel, Fba, ScanBoxRequestModel } from 'src/app/models/shipment.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { SettingsService } from 'src/app/services/settings.service';
import { SettingAccount } from 'src/app/models/settings.model';
import { ToastService } from 'src/app/core/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popup-scan-box',
  templateUrl: './popup-scan-box.component.html',
  styleUrls: ['./popup-scan-box.component.scss']
})
export class PopupScanBoxComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef;
  isGlobal: boolean;
  account: string;
  accounts: SettingAccount[];
  onScanBoxClose: any;
  type: string;
  shipmentId: string;
  shipmentIds: string[];
  sku: string;
  ddBoxesValues: number[];
  boxId = '-1';
  searchFnSkuData: any[] = [];
  fba: any[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PopupScanBoxComponent>,
    private settingsService: SettingsService,
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    if (this.boxId !== '-1') {
      this.ddBoxesValues = [parseInt(this.boxId, 10)]; // to display box without delay
    }
    this.settingsService.GetAllAccounts().pipe(takeUntil(this.destroy$))
      .subscribe((value: SettingAccount[]) => this.accounts = [...value]);
    this.loadShipperIds();

    const data: GetBoxesRequestModel = {
      account: this.account,
      isOverszd: parseInt(this.type, 10),
      ship: this.shipmentId
    };
    this.shipmentService.GetListBoxes(data)
      .pipe(takeUntil(this.destroy$)).subscribe((value: number[]) => {
        this.ddBoxesValues = [...value];
      });

    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 10);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
  public loadShipperIds() {
    this.getShipmentId();
  }

  public getShipmentId() {

    if (this.type && this.account) {
      this.shipmentService.GetShipIdsByFilter(this.account, parseInt(this.type, 10))
        .pipe(takeUntil(this.destroy$)).subscribe((value: string[]) => {
          if (value) {
            this.shipmentIds = [...value];
          } else {
            this.shipmentIds = [...[]];
          }
        });
    }
  }
  public searchFnSku(value: string, event?: string, searchInput?: any) {
    const data: SearchFnSkuRequestModel = {
      account: this.account,
      isOverSized: parseInt(this.type, 10),
      fnSku: value
    };
    if (this.searchFnSkuData.length > 0 && event === 'enter') {
      const data1 = this.searchFnSkuData[0];
      if (!this.fba.some(rec => rec.sku === data1.sku && rec.fnSku === data1.fnSku)) {
        this.fba.push({
          sku: data1.sku,
          fnSku: data1.fnSku,
          qty: 1
        });
      } else {
        const qty = this.fba.find(rec => rec.sku === data1.sku && rec.fnSku === data1.fnSku).qty;
        this.fba.find(rec => rec.sku === data1.sku && rec.fnSku === data1.fnSku).qty = qty + 1;
      }
      searchInput.value = '';
      this.searchFnSkuData = [...[]];
    } else if (value !== '') {
      this.shipmentService.SearchFnSku(data).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: Fba[]) => {
        if (res && res.length > 0) {
          this.searchFnSkuData = [...res];
        } else {
          this.searchFnSkuData = [...[]];
          this.playWarningAudio();
        }
      });
    } else {
      this.searchFnSkuData = [...[]];
    }
  }
  public addToSearchDataInserted(data) {
    if (!this.fba.some(rec => rec.sku === data.sku && rec.fnSku === data.fnSku)) {
      this.fba.push({
        sku: data.sku,
        fnSku: data.fnSku,
        qty: 1
      });
    } else {
      const qty = this.fba.find(rec => rec.sku === data.sku && rec.fnSku === data.fnSku).qty;
      this.fba.find(rec => rec.sku === data.sku && rec.fnSku === data.fnSku).qty = qty + 1;
    }
    this.searchFnSkuData = [...[]];
    this.searchInput.nativeElement.value = '';
  }

  public clearSearchItem(searchInput) {
    searchInput.value = '';
    this.searchFnSkuData = [...[]];
  }

  public removeItem(item: Fba) {
    this.fba.splice(this.fba.indexOf(item), 1);
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public onSaveClick(value: string) {
    if (this.fba.length > 0) {
      const sku = this.fba.map(e => e.sku);
      const qty = this.fba.map(e => e.qty);
      const data: ScanBoxRequestModel = {
        account: this.account,
        isOverSized: parseInt(this.type, 10),
        ship: this.shipmentId,
        box: parseInt(this.boxId, 10),
        sku,
        qty
      };
      this.shipmentService.ScanBox(data)
        .pipe(takeUntil(this.destroy$)).subscribe(() => {
          if (value !== 'save-next-box') {
            this.toastService.showToaster('Operation Successful');
            this.dialogRef.close(value);
          } else {
            this.fba = [...[]];
            this.boxId = '-1';
            if (this.onScanBoxClose) {
              this.onScanBoxClose.emit();
            }
          }
        });
    }
  }

  public numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  public positiveOnly(item: any) {
    if (item.qty && item.qty < 0) {
      item.qty = 0;
    }
  }

  private playWarningAudio() {
    const audio = new Audio();
    audio.src = '../../../assets/sound/beep-5.wav';
    audio.load();
    audio.play();
  }
}
