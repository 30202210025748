import { Component, OnInit } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { AddnewchannelComponent } from '../../shared/addnewchannel/addnewchannel.component';
@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss']
})
export class ChannelsComponent implements OnInit {
  public channelType = '';
  showScsection: boolean;
  slideToggle: any;
  sidebarTopic: any;
  constructor(private main: MainService) { }

  ngOnInit() {
    this.main.channelType$.subscribe(value => {
      if (value) {
        this.channelType = value;
      } else {
        this.channelType = 'dashboard';
      }
    });
    this.showScsection = false;
  }

  addNewChannelPopup() {
    this.main.openBroadcastPopup(AddnewchannelComponent, 'auto', 940, '90%', 'add_new_channel_popup');
  }

}
