import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventorySkuErrorComponent } from '../inventory-sku-error/inventory-sku-error.component';

@Component({
  selector: 'app-inventory-item-alert',
  templateUrl: './inventory-item-alert.component.html',
  styleUrls: ['./inventory-item-alert.component.scss']
})
export class InventoryItemAlertComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  public openInventoryItemAlert() {
    this.openBroadcastPopup(InventoryItemAlertComponent, 182, 297, '70vh', 'inventory_sku_item_alert');
  }


  public openInventorySkuError() {
    this.openBroadcastPopup(InventorySkuErrorComponent, 'auto', 720, '90%', 'inventory_sku_error');
  }

  public openBroadcastPopup(component: any, height: any, width: number, maxHeight: string, customClass: string) {
    this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '92vw',
      panelClass: customClass !== undefined ? customClass : ''
    });

  }
}
