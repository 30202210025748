import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LogoComponent } from './logo/logo.component';
import { FormLeftBgComponent } from './form-left-bg/form-left-bg.component';
import { LinkOfFormComponent } from './link-of-form/link-of-form.component';
import { CommonSidebarComponent } from './common-sidebar/common-sidebar.component';
import { FilterComponent } from './filter/filter.component';
import { EditShippingComponent } from './edit-shipping/edit-shipping.component';
import { LogisticsParentChildViewComponent } from './logistics-parent-child-view/logistics-parent-child-view.component';
import { ShipmentSettingsPrinterComponent } from './shipment-settings-printer/shipment-settings-printer.component';
import { PopupCreatePalletsComponent } from './popup-create-pallets/popup-create-pallets.component';
import { PopupUpdateQtyComponent } from './popup-update-qty/popup-update-qty.component';
import { PopupPlanShipComponent } from './popup-plan-ship/popup-plan-ship.component';
import { PopupBulkPrintLabelsComponent } from './popup-bulk-print-labels/popup-bulk-print-labels.component';
import { PopupSplitBoxComponent } from './popup-split-box/popup-split-box.component';
import { PopupAddSkuComponent } from './popup-add-sku/popup-add-sku.component';
import { PopupSwitchBoxComponent } from './popup-switch-box/popup-switch-box.component';
import { PopupSplitIntoBoxComponent } from './popup-split-into-box/popup-split-into-box.component';
import { PrintBoxLabelComponent } from './print-box-label/print-box-label.component';
import { PopupPlanMultipleSkusComponent } from './popup-plan-multiple-skus/popup-plan-multiple-skus.component';
import { PopupInventoryFilterComponent } from './popup-inventory-filter/popup-inventory-filter.component';
import { PopupConfigureColumnComponent } from './popup-configure-column/popup-configure-column.component';
import { InventorySkuAlertComponent } from './inventory-sku-alert/inventory-sku-alert.component';
import { InventoryItemAlertComponent } from './inventory-item-alert/inventory-item-alert.component';
import { InventorySkuErrorComponent } from './inventory-sku-error/inventory-sku-error.component';
import { PopupSetSessionsComponent } from './popup-set-sessions/popup-set-sessions.component';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { PopupInventoryBulkOneComponent } from './popup-inventory-bulk-one/popup-inventory-bulk-one.component';
import { PopupInventoryBulkTwoComponent } from './popup-inventory-bulk-two/popup-inventory-bulk-two.component';
import { PopupInventoryItemsNewVendorComponent } from './popup-inventory-items-new-vendor/popup-inventory-items-new-vendor.component';
import { PopupInventoryNewSeasonComponent } from './popup-inventory-new-season/popup-inventory-new-season.component';
import { PopupInventorySetSeasonComponent } from './popup-inventory-set-season/popup-inventory-set-season.component';
import { PopupInventoryNewVendorComponent } from './popup-inventory-new-vendor/popup-inventory-new-vendor.component';
import { PopupSplitSkusComponent } from './popup-split-skus/popup-split-skus.component';
import { CopyPasteGridComponent } from './copy-paste-grid/copy-paste-grid.component';
import { PopupBulkPrintSkuListComponent } from './popup-bulk-print-sku-list/popup-bulk-print-sku-list.component';
import { CloseShipmentComponent } from './close-shipment/close-shipment.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { PopupNewEditItemsComponent } from './popup-new-edit-items/popup-new-edit-items.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { PercentIncDecComponent } from './percent-inc-dec/percent-inc-dec.component';
import { FbaMinManualSetPopupComponent } from './fba-min-manual-set-popup/fba-min-manual-set-popup.component';
import { HeaderBulkPopupComponent } from './header-bulk-popup/header-bulk-popup.component';
import { HeaderBulkPopupTwoComponent } from './header-bulk-popup-two/header-bulk-popup-two.component';
import { HeaderSettingsPopupComponent } from './header-settings-popup/header-settings-popup.component';
import { MapPricingScheduleDateRangeComponent } from './map-pricing-schedule-date-range/map-pricing-schedule-date-range.component';
import { MapPricingScheduleWeeklyComponent } from './map-pricing-schedule-weekly/map-pricing-schedule-weekly.component';
import { NewMapPriceSchedulePopUpComponent } from './new-map-price-schedule-pop-up/new-map-price-schedule-pop-up.component';
import { PricingmodelsettingsComponent } from './pricingmodelsettings/pricingmodelsettings.component';
import { SellersEditPopupComponent } from './sellers-edit-popup/sellers-edit-popup.component';
import { PopupAddToLogisticsComponent } from './popup-add-to-logistics/popup-add-to-logistics.component';
import { ConnectMwsAccountComponent } from './connect-mws-account/connect-mws-account.component';
import { PrintBoxLabelTwoComponent } from './print-box-label-two/print-box-label-two.component';
import { PopupScanBoxComponent } from './popup-scan-box/popup-scan-box.component';


import { AddnewchannelComponent } from './addnewchannel/addnewchannel.component';
import { ChSettingsComponent } from '../channels/ch-settings/ch-settings.component';
import { ChSettingsTabComponent } from '../channels/ch-settings/ch-settings-tab/ch-settings-tab.component';
import { ChConnectionTabComponent } from '../channels/ch-settings/ch-connection-tab/ch-connection-tab.component';
import { ChDashboardComponent } from '../channels/ch-dashboard/ch-dashboard.component';
import { ChSkuComponent } from '../channels/ch-sku/ch-sku.component';
import { ChHistoryComponent } from '../channels/ch-history/ch-history.component';
import { ChannelsComponent } from '../channels/channels/channels.component';
import { SyncHistoryComponent } from '../channels/ch-history/sync-history/sync-history.component';
import { SubmissionHistoryComponent } from '../channels/ch-history/submission-history/submission-history.component';
import { ImportHistoryComponent } from '../channels/ch-history/import-history/import-history.component';
import { ChSkuAllComponent } from '../channels/ch-sku/ch-sku-all/ch-sku-all.component';
import { ChSkuDraftComponent } from '../channels/ch-sku/ch-sku-draft/ch-sku-draft.component';
import { ChSkuUnlistedComponent } from '../channels/ch-sku/ch-sku-unlisted/ch-sku-unlisted.component';
import { ChSkuActiveComponent } from '../channels/ch-sku/ch-sku-active/ch-sku-active.component';
import { ChSkuFailedComponent } from '../channels/ch-sku/ch-sku-failed/ch-sku-failed.component';
import { GlobalMessageComponent } from './global-message/global-message.component';
import { EditpricingmodelsettingsComponent } from './editpricingmodelsettings/editpricingmodelsettings.component';
import { EditNewMapPriceSchedulePopUpComponent } from './edit-new-map-price-schedule-pop-up/edit-new-map-price-schedule-pop-up.component';
import { EditMapPricingScheduleWeeklyComponent } from './edit-map-pricing-schedule-weekly/edit-map-pricing-schedule-weekly.component';
import {
  EditMapPricingScheduleDateRangeComponent
} from './edit-map-pricing-schedule-date-range/edit-map-pricing-schedule-date-range.component';
import { RateCalculatorComponent } from './rate-calculator/rate-calculator.component';
import { OrderReturnPopupComponent } from './order-return-popup/order-return-popup.component';
import { TabOrderreturnReturnComponent } from './tab-orderreturn-return/tab-orderreturn-return.component';
import { TabOrderreturnOrderComponent } from './tab-orderreturn-order/tab-orderreturn-order.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { CommonSearchComponent } from '../inventory/common-search/common-search.component';
import { CommonSearchNewComponent } from './common-search-new/common-search-new.component';
import { NoFunctionalTableComponent } from './no-functional-table/no-functional-table.component';
import { PopupOrderBulkOperationComponent } from '../shared/popup-order-bulk-operation/popup-order-bulk-operation.component';
import { PopupOrderImportOrdersComponent } from './popup-order-import-orders/popup-order-import-orders.component';
import { PopupOrderImportDataComponent } from './popup-order-import-data/popup-order-import-data.component';
import { PopupOrderNewOrderComponent } from './popup-order-new-order/popup-order-new-order.component';
import { PopupOrderNewCustomerComponent } from './popup-order-new-customer/popup-order-new-customer.component';
import { PopupMultiSelectSidebarComponent } from './popup-multi-select-sidebar/popup-multi-select-sidebar.component';
import { PopupNoReturnYetComponent } from './popup-no-return-yet/popup-no-return-yet.component';
import { PopupProductPickupListComponent } from './popup-product-pickup-list/popup-product-pickup-list.component';
import { PopupPackagingSlipComponent } from './popup-packaging-slip/popup-packaging-slip.component';
import { PopupOrderSummaryComponent } from './popup-order-summary/popup-order-summary.component';
import { AdviserSidebarComponent } from './adviser-sidebar/adviser-sidebar.component';
import { VerifyClientComponent } from './verify-client/verify-client.component';
import { PopupInventoryProductComponent } from './popup-inventory-product/popup-inventory-product.component';
import { PopupInventoryProductTab1Component } from './popup-inventory-product-tab1/popup-inventory-product-tab1.component';
import { PopupInventoryProductTab2Component } from './popup-inventory-product-tab2/popup-inventory-product-tab2.component';
import { PopupInventoryProductTab3Component } from './popup-inventory-product-tab3/popup-inventory-product-tab3.component';
import { EditMappingPopupComponent } from './edit-mapping-popup/edit-mapping-popup.component';
import { CLoaderComponent } from './c-loader/c-loader.component';
import { FullPageLoaderComponent } from './full-page-loader/full-page-loader.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SortIndicatorComponent } from './sort-indicator/sort-indicator.component';
import { CLoaderTwoComponent } from './c-loader-two/c-loader-two.component';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { HRecogniserComponent } from './h-recogniser/h-recogniser.component';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { CreateNewBoxComponent } from './logistics-parent-child-view/create-new-box/create-new-box.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { TooltipDatePipe } from './pipes/tooltip-date.pipe';
import { CreateShipmentPopupComponent } from '../logistics/create-shipment-popup/create-shipment-popup.component';
import { ShipmentSeeMoreComponent } from '../logistics/shipment-see-more/shipment-see-more.component';
import {SearchPipe} from '../../pipes/search.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true
};

@NgModule({
  declarations: [
    LogoComponent,
    FormLeftBgComponent,
    LinkOfFormComponent,
    CommonSidebarComponent,
    FilterComponent,
    EditShippingComponent,
    LogisticsParentChildViewComponent,
    ShipmentSettingsPrinterComponent,
    PopupUpdateQtyComponent,
    PopupCreatePalletsComponent,
    PopupPlanShipComponent,
    PopupBulkPrintLabelsComponent,
    PopupSplitBoxComponent,
    PopupAddSkuComponent,
    PopupSwitchBoxComponent,
    PopupSplitIntoBoxComponent,
    PrintBoxLabelComponent,
    PopupPlanMultipleSkusComponent,
    PopupInventoryFilterComponent,
    PopupConfigureColumnComponent,
    InventorySkuAlertComponent,
    InventoryItemAlertComponent,
    InventorySkuErrorComponent,
    PopupSetSessionsComponent,
    LoaderComponent,
    ConfirmationComponent,
    PopupInventoryBulkOneComponent,
    PopupInventoryBulkTwoComponent,
    PopupInventoryItemsNewVendorComponent,
    PopupInventoryNewSeasonComponent,
    PopupInventorySetSeasonComponent,
    PopupInventoryNewVendorComponent,
    PopupSplitSkusComponent,
    CopyPasteGridComponent,
    PopupBulkPrintSkuListComponent,
    CloseShipmentComponent,
    PopupNewEditItemsComponent,
    InputNumberComponent,
    PercentIncDecComponent,
    FbaMinManualSetPopupComponent,
    HeaderBulkPopupComponent,
    HeaderBulkPopupTwoComponent,
    HeaderSettingsPopupComponent,
    MapPricingScheduleDateRangeComponent,
    MapPricingScheduleWeeklyComponent,
    NewMapPriceSchedulePopUpComponent,
    PricingmodelsettingsComponent,
    SellersEditPopupComponent,
    PopupAddToLogisticsComponent,
    ConnectMwsAccountComponent,
    PrintBoxLabelTwoComponent,
    PopupScanBoxComponent,
    AddnewchannelComponent,
    ChSettingsComponent,
    ChConnectionTabComponent,
    ChSettingsTabComponent,
    ChannelsComponent,
    ChDashboardComponent,
    ChSkuComponent,
    ChHistoryComponent,
    SyncHistoryComponent,
    SubmissionHistoryComponent,
    ImportHistoryComponent,
    ChSkuAllComponent,
    ChSkuDraftComponent,
    ChSkuUnlistedComponent,
    ChSkuActiveComponent,
    ChSkuFailedComponent,
    GlobalMessageComponent,
    EditpricingmodelsettingsComponent,
    EditNewMapPriceSchedulePopUpComponent,
    EditMapPricingScheduleWeeklyComponent,
    EditMapPricingScheduleDateRangeComponent,
    RateCalculatorComponent,
    OrderReturnPopupComponent,
    TabOrderreturnReturnComponent,
    TabOrderreturnOrderComponent,
    CustomTableComponent,
    CommonSearchComponent,
    CommonSearchNewComponent,
    NoFunctionalTableComponent,
    PopupOrderBulkOperationComponent,
    PopupOrderImportOrdersComponent,
    PopupOrderNewOrderComponent,
    PopupOrderNewCustomerComponent,
    PopupOrderImportDataComponent,
    PopupMultiSelectSidebarComponent,
    PopupNoReturnYetComponent,
    PopupProductPickupListComponent,
    PopupPackagingSlipComponent,
    PopupOrderSummaryComponent,
    AdviserSidebarComponent,
    VerifyClientComponent,
    PopupInventoryProductComponent,
    PopupInventoryProductTab1Component,
    PopupInventoryProductTab2Component,
    PopupInventoryProductTab3Component,
    EditMappingPopupComponent,
    CLoaderComponent,
    FullPageLoaderComponent,
    SortIndicatorComponent,
    CLoaderTwoComponent,
    NumberOnlyDirective,
    PhoneMaskDirective,
    HRecogniserComponent,
    CreateNewBoxComponent,
    DateAgoPipe,
    TooltipDatePipe,
    CreateShipmentPopupComponent,
    ShipmentSeeMoreComponent,
    SearchPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRippleModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    InfiniteScrollModule,
    NgxDaterangepickerMd.forRoot(),
    DragDropModule,
    CreditCardDirectivesModule,
    PerfectScrollbarModule,
    MatButtonModule,
    MatButtonToggleModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRippleModule,
    MatDialogModule,
    MatSelectModule,
    MatMenuModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    InfiniteScrollModule,
    LogoComponent,
    FormLeftBgComponent,
    LinkOfFormComponent,
    CommonSidebarComponent,
    FilterComponent,
    EditShippingComponent,
    LogisticsParentChildViewComponent,
    ShipmentSettingsPrinterComponent,
    PopupUpdateQtyComponent,
    PopupCreatePalletsComponent,
    PopupPlanShipComponent,
    PopupBulkPrintLabelsComponent,
    PopupSplitBoxComponent,
    PopupAddSkuComponent,
    PopupSwitchBoxComponent,
    PopupSplitIntoBoxComponent,
    PrintBoxLabelComponent,
    PrintBoxLabelTwoComponent,
    PopupPlanMultipleSkusComponent,
    PopupInventoryFilterComponent,
    PopupConfigureColumnComponent,
    InventorySkuAlertComponent,
    InventoryItemAlertComponent,
    InventorySkuErrorComponent,
    PopupSetSessionsComponent,
    ConfirmationComponent,
    PopupInventoryBulkOneComponent,
    PopupInventoryBulkTwoComponent,
    PopupInventoryItemsNewVendorComponent,
    PopupInventoryNewSeasonComponent,
    PopupInventorySetSeasonComponent,
    CopyPasteGridComponent,
    PopupBulkPrintSkuListComponent,
    NgxDaterangepickerMd,
    ConnectMwsAccountComponent,
    PopupScanBoxComponent,
    GlobalMessageComponent,
    CommonSearchComponent,
    CustomTableComponent,
    InputNumberComponent,
    CommonSearchNewComponent,
    NoFunctionalTableComponent,
    AdviserSidebarComponent,
    CLoaderComponent,
    FullPageLoaderComponent,
    SortIndicatorComponent,
    CLoaderTwoComponent,
    NumberOnlyDirective,
    PhoneMaskDirective,
    HRecogniserComponent,
    CreditCardDirectivesModule,
    DateAgoPipe,
    TooltipDatePipe,
    SearchPipe
  ],
  entryComponents: [
    PopupUpdateQtyComponent,
    PopupSwitchBoxComponent,
    PopupSplitIntoBoxComponent,
    PrintBoxLabelComponent,
    PopupPlanMultipleSkusComponent,
    InventoryItemAlertComponent,
    InventorySkuErrorComponent,
    PopupConfigureColumnComponent,
    ConfirmationComponent,
    PopupInventoryBulkOneComponent,
    PopupInventoryBulkTwoComponent,
    PopupInventoryItemsNewVendorComponent,
    PopupInventoryNewSeasonComponent,
    PopupInventorySetSeasonComponent,
    PopupSplitSkusComponent,
    PopupInventoryNewVendorComponent,
    CloseShipmentComponent,
    PopupNewEditItemsComponent,
    FbaMinManualSetPopupComponent,
    HeaderBulkPopupComponent,
    HeaderBulkPopupTwoComponent,
    HeaderSettingsPopupComponent,
    MapPricingScheduleDateRangeComponent,
    MapPricingScheduleWeeklyComponent,
    NewMapPriceSchedulePopUpComponent,
    PricingmodelsettingsComponent,
    SellersEditPopupComponent,
    PopupAddToLogisticsComponent,
    ConnectMwsAccountComponent,
    PrintBoxLabelTwoComponent,
    PopupScanBoxComponent,
    ChSettingsComponent,
    ChConnectionTabComponent,
    ChSettingsTabComponent,
    AddnewchannelComponent,
    GlobalMessageComponent,
    EditpricingmodelsettingsComponent,
    EditMapPricingScheduleDateRangeComponent,
    EditMapPricingScheduleWeeklyComponent,
    EditNewMapPriceSchedulePopUpComponent,
    RateCalculatorComponent,
    OrderReturnPopupComponent,
    PopupOrderBulkOperationComponent,
    PopupOrderImportOrdersComponent,
    PopupOrderNewOrderComponent,
    PopupOrderNewCustomerComponent,
    PopupOrderImportDataComponent,
    PopupMultiSelectSidebarComponent,
    PopupNoReturnYetComponent,
    PopupProductPickupListComponent,
    PopupPackagingSlipComponent,
    PopupOrderSummaryComponent,
    VerifyClientComponent,
    PopupInventoryProductComponent,
    EditMappingPopupComponent,
    CreateNewBoxComponent,
    CreateShipmentPopupComponent,
    ShipmentSeeMoreComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule { }
