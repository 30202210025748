import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-inventory-tab',
  templateUrl: './inventory-tab.component.html',
  styleUrls: ['./inventory-tab.component.scss']
})
export class InventoryTabComponent implements OnInit {
  public inventoryTab = '';

  constructor(public main: MainService) { }

  ngOnInit() {
    this.main.reportingType$.subscribe(value => {
      if (value) {
        this.inventoryTab = value;
      } else {
        this.inventoryTab = 'report';
      }
    });
  }

}
