import { Component, OnInit, OnDestroy } from '@angular/core';
import { BoxLabelModel, GetBoxLabelRequestModel } from 'src/app/models/shipment.model';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/services/print.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-box-label-print',
  templateUrl: './box-label-print.component.html',
  styleUrls: ['./box-label-print.component.scss']
})
export class BoxLabelPrintComponent implements OnInit, OnDestroy {
  data: string[];
  boxLabels: BoxLabelModel[];
  total: number;
  numbers: number[];
  pad = '000000';
  private destroy$ = new Subject<void>();

  constructor(
    route: ActivatedRoute,
    private printService: PrintService,
    private shipmentService: ShipmentService
  ) {
    this.data = route.snapshot.params.data.split(',');
  }

  ngOnInit() {
    this.total = parseInt(this.data[5], 10);
    this.numbers = Array(this.total).fill(0).map((x, i) => i);
    const data: GetBoxLabelRequestModel = {
      ship: this.data[0],
      account: this.data[1],
      fromBox: parseInt(this.data[2], 10),
      toBox: parseInt(this.data[3], 10),
      isOverszd: parseInt(this.data[4], 10)
    };
    this.shipmentService.GetBoxLabel(data).pipe(takeUntil(this.destroy$))
      .subscribe((value: BoxLabelModel[]) => {
        if (value) {
          this.boxLabels = value;
          this.printService.onDataReady();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public getBarcode(box: BoxLabelModel) {
    const str = `${box.box}`;
    const boxStr = `${this.pad.substring(0, this.pad.length - str.length)}${str}`;
    return `${this.data[0]}U${boxStr}`;
  }

}
