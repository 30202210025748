import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { SettingAccount } from 'src/app/models/settings.model';
import { WarehouseModel } from 'src/app/models/onboarding.model';
import {
  PlanShipModel, SkuModel, Skus, PlanShipRequestModel,
  ShipModel, Shipment, CreateShipRequestModel
} from 'src/app/models/shipment.model';
import { Subject, forkJoin } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PopupPlanMultipleSkusComponent } from '../../shared/popup-plan-multiple-skus/popup-plan-multiple-skus.component';
import { ShipmentService } from 'src/app/services/shipment.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/core/toast.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from 'src/app/services/settings.service';
import { ShipmentSeeMoreComponent } from '../shipment-see-more/shipment-see-more.component';

@Component({
  selector: 'app-create-shipment-popup',
  templateUrl: './create-shipment-popup.component.html',
  styleUrls: ['./create-shipment-popup.component.scss']
})
export class CreateShipmentPopupComponent implements OnInit, OnDestroy {
  public accounts: SettingAccount[];
  public warehouses: WarehouseModel[];
  public planShipData: any[];
  public account = '';
  public type = '0';
  public typeName = 'Standard';
  public wharehouse: string;
  public sku: string;
  public qty: number;
  public skuErrorMsg: string;
  public disableSubmit = true;
  public skus: any[] = [];
  public shipment: string;
  public searchSkuData: any[] = [];
  public clicked: number;
  public loadingPlanSku: string;
  public loadingCreateSku: string;
  public loadingCreateSkuMultiple: string;
  public loadingAllFromProduction: boolean;
  public loadingAll: boolean;
  public loadingPlanAll: boolean;
  public expanded: string;
  private destroy$ = new Subject<void>();
  private shipmentModified: boolean = false;
  public fbaTypes = ['Standard', 'Oversized', 'Hazmat', 'Small & Light']

  constructor(
    public dialogRef: MatDialogRef<PopupPlanMultipleSkusComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private toastService: ToastService,
    private inventoryService: InventoryService,
    private settingsService: SettingsService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    forkJoin([
      this.settingsService.GetAllWarehouses(),
      this.settingsService.GetAllAccounts()
    ]).pipe(takeUntil(this.destroy$))
      .subscribe(values => {
        this.warehouses = values[0] as WarehouseModel[];
        if (this.warehouses && this.warehouses.length > 0) {
          this.wharehouse = this.warehouses[0].id;
        }

        this.accounts = values[1] as SettingAccount[];
        if (this.accounts && this.accounts.length > 0) {
          this.account = this.accounts[0].name;
        }

        if (this.skus && this.skus.length > 0) {
          this.checkIfFirstCanPlan()
        } else {
          this.onAddSingleSKU();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onTypeChange($event: any) {
    this.typeName = this.fbaTypes[parseInt(this.type, 10)]
  }

  public searchSku(value: string) {
    if (value) {
      this.inventoryService.searchSkuByAccount(value, this.account).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any[]) => {
        if (res && res.length > 0) {
          this.searchSkuData = [...res.slice()];
        } else {
          this.searchSkuData = [...[]];
        }
      });
    } else {
      this.searchSkuData = [...[]];
    }
  }

  public planSingleShip(sku: any, i: number) {
    if (this.account && this.type && this.wharehouse && sku.skuId && sku.qty) {
      this.skus[i].planning = true;
      const user = this.authService.getUserInfo();
      const skus: Skus[] = [{ sku: sku.skuId, qty: parseInt(sku.qty, 10) }];
      const data: PlanShipRequestModel = {
        userId: user.userId,
        tenantId: user.tenantId,
        warehouseId: this.wharehouse,
        account: this.account,
        skus,
        shipment: this.shipment
      };
      this.shipmentService.PlanShip(data)
        .pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          if (value) {
            if (value.shipsSku && value.shipsSku.length > 0) {
              sku.ships = [...value.shipsSku[0].ships];
              const tmp = this.skus.some(x => !x.skuId || !x.qty);
              if (!tmp) {
                this.onAddSingleSKU();
              }
            }
          }
          this.loadingPlanSku = null;
          this.skus[i].planning = false;
        }, () => {
          this.loadingPlanSku = null;
          this.skus[i].planning = false;
        });
    }
  }

  public onCreate(value: any, ship: any, i: number) {
    const shipment: Shipment[] = [];
    shipment.push({
      shipmentId: ship.shipmentId,
      destination: ship.destinationId,
      qty: ship.qty
    });

    const user = this.authService.getUserInfo();
    const data: CreateShipRequestModel = {
      userId: user.userId,
      tenantId: user.tenantId,
      account: this.account,
      warehouseId: this.wharehouse,
      isOverSized: parseInt(this.type, 10),
      skuShipments: [{
        sku: value.skuId,
        shipments: shipment
      }]
    };
    this.skus[i].creatingShipment = true;
    this.shipmentService.CreateShip(data).pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.skus[i].creatingShipment = false;
        this.loadingCreateSku = null;
        const indSku = this.skus.indexOf(value);
        value.ships.splice(value.ships.indexOf(ship), 1);
        if (value.ships.length === 0) {
          this.skus.splice(indSku, 1);
        }
        this.toastService.showToaster(res);
        this.shipmentModified = true
      }, () => {
        this.loadingCreateSku = null
        this.skus[i].creatingShipment = false;
      });
  }

  public onCreateMultiple(value: any, i: number) {
    const shipment: Shipment[] = [];
    value.ships.forEach(element => {
      shipment.push({
        shipmentId: element.shipmentId,
        destination: element.destinationId,
        qty: element.qty
      });
    });

    const user = this.authService.getUserInfo();
    const data: CreateShipRequestModel = {
      userId: user.userId,
      tenantId: user.tenantId,
      account: this.account,
      warehouseId: this.wharehouse,
      isOverSized: parseInt(this.type, 10),
      skuShipments: [{
        sku: value.skuId,
        shipments: shipment
      }]
    };

    this.skus[i].creatingShipment = true;
    this.shipmentService.CreateShip(data).pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.loadingCreateSkuMultiple = null;
        this.skus[i].creatingShipment = false;
        this.skus.splice(this.skus.indexOf(value), 1);
        this.toastService.showToaster(res);
        this.shipmentModified = true
      }, () => {
        this.loadingCreateSkuMultiple = null
        this.skus[i].creatingShipment = false;
      });
  }

  public onAddAllSKUs() {
    if (this.type) {
      this.shipmentService.GetAllSkus(parseInt(this.type, 10))
        .pipe(takeUntil(this.destroy$)).subscribe((value: SkuModel[]) => {
          if (value) {
            this.skus = [...value];
          } else {
            this.skus = [...[]];
            this.onAddSingleSKU();
          }
          this.loadingAll = false;
        }, () => this.loadingAll = false);
    }
  }

  public onAddAllSKUsFromProduction() {
    if (this.type && this.account) {
      this.shipmentService.GetSkuByFilter(this.account, parseInt(this.type, 10))
        .pipe(takeUntil(this.destroy$)).subscribe((value: SkuModel[]) => {
          if (value) {
            this.skus = [...value];
          } else {
            this.skus = [...[]];
            this.onAddSingleSKU();
          }
          this.loadingAllFromProduction = false;
        }, () => this.loadingAllFromProduction = false);
    }
  }

  public onPlanShipment() {
    if (this.account && this.type && this.wharehouse && this.skus.length > 0) {
      const user = this.authService.getUserInfo();
      const skus: Skus[] = [];
      this.skus.forEach(value => {
        if (value.skuId) {
          skus.push({ sku: value.skuId, qty: value.qty });
        }
      });
      const data: PlanShipRequestModel = {
        userId: user.userId,
        tenantId: user.tenantId,
        warehouseId: this.wharehouse,
        account: this.account,
        skus,
        shipment: this.shipment
      };
      this.shipmentService.PlanShip(data)
        .pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          if (value && value.shipsSku && value.shipsSku.length > 0) {
            this.skus.forEach(ele => {
              const x = value.shipsSku.find((rec: any) => rec.sku === ele.skuId);
              if (x) {
                ele.ships = [...x.ships];
              }
            });
          }
          this.loadingPlanAll = false;
        }, () => this.loadingPlanAll = false);
    }
  }

  public getState(shipTo: string) {
    if (shipTo) {
      const arr = shipTo.split(',');
      if (arr.length >= 3) {
        return arr[arr.length - 2];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public getCity(shipTo: string) {
    if (shipTo) {
      const arr = shipTo.split(',');
      if (arr.length >= 3) {
        return arr[arr.length - 3];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public onAddSingleSKU() {
    this.skus.push({ skuId: null, qty: 0 });
  }

  public onCreateShipment(value: ShipModel, data: PlanShipModel) {
    const shipment: Shipment[] = [];
    shipment.push({
      shipmentId: value.shipmentid,
      destination: value.destinationId,
      qty: value.qty
    });
    data.ships.splice(data.ships.indexOf(value), 1);
    this.CreateShip(shipment, false);
  }

  public onCreateAll(data: PlanShipModel) {
    const shipment: Shipment[] = [];

    data.ships.forEach(value => {
      shipment.push({
        shipmentId: value.shipmentid,
        destination: value.destinationId,
        qty: value.qty
      });
    });
    this.planShipData.splice(this.planShipData.indexOf(data), 1);

    this.CreateShip(shipment, false);
  }

  public CreateShip(shipment: Shipment[], closeDialog: boolean) {
    const user = this.authService.getUserInfo();
    const data: CreateShipRequestModel = {
      userId: user.userId,
      tenantId: user.tenantId,
      account: this.account,
      warehouseId: this.wharehouse,
      isOverSized: parseInt(this.type, 10),
      skuShipments: [{
        sku: this.sku,
        shipments: shipment
      }]
    };
    if (closeDialog) {
      this.dialogRef.close();
    }

    this.shipmentService.CreateShip(data).pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.loadingCreateSku = null;
        this.toastService.showToaster(value);
      }, () => this.loadingCreateSku = null);
  }

  public closePopup() {
    this.dialogRef.close({shipmentModified: this.shipmentModified});
  }

  public seeMore(shp: any, sku: any) {
    const dialogRef = this.dialog.open(ShipmentSeeMoreComponent, {
      width: '400px',
      maxWidth: '95vw',
    });
    dialogRef.componentInstance.ship = shp;
    dialogRef.componentInstance.skuId = sku.skuId;
    dialogRef.componentInstance.qty = sku.qty;
  }

  private checkIfFirstCanPlan() {
    if(this.skus[0].qty && this.wharehouse && this.account){
      this.planSingleShip(this.skus[0], 0);
    }
  }
}
