import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginModel } from 'src/app/models/login.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageKeyEnum } from 'src/app/core/StorageKeyEnum';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public showLoader1 = false;
  public loginForm: FormGroup;
  public errorMessage: string;
  public returnUrl: string;
  private destroy$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'home';
    this.homeService.GetOpenShip().pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate([this.returnUrl]));

    this.loginForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required]
    });

    this.loginForm.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.errorMessage = null);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public login() {
    this.showLoader1 = true;
    this.authService.login(this.loginForm.get('username').value, this.loginForm.get('password').value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: LoginModel) => {
        localStorage.setItem(StorageKeyEnum.User, JSON.stringify(value));
        this.router.navigate([this.returnUrl]);
      }, (err) => {
        this.errorMessage = err.error.message;
        this.showLoader1 = false;
      });

    const element = document.getElementById('accordionSidebar');
    if (element.classList.contains('sidebartoggle')) {
      element.classList.remove('sidebartoggle');
    } else {
      element.classList.remove('sidebartoggle');
    }
  }

}
