import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'tooltipDate'
})
export class TooltipDatePipe implements PipeTransform {

  transform(value: Date, ...args: any[]): any {
    const date = new DatePipe('en-US').transform(value, 'MMMM dd, yyyy');
    const time = new DatePipe('en-US').transform(value, 'h:mm a');
    return `${date}, at ${time}`;
  }

}
