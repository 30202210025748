import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tab-orderreturn-return',
  templateUrl: './tab-orderreturn-return.component.html',
  styleUrls: ['./tab-orderreturn-return.component.scss']
})
export class TabOrderreturnReturnComponent implements OnInit {
  public newReturn = false;
  testForm = this.fb.group({
    weightLB: [36],
    weightOZ: [2],
  });
  constructor(private fb: FormBuilder) { }

  ngOnInit() { }

  public valueChange($e, param) {
    const obj = {};
    obj[param] = $e;
    this.testForm.patchValue({ ...obj });
    this.testForm.get(param).markAsDirty();
    this.testForm.get(param).markAsTouched();
  }

}
