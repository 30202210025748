import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit {
  @Input() keys: string[];
  @Input() objkeys: string[];
  @Input() arrayData: any[];
  @Input() linkable: any[];
  @Input() linkebleLink: any[];
  @Input() additionalParam: any[];
  @Output() showRightPart: EventEmitter<boolean> = new EventEmitter();
  @Output() searchValue: EventEmitter<any> = new EventEmitter();
  @ViewChild('notesTextarea') notesTextarea: ElementRef;
  @ViewChild('imageZoomHover') imageZoomHover: ElementRef;
  @ViewChild('overlayImageZoomHover') overlayImageZoomHover: ElementRef;
  @ViewChild('tableHeader') tableHeader: ElementRef;
  public checkBoxShow: boolean;
  public addEdit: number;
  public showColumn: number[];
  public addEditType = '';
  public zoomedImage = '';
  public commonSearchOpened = false;
  public checkedAll = false;
  public searchKeySelected: string;
  public searchData = '';
  public notesForm = this.fb.group({
    Notes: [''],
  });
  constructor(public main: MainService, private fb: FormBuilder) { }

  ngOnInit() {
    this.showColumn = [...Array(this.keys.length).fill(0).map((d, i) => i + 1)];
    this.checkUncheckAll(true);
    this.checkBoxShow = this.additionalParam.indexOf('checkbox') !== -1 ? false : true;
  }

  public scrolled() {
    console.log('scrolled');
  }

  public scroll($e) {
    this.tableHeader.nativeElement.scrollLeft = $e.target.scrollLeft;
  }

  public showColumnResult($e) {
    this.showColumn = $e;
    console.log(this.showColumn);
  }

  public openFilterInventory(type?: string) {
    console.log(type);
    this.searchKeySelected = type;
    this.commonSearchOpened = true;
  }

  public openAddNotes(i, type, value = '') {
    if (type === 'edit') {
      this.notesTextarea.nativeElement.setAttribute('readonly', true);
    } else {
      this.notesTextarea.nativeElement.removeAttribute('readonly');
    }
    this.addEditType = type;
    this.notesForm.setValue({ Notes: type === 'edit' ? value : '' }); this.addEdit = i;
  }

  public submitNotes() {
    console.log(this.notesForm.value);
  }

  public checkAll($e) {
    $e.stopPropagation(); $e.preventDefault();
    this.checkUncheckAll(false);
  }

  public checkIndividual($e, i) {
    $e.stopPropagation(); $e.preventDefault();
    const tempArray = [...this.arrayData];
    tempArray[i].checked = !tempArray[i].checked;
    this.arrayData = [...tempArray];
    console.log(this.arrayData);
  }

  public checkUncheckAll(first) {
    console.log(first);
    let tempArray = [...this.arrayData];
    tempArray = tempArray.map((data) => {
      return { ...data, ...{ checked: first ? false : !this.checkedAll } };
    });
    this.arrayData = [...tempArray];
    console.log(this.arrayData);
    if (!first) { this.checkedAll = !this.checkedAll; }
  }

  public openImageZoom($e, image) {
    this.zoomedImage = image;
    this.imageZoomHover.nativeElement.style.cssText = `display: grid; top: ${$e.pageY - 15}px; left: ${$e.pageX - 15}px`;
    this.overlayImageZoomHover.nativeElement.style.cssText = `display: block`;
  }

  public closeImageZoom() {
    this.imageZoomHover.nativeElement.style.cssText = this.overlayImageZoomHover.nativeElement.style.cssText = `display: none;`;
  }

  public searchDataM($e) {
    const tempArray = $e !== '' ? JSON.parse($e) : '';
    this.searchData = (tempArray[0] + tempArray[1]);
  }

  public test() {
    console.log('test success');
  }
}

