import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import {
  ShipmentModel, GetBoxesRequestModel, BoxModel, GetBoxesByFilterRequestModel, BoxesByFilterModel
} from '../../../models/shipment.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupCreatePalletsComponent } from '../popup-create-pallets/popup-create-pallets.component';
import { PopupAddToLogisticsComponent } from '../popup-add-to-logistics/popup-add-to-logistics.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/core/toast.service';
import { CommonService } from 'src/app/services/common.service';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-edit-shipping',
  templateUrl: './edit-shipping.component.html',
  styleUrls: ['./edit-shipping.component.scss']
})
export class EditShippingComponent implements OnInit, OnChanges {

  @Output() closeSidebar = new EventEmitter<boolean>();
  @Input() shipment: ShipmentModel;
  public boxSums: BoxModel[];

  public filterType = '0';
  public filterFirst = null;
  public filterLast = null;
  public groupedArray: BoxesByFilterModel[][] = [];
  public expand: string;
  public totalBoxByFilterQty: number;
  public totalBoxByFilterSkus: number;
  public totalBoxByFilterBoxes: number;
  public shipFromList: string[];
  public loadingUpdateBoxContent: string;
  public loadingUpdateShipmentOnAmazon: string;
  private destroy$ = new Subject<void>();
  creatingPallet: boolean;

  constructor(
    private shipmentService: ShipmentService,
    public dialog: MatDialog,
    private toastService: ToastService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.shipmentService.GetWhList().pipe(takeUntil(this.destroy$))
      .subscribe((resp: string[]) => this.shipFromList = [...resp]);
  }

  ngOnChanges(changes: any) {
    if (changes.shipment && this.shipment) {
      this.getIncludedSkus();
      this.populateRightBoxesGrid();
    }
  }

  public addedToShipment() {
    this.getIncludedSkus();
    this.populateRightBoxesGrid();
  }

  public onFilterGoClick(event: any) {
    this.filterType = event.filterType;
    this.filterFirst = event.filterFirst;
    this.filterLast = event.filterLast;
    this.populateRightBoxesGrid();
  }

  public populateRightBoxesGrid() {
    let fisrt = null;
    let last = null;
    if (this.filterFirst) {
      fisrt = parseInt(this.filterFirst, 10);
    }
    if (this.filterLast) {
      last = parseInt(this.filterLast, 10);
    }
    if (this.filterType) {
      const data: GetBoxesByFilterRequestModel = {
        ship: this.shipment.shipmentId,
        account: this.shipment.account,
        isOverszd: this.shipment.isoverszd,
        loadtype: parseInt(this.filterType, 10),
        fromBox: fisrt,
        toBox: last
      };
      if (parseInt(this.filterType, 10) === 4) {
        this.totalBoxByFilterQty = 0;
        this.groupedArray = [...[]];
        this.totalBoxByFilterBoxes = 0;
      } else {
        this.shipmentService.GetBoxesByFilter(data).pipe(takeUntil(this.destroy$))
          .subscribe((resp: any) => {
            const value: BoxesByFilterModel[] = resp.boxesFilters;
            if (value) {
              let qtyTotal = 0;
              value.forEach(ele => {
                qtyTotal += ele.qty;
              });
              this.totalBoxByFilterQty = value.reduce((acc, cur) => acc + cur.qty, 0);
              this.totalBoxByFilterSkus = resp.total;
              this.groupedArray = [...this.groupBy(value, 'firstBox')];
              this.totalBoxByFilterBoxes = qtyTotal;
            } else {
              this.totalBoxByFilterQty = 0;
              this.totalBoxByFilterSkus = 0;
              this.groupedArray = [...[]];
              this.totalBoxByFilterBoxes = 0;
            }
          });
      }
    }
  }

  public whChanged() {
    this.shipmentService.UpdateShipmentWh(this.shipment)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.toastService.showToaster('Warehouse updated successfully'));
  }

  public enableError(shipment: ShipmentModel) {
    this.shipmentService.EnableShipmentError(shipment.shipmentId).pipe(takeUntil(this.destroy$))
      .subscribe(() => shipment.errDismissed = false);
  }

  public openCreatePallet() {
    if (this.shipment && this.shipment.shipmentId && this.shipment.account) {
      const dialogRef = this.dialog.open(PopupCreatePalletsComponent, {
        width: '400px',
        maxWidth: '95vw',
        data: {externalMode: true}
      });
      dialogRef.componentInstance.externalMode = true;
      dialogRef.componentInstance.shipmentId = this.shipment.shipmentId;
      dialogRef.componentInstance.account = this.shipment.account;
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.creatingPallet = true;
          this.shipmentService.PalletLabel(data)
              .subscribe((value: any) => {
                const contentDisposition = value.headers.get('content-disposition') || '';
                const matches = /filename=([^;]+)/ig.exec(contentDisposition);
                const fileName = (matches[1] || 'untitled').trim();
                this.creatingPallet = false;
                saveAs(value.body, fileName);
              }, error => {
                this.creatingPallet = false;
              });
        }
      })
    }
  }
  public openAddToLogistics() {
    const dialogRef = this.dialog.open(PopupAddToLogisticsComponent, {
      width: '600px',
      maxWidth: '95vw',
    });
    dialogRef.componentInstance.shipment = this.shipment;
  }

  public onTotalWeightChange() {
    this.populateRightBoxesGrid();
  }

  public deleteFromBox() {
    this.populateRightBoxesGrid();
  }


  public onSwitchBox() {
    this.populateRightBoxesGrid();
  }

  public onSplitIntoBox() {
    this.populateRightBoxesGrid();
  }

  public onUnpackBox() {
    this.populateRightBoxesGrid();
  }
  public onScanBox() {
    this.populateRightBoxesGrid();
  }

  public onUpdateQtyRightClick() {
    this.populateRightBoxesGrid();
  }

  private groupBy(collection: any[], property: any) {
    let val: any;
    let index: any;
    const values = [];
    const result = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) {
        result[index].push(collection[i]);
      } else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  private getIncludedSkus() {
    const data: GetBoxesRequestModel = {
      account: this.shipment.account,
      ship: this.shipment.shipmentId,
      isOverszd: this.shipment.isoverszd
    };
    this.shipmentService.GetBoxSum(data).pipe(takeUntil(this.destroy$))
      .subscribe((value: BoxModel[]) => {
        if (value) {
          this.boxSums = [...value];
        } else {
          this.boxSums = [...[]];
        }
      });
  }
  fileSelected($event: any) {
    const file: File = $event.target.files[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = e => {
      // upload to api
    };
    fileReader.readAsDataURL(file);
  }

  public updateShipment(shipment: ShipmentModel) {
    this.commonService.UpdateShipment(shipment.shipmentId, shipment.account).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastService.showToaster('Shipment updated on amazon');
        this.loadingUpdateShipmentOnAmazon=null;
      }, error => {
        this.toastService.showErrorToaster('Fail to update Shipment');
        this.loadingUpdateShipmentOnAmazon = null;
      });
  }

  public updateBoxContent(shipment: ShipmentModel) {
    this.commonService.UpdateBoxContent(shipment.account, shipment.shipmentId).pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.toastService.showToaster('Box Content updated on amazon');
        this.loadingUpdateBoxContent = null;
      }, error => {
        this.toastService.showErrorToaster('Fail to update Box Content');
        this.loadingUpdateBoxContent = null;
      });
  }
}
