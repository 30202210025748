import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-copy-paste-grid',
  templateUrl: './copy-paste-grid.component.html',
  styleUrls: ['./copy-paste-grid.component.scss']
})
export class CopyPasteGridComponent implements OnInit {

  @Input() columnList: string[];
  @Input() invalidItem$: BehaviorSubject<any>;
  @Input() invalidSku$: BehaviorSubject<any>;
  @Input() validated$: BehaviorSubject<any>;
  @Input() mapped$: BehaviorSubject<any>;
  @Input() allUnmapped$: BehaviorSubject<any>;
  @Input() clearRows$: Subject<void>;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDataChange = new EventEmitter<any>();
  public displayedColumns: string[] = ['Click here and then paste records (CTRL + V)'];
  public dataSource: any[] = [];
  public invalidItems: any[] = [];
  public invalidSkus: any[] = [];
  public invalids: any[] = [];

  constructor() { }

  ngOnInit() {
    this.invalidItem$.subscribe(event => {
      if (event && event.columnName === 'ITEM ID') {
        this.invalidItems.push(event.value);
      }
    });

    this.invalidSku$.subscribe(event => {
      if (event && event.columnName === 'SKU') {
        this.invalidSkus.push(event.value);
      }
    });

    this.validated$.subscribe((event: any[]) => {
      this.invalidItems = [...[]];
      this.invalidSkus = [...[]];
      if (event && event.length > 0) {
        this.invalids = [...event];
      } else {
        this.invalids = [...[]];
      }
    });

    this.mapped$.subscribe((event: any[]) => {
      this.invalidItems = [...[]];
      this.invalidSkus = [...[]];
      if (event && event.length > 0) {
        this.invalids = [...event];
      } else {
        this.invalids = [...[]];
      }

      this.dataSource = [...[]];
      if (this.invalids && this.invalids.length > 0) {
        this.invalids.forEach(element => {
          this.dataSource.push({
            SKU: element.Sku,
            'ITEM ID': element.ItemId,
            QTY: element.Qty
          });
        });
      }
    });

    this.allUnmapped$.subscribe((event: any[]) => {
      this.invalidItems = [...[]];
      this.invalidSkus = [...[]];
      this.invalids = [...[]];

      this.dataSource = [...[]];
      if (event && event.length > 0) {
        this.displayedColumns = [...['SKU', 'ITEM ID', 'QTY', 'TITLE', 'IMAGE']];
        event.forEach(element => {
          this.dataSource.push({
            SKU: element.sku,
            'ITEM ID': element.itemId,
            QTY: element.qty,
            TITLE: element.title,
            IMAGE: element.image
          });
        });
      }
    });

    this.clearRows$.subscribe(() => {
      this.displayedColumns = ['Click here and then paste records (CTRL + V)'];
      this.dataSource = [...[]];
    });
  }

  public isInvalidRow(element: any) {
    return this.invalids.some(rec => rec.Sku === element.SKU && rec.ItemId === element['ITEM ID']);
  }

  data(event: ClipboardEvent) {
    this.displayedColumns = [...this.columnList];
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData.getData('text');
    const rowData = pastedText.split('\n');
    const data = [];

    rowData.forEach(el => {
      const row = {};
      this.displayedColumns.forEach((a, index) => {
        if (!el.split('\t')[index]) {
          row[a] = null;
        } else {
          row[a] = el.split('\t')[index];
        }
      });
      data.push(row);
    });
    this.dataSource = data;
  }

  public pasteInCell(e: any) {
    e.stopPropagation();
  }

  public onCellChange(columnName: string, value: any) {
    this.onDataChange.next({ columnName, value });
  }

  public isInvalid(columnName: string, value: any) {
    if (columnName === 'ITEM ID') {
      return this.invalidItems.some(rec => rec === value);
    }
    if (columnName === 'SKU') {
      return this.invalidSkus.some(rec => rec === value);
    }
  }

}
