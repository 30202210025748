import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SettingAccount, WarehouseModel } from 'src/app/models/settings.model';
import {
  PlanShipRequestModel, PlanShipModel, ShipModel, GetSkuByNameRequestModel, CreateShipRequestModel, Skus, Shipment
} from 'src/app/models/shipment.model';
import { SettingsService } from 'src/app/services/settings.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/core/toast.service';
import { PopupPlanMultipleSkusComponent } from '../popup-plan-multiple-skus/popup-plan-multiple-skus.component';
import { MainService } from '../../../services/main.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/services/inventory.service';

@Component({
  selector: 'app-popup-plan-ship',
  templateUrl: './popup-plan-ship.component.html',
  styleUrls: ['./popup-plan-ship.component.scss']
})
export class PopupPlanShipComponent implements OnInit, OnDestroy {

  public accounts: SettingAccount[];
  public warehouses: WarehouseModel[];
  public planShipData: PlanShipModel[];
  public account = 'Mix N More';
  public type = '0';
  public wharehouse = 'BRD';
  public sku: string;
  public qty: string;
  public skuErrorMsg: string;
  public disableSubmit = true;
  public showCreateAll: boolean;

  public resultData: ShipModel[] = [];
  public searchSkuData: any[] = [];
  public shipment: string;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PopupPlanShipComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialog: MatDialog,
    private shipmentService: ShipmentService,
    private inventoryService: InventoryService,
    private authService: AuthService,
    private settingsService: SettingsService,
    private toastService: ToastService,
    public main: MainService
  ) { }

  ngOnInit() {
    this.settingsService.GetAllWarehouses().pipe(takeUntil(this.destroy$))
      .subscribe((value: WarehouseModel[]) => this.warehouses = value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public positiveIntegerOnly(item: string) {
    if (item === 'qty' && this.qty) {
      if (parseInt(this.qty, 10) < 0) {
        this.qty = '0';
      } else {
        this.qty = Math.floor(parseInt(this.qty, 10)).toString();
      }
    }
  }

  public searchSku(value: string) {
    if (value) {
      this.inventoryService.searchSku(value).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any[]) => {
        if (res && res.length > 0) {
          this.searchSkuData = [...res.slice().filter(rec => rec.account === this.account)];
          if (this.searchSkuData.length === 0) {
            this.skuErrorMsg = 'Sku does not exist in database';
          }
        } else {
          this.searchSkuData = [...[]];
          this.skuErrorMsg = 'Sku does not exist in database';
        }
      });
    } else {
      this.searchSkuData = [...[]];
      this.skuErrorMsg = null;
    }
  }

  public onPlanShipClick() {
    if (this.account && this.type && this.wharehouse && this.sku && this.qty) {
      const user = this.authService.getUserInfo();
      const skus: Skus[] = [{ sku: this.sku, qty: parseInt(this.qty, 10) }];
      const data: PlanShipRequestModel = {
        userId: user.userId,
        tenantId: user.tenantId,
        warehouseId: this.wharehouse,
        account: this.account,
        skus,
        shipment: this.shipment
      };
      this.shipmentService.PlanShip(data)
        .pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          if (value) {
            this.planShipData = value.skus;
            if (this.planShipData && this.planShipData.length > 0) {
              this.resultData.push(...this.planShipData[0].ships.slice());
              this.showCreateAll = true;
            }
          }
        });
    }
  }

  public onCreate(value: ShipModel) {
    const shipment: Shipment[] = [];
    shipment.push({
      shipmentId: value.shipmentid,
      destination: value.destinationId,
      qty: value.qty
    });
    this.resultData.splice(this.resultData.indexOf(value), 1);
    this.CreateShip(shipment, false);
  }

  public onCreateAll() {
    const shipment: Shipment[] = [];
    this.resultData.forEach(value => {
      shipment.push({
        shipmentId: value.shipmentid,
        destination: value.destinationId,
        qty: value.qty
      });
    });
    this.CreateShip(shipment, true);
  }

  public CreateShip(shipment: Shipment[], closeDialog: boolean) {
    const user = this.authService.getUserInfo();
    const data: CreateShipRequestModel = {
      userId: user.userId,
      tenantId: user.tenantId,
      account: this.account,
      warehouseId: this.wharehouse,
      isOverSized: parseInt(this.type, 10),
      skuShipments: [{
        sku: this.sku,
        shipments: shipment
      }]
    };

    this.shipmentService.CreateShip(data).pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.toastService.showToaster(value);
        if (closeDialog) {
          this.dialogRef.close();
        }
      });
  }

  public onPlanForMultipleSkus() {
    if (this.accounts) {
      this.dialogRef.close();
      const dialogRef = this.dialog.open(PopupPlanMultipleSkusComponent, {
        width: '1000px',
        maxWidth: '95vw',
      });
      dialogRef.componentInstance.accounts = this.accounts;
      dialogRef.componentInstance.warehouses = this.warehouses;
      dialogRef.componentInstance.shipment = this.shipment;
    }
  }

  public onSkuBlur() {
    if (this.sku && this.account) {
      const data: GetSkuByNameRequestModel = {
        account: this.account,
        sku: this.sku
      };
      this.shipmentService.GetSkuByName(data)
        .pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.disableSubmit = false;
          this.skuErrorMsg = null;
        }, (error) => {
          this.disableSubmit = true;
          this.skuErrorMsg = error.error.message;
        });
    }
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public incDecValue(incDec, model) {
    const value = this[model] !== undefined ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === undefined ? 1 : value + 1 : this[model] === undefined ? 0 : value > 0 ? value - 1 : 0;
  }
}
