import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from '../../../services/main.service';
import { HeaderBulkPopupTwoComponent } from '../header-bulk-popup-two/header-bulk-popup-two.component';
@Component({
  selector: 'app-header-bulk-popup',
  templateUrl: './header-bulk-popup.component.html',
  styleUrls: ['./header-bulk-popup.component.scss']
})
export class HeaderBulkPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<HeaderBulkPopupComponent>, private main: MainService) { }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }
  openPopUp() {
    this.main.openBroadcastPopup(HeaderBulkPopupTwoComponent, 'auto', 1200, '90%', 'header_bulk_popup_two');
  }
}
