import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PricingService } from 'src/app/services/pricing.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-map-pricing-schedule-date-range',
  templateUrl: './map-pricing-schedule-date-range.component.html',
  styleUrls: ['./map-pricing-schedule-date-range.component.scss']
})
export class MapPricingScheduleDateRangeComponent implements OnInit {

  private destroy$ = new Subject<void>();
  public dateFrom: string;
  public dateTo: string;
  public model: number;
  public name: string;
  public type: number;
  public timeFrom: string;
  public timeTo: string;
  public ignore: boolean;
  public ignHide: boolean;
  public ison: boolean;

  constructor(
    private dialog: MatDialogRef<MapPricingScheduleDateRangeComponent>,
    private priceService: PricingService
  ) { }

  ngOnInit() {
  }

  closePopUp() {
    this.dialog.close();
  }

  public save() {
    const data = {
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
      name: this.name,
      timeFrom: this.timeTo,
      timeTo: this.timeTo,
      ignore: this.ignore,
      ignHide: this.ignHide,
      ison: this.ison,
    };
    this.priceService.saveMapModel(data).pipe(takeUntil(this.destroy$)).subscribe((value) => {
      console.log(value);
    });
  }

}
