import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-shipment-settings-printer',
  templateUrl: './shipment-settings-printer.component.html',
  styleUrls: ['./shipment-settings-printer.component.scss']
})
export class ShipmentSettingsPrinterComponent implements OnInit {

  public type = 'printer';
  public textareaText = `Once the order is shipped by the supplier and fulfilled in Oberlo,
  the shipping confirmation email with a tracking code can be automatically sent to the customers.`;
  @Output() closeSidebar = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

}
