import { Component, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { InventoryService } from '../../../services/inventory.service';
import { takeUntil } from 'rxjs/operators';
import { CopyPasteGridComponent } from '../copy-paste-grid/copy-paste-grid.component';
import { MapValidateRequestModel, MapValidateResponseModel } from 'src/app/models/inventory.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-order-bulk-operation',
  templateUrl: './popup-order-bulk-operation.component.html',
  styleUrls: ['./popup-order-bulk-operation.component.scss']
})
export class PopupOrderBulkOperationComponent implements OnDestroy {

  @ViewChild('copyPasteGrid') copyPasteGrid: CopyPasteGridComponent;
  public columnList = ['SKU', 'ITEM ID', 'QTY'];
  public invalidItem$ = new BehaviorSubject<any>(null);
  public invalidSku$ = new BehaviorSubject<any>(null);
  public validated$ = new BehaviorSubject<any>(null);
  public mapped$ = new BehaviorSubject<any>(null);
  public allUnmapped$ = new BehaviorSubject<any>(null);
  public clearRows$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(
    private inventoryService: InventoryService,
    public dialogRef: MatDialogRef<PopupOrderBulkOperationComponent>
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public onClearRowClick() {
    this.clearRows$.next();
  }

  public onDataChange(event: { columnName: any; value: any; }) {
    if (event.columnName === 'SKU') {
      this.inventoryService.MapValidateSku([event.value])
        .subscribe(data => {
          if (!data) {
            this.invalidSku$.next(event);
          }
        });
    }

    if (event.columnName === 'ITEM ID') {
      this.inventoryService.MapValidateItem([event.value]).pipe(
        takeUntil(this.destroy$)
      ).subscribe(data => {
        if (!data) {
          this.invalidItem$.next(event);
        }
      });
    }
  }

  public onValidateClick() {
    const data: MapValidateRequestModel[] = [];
    if (this.copyPasteGrid.dataSource.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.copyPasteGrid.dataSource.length; i++) {
        data.push({
          itemId: this.copyPasteGrid.dataSource[i]['ITEM ID'],
          sku: this.copyPasteGrid.dataSource[i].SKU,
          qty: parseInt(this.copyPasteGrid.dataSource[i].QTY, 10)
        });
      }
    }
    this.inventoryService.MapValidate(data).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: MapValidateResponseModel[]) => {
      const invalids = data.filter((el) => {
        return !value.some(rec => rec.sku === el.sku && rec.itemId === el.itemId);
      });
      this.validated$.next(invalids);
    });

  }

  public onValidateSaveClick() {
    const data: MapValidateRequestModel[] = [];
    if (this.copyPasteGrid.dataSource.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.copyPasteGrid.dataSource.length; i++) {
        data.push({
          itemId: this.copyPasteGrid.dataSource[i]['ITEM ID'],
          sku: this.copyPasteGrid.dataSource[i].SKU,
          qty: parseInt(this.copyPasteGrid.dataSource[i].QTY, 10)
        });
      }
    }
    this.inventoryService.Map(data).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: MapValidateResponseModel[]) => {
      const invalids = data.filter((el) => {
        return !value.some(rec => rec.sku === el.sku && rec.itemId === el.itemId);
      });
      this.mapped$.next(invalids);
    });

  }

  public allUnmapped() {
    this.inventoryService.AllUnmapped().pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: any[]) => this.allUnmapped$.next(value));
  }

  public isDisabled() {
    if (this.copyPasteGrid && this.copyPasteGrid.dataSource && this.copyPasteGrid.dataSource.length > 0) {
      let res = false;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.copyPasteGrid.dataSource.length; i++) {
        const item = this.copyPasteGrid.dataSource[i]['ITEM ID'];
        const sku = this.copyPasteGrid.dataSource[i].SKU;
        const qty = this.copyPasteGrid.dataSource[i].QTY;
        if (!item || !sku || !qty) {
          res = true;
        }
      }
      return res;
    } else {
      return true;
    }
  }

}
