import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-verify-client',
  templateUrl: './verify-client.component.html',
  styleUrls: ['./verify-client.component.scss']
})
export class VerifyClientComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
