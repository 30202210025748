import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  ShipModel, PlanShipModel, SkuModel, Skus, PlanShipRequestModel, Shipment, CreateShipRequestModel
} from 'src/app/models/shipment.model';
import { SettingAccount, WarehouseModel } from 'src/app/models/settings.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentService } from 'src/app/services/shipment.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/core/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InventoryService } from 'src/app/services/inventory.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-popup-plan-multiple-skus',
  templateUrl: './popup-plan-multiple-skus.component.html',
  styleUrls: ['./popup-plan-multiple-skus.component.scss']
})
export class PopupPlanMultipleSkusComponent implements OnInit, OnDestroy {
  public accounts: SettingAccount[];
  public warehouses: WarehouseModel[];
  public planShipData: PlanShipModel[];
  public account = 'Mix N More';
  public type = '0';
  public wharehouse = 'BRD';
  public sku: string;
  public qty: number;
  public skuErrorMsg: string;
  public disableSubmit = true;
  public skus: SkuModel[] = [];
  public shipment: string;
  public searchSkuData: any[] = [];
  showCreateAll: boolean;
  public clicked: number;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PopupPlanMultipleSkusComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private toastService: ToastService,
    private inventoryService: InventoryService,
    private settingsService: SettingsService
  ) { }

  ngOnInit(): void {
    this.settingsService.GetAllWarehouses().pipe(takeUntil(this.destroy$))
      .subscribe((value: WarehouseModel[]) => this.warehouses = value);
    this.settingsService.GetAllAccounts().pipe(takeUntil(this.destroy$))
      .subscribe((value: SettingAccount[]) => this.accounts = value);
    this.onAddSingleSKU();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public searchSku(value: string) {
    if (value) {
      this.inventoryService.searchSku(value).pipe(
        takeUntil(this.destroy$)
      ).subscribe((res: any[]) => {
        if (res && res.length > 0) {
          this.searchSkuData = [...res.slice().filter(rec => rec.account === this.account)];
          if (this.searchSkuData.length === 0) {
            // this.skuErrorMsg = 'Sku does not exist in database';
          }
        } else {
          this.searchSkuData = [...[]];
          // this.skuErrorMsg = 'Sku does not exist in database';
        }
      });
    } else {
      this.searchSkuData = [...[]];
      // this.skuErrorMsg = null;
    }
  }

  public addNewRow(sku) {
    if (sku.skuId && sku.qty > 0) {
      this.onAddSingleSKU();
    }
  }

  public onAddAllSKUs() {
    if (this.type && this.account) {
      this.shipmentService.GetSkuByFilter(this.account, parseInt(this.type, 10))
        .pipe(takeUntil(this.destroy$)).subscribe((value: SkuModel[]) => {
          if (value) {
            this.skus = [...value];
          }
        });
    }
  }

  public onPlanShipment() {
    if (this.account && this.type && this.wharehouse && this.skus.length > 0) {
      const user = this.authService.getUserInfo();
      const skus: Skus[] = [];
      this.skus.forEach(value => {
        skus.push({ sku: value.skuId, qty: value.qty });
      });
      const data: PlanShipRequestModel = {
        userId: user.userId,
        tenantId: user.tenantId,
        warehouseId: this.wharehouse,
        account: this.account,
        skus,
        shipment: this.shipment
      };
      this.shipmentService.PlanShip(data)
        .pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
          if (value) {
            this.planShipData = value.skus;
            this.showCreateAll = true;
          }
        });
    }
  }

  public onAddSingleSKU() {
    this.skus.push({ skuId: null, qty: 0 });
  }

  public onCreateShipment(value: ShipModel, data: PlanShipModel) {
    const shipment: Shipment[] = [];
    shipment.push({
      shipmentId: value.shipmentid,
      destination: value.destinationId,
      qty: value.qty
    });
    data.ships.splice(data.ships.indexOf(value), 1);
    this.CreateShip(shipment, false);
  }

  public onCreateAll(data: PlanShipModel) {
    const shipment: Shipment[] = [];

    data.ships.forEach(value => {
      shipment.push({
        shipmentId: value.shipmentid,
        destination: value.destinationId,
        qty: value.qty
      });
    });
    this.planShipData.splice(this.planShipData.indexOf(data), 1);

    this.CreateShip(shipment, false);
  }

  public CreateShip(shipment: Shipment[], closeDialog: boolean) {
    const user = this.authService.getUserInfo();
    const data: CreateShipRequestModel = {
      userId: user.userId,
      tenantId: user.tenantId,
      account: this.account,
      warehouseId: this.wharehouse,
      isOverSized: parseInt(this.type, 10),
      skuShipments: [{
        sku: this.sku,
        shipments: shipment
      }]
    };
    if (closeDialog) {
      this.dialogRef.close();
    }

    this.shipmentService.CreateShip(data).pipe(takeUntil(this.destroy$))
      .subscribe(value => this.toastService.showToaster(value));
  }

  public closePopup() {
    this.dialogRef.close();
  }

}
