import { Component, OnInit, Inject } from '@angular/core';

import { MainService } from '../../../services/main.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-map-pricing-schedule-weekly',
  templateUrl: './edit-map-pricing-schedule-weekly.component.html',
  styleUrls: ['./edit-map-pricing-schedule-weekly.component.scss']
})
export class EditMapPricingScheduleWeeklyComponent implements OnInit {

  public id: number;
  public model: number;
  public name: string;
  public type: number;
  public dateFrom: string;
  public dateTo: string;
  public timeFrom: string;
  public timeTo: string;
  public ignore: any;
  public ignHide: any;
  public ison: any;
  public wDays;
  public d = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public main: MainService,
    private dialog: MatDialogRef<EditMapPricingScheduleWeeklyComponent>) { }

  ngOnInit() {
    console.log(this.data);
    this.getParams();
  }

  closePopUp() {
    this.dialog.close();
  }

  getParams() {
    if (this.data != null) {
      const mapModel = this.data.mapModel[0];
      this.id = this.data.prcModels.id;
      this.model = mapModel.model;
      this.name = mapModel.name;
      this.type = mapModel.type;
      this.timeFrom = mapModel.timeFrom;
      this.timeTo = mapModel.timeTo;
      this.dateFrom = mapModel.dateFrom;
      const fromDate: any = new Date(this.dateFrom);
      this.dateFrom = fromDate.getFullYear() + '-' + fromDate.getMonth() + 1 + '-' + fromDate.getDay();
      this.dateTo = mapModel.dateTo;
      const toDate: any = new Date(this.dateTo);
      this.dateTo = toDate.getFullYear() + '-' + toDate.getMonth() + 1 + '-' + toDate.getDay();
      this.ignore = this.toNumber(mapModel.ignore);
      this.ignHide = this.toNumber(mapModel.ignHide);
      this.ison = this.toNumber(mapModel.ison);
      this.wDays = mapModel.wDays;
    }
  }



  public save() {
    /*this.priceService.saveMapModel(data).pipe(takeUntil(this.destroy$)).subscribe((value) => {
      console.log(value);
    });*/
  }


  public toBoolean(value: number) {
    return Boolean(value);
  }

  public toNumber(value: boolean) {
    return Number(value);
  }

}
