import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adviser-sidebar',
  templateUrl: './adviser-sidebar.component.html',
  styleUrls: ['./adviser-sidebar.component.scss']
})
export class AdviserSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
