import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c-loader-two',
  templateUrl: './c-loader-two.component.html',
  styleUrls: ['./c-loader-two.component.scss']
})
export class CLoaderTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
