import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentService } from 'src/app/services/shipment.service';
import { ToastService } from '../../../core/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popup-add-sku',
  templateUrl: './popup-add-sku.component.html',
  styleUrls: ['./popup-add-sku.component.scss']
})
export class PopupAddSkuComponent implements OnDestroy {

  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PopupAddSkuComponent>,
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public closePopup() {
    this.dialogRef.close();
  }

  uploadFile(event: any) {
    const formData = new FormData();
    if (event.target.files.length > 0) {
      formData.append('formFile', event.target.files[0], event.target.files[0].name);
      this.shipmentService.ImportBoxes(formData).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.toastService.showToaster('Boxes added successfully');
        this.dialogRef.close(true);
      });
    }
  }

}
