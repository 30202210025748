import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-product-pickup-list',
  templateUrl: './popup-product-pickup-list.component.html',
  styleUrls: ['./popup-product-pickup-list.component.scss']
})
export class PopupProductPickupListComponent implements OnInit {
  public arrayData = [
    {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    },
    {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    },
    {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    },
    {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    },
    {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }, {
      image:
        'assets/images/order_two.png',
      item:
        'ESD-IMP5032SS',
      description:
        '11',
      location:
        '006-9',
      QTY:
        11,
    }

  ];

  public keys = [
    'Image',
    'Item #',
    'Description',
    'Location',
    'QTY',

  ];
  public objkeys = [
    'image',
    'item',
    'description',
    'location',
    'QTY',

  ];

  public additionalParam = ['chceckbox'];
  constructor() { }

  ngOnInit() {
  }

}
