import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { AccountModel, WarehouseModel } from '../models/onboarding.model';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private url = 'api/onboarding';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  options = { headers: this.headers };

  constructor(private http: HttpClient) { }

  public InsertAccount(accountModel: AccountModel) {
    const data = JSON.stringify(accountModel);
    return this.http.post(`${environment.apiUrl}/${this.url}/account`, data,
      { headers: this.headers, observe: 'response', responseType: 'text' });
  }

  public UpdateAccount(accountModel: AccountModel) {
    const data = JSON.stringify(accountModel);
    return this.http.put(`${environment.apiUrl}/${this.url}/account`, data, this.options);
  }

  public InsertWarehouse(warehouseModel: WarehouseModel) {
    const data = JSON.stringify(warehouseModel);
    return this.http.post(`${environment.apiUrl}/${this.url}/addwh`, data, this.options);
  }

  public AmazonLink() {
    return this.http.get(`${environment.apiUrl}/${this.url}/amazonlink`, { headers: this.headers, responseType: 'text' });
  }

  public verifyCredential(verifycredential: any) {
    return this.http.post(`${environment.apiUrl}/${this.url}/verifycredential`, verifycredential, this.options);
  }

  public getStates() {
    return this.http.get('./assets/states.json');
  }
}
