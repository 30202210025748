import { Component, OnInit, OnDestroy } from '@angular/core';
import { SkuLabelModel, GetSkuLabelRequestModel } from 'src/app/models/shipment.model';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/services/print.service';
import { ShipmentService } from 'src/app/services/shipment.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sku-label-print',
  templateUrl: './sku-label-print.component.html',
  styleUrls: ['./sku-label-print.component.scss']
})
export class SkuLabelPrintComponent implements OnInit, OnDestroy {
  data: string[];
  skuLabel: SkuLabelModel;
  barcode: string;
  total: number;
  numbers: number[];
  private destroy$ = new Subject<void>();

  constructor(
    route: ActivatedRoute,
    private printService: PrintService,
    private shipmentService: ShipmentService
  ) {
    this.data = route.snapshot.params.data.split(',');
  }

  ngOnInit() {
    this.total = parseInt(this.data[2], 10);
    this.numbers = Array(this.total).fill(0).map((x, i) => i);
    const data: GetSkuLabelRequestModel = {
      skus: [this.data[0]],
      accounts: [this.data[1]],
      qtys: [this.total],
      isOverszds: [parseInt(this.data[3], 10)]
    };
    this.shipmentService.GetSkuLabel(data).pipe(takeUntil(this.destroy$))
      .subscribe((value: SkuLabelModel[]) => {
        if (value) {
          this.skuLabel = value[0];
          this.barcode = value[0].idType + ': ' + value[0].id + ' - new ';
          this.printService.onDataReady();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
