import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-packaging-slip',
  templateUrl: './popup-packaging-slip.component.html',
  styleUrls: ['./popup-packaging-slip.component.scss']
})
export class PopupPackagingSlipComponent implements OnInit {
  public arrayData = [
    {
      image: 'assets/images/order_two.png',
      item: 'ESD-IMP5032SS',
      description: '11',
      QTY: 11,
      unit: '$12.95',
      total: '$12.95'
    },
    {
      image: 'assets/images/order_two.png',
      item: 'ESD-IMP5032SS',
      description: '11',
      QTY: 11,
      unit: '$12.95',
      total: '$12.95'
    },
    {
      image: 'assets/images/order_two.png',
      item: 'ESD-IMP5032SS',
      description: '11',
      QTY: 11,
      unit: '$12.95',
      total: '$12.95'
    },
  ];

  public keys = [
    'Image',
    'Item #',
    'Description',
    'QTY',
    'Unit',
    'Total'
  ];
  public objkeys = [
    'image',
    'item',
    'description',
    'QTY',
    'unit',
    'total'
  ];

  public additionalParam = [];
  constructor() { }

  ngOnInit() {
  }

}
