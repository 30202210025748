import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-inventory-bulk-two',
  templateUrl: './popup-inventory-bulk-two.component.html',
  styleUrls: ['./popup-inventory-bulk-two.component.scss']
})
export class PopupInventoryBulkTwoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  public skuGo($e){
    console.log($e);
  }

}
