import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { AccountModel } from 'src/app/models/onboarding.model';
import { MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { takeUntil, debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import { MainService } from '../../../services/main.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoginModel } from 'src/app/models/login.model';

@Component({
    selector: 'app-connect-mws-account',
    templateUrl: './connect-mws-account.component.html',
    styleUrls: ['./connect-mws-account.component.scss']
})
export class ConnectMwsAccountComponent implements OnInit, AfterViewInit, OnDestroy {

    public mwsAccountForm: FormGroup;
    public amazonLink: string;
    public isModal: boolean;
    public isEdit: boolean;
    public account: AccountModel;
    public sellerID = { error: null, searched: false };
    public marketplaceID = { error: null, searched: false };
    public tokenId = { error: null, searched: false };
    public errorResolved = false;
    public showLoader1 = false;
    private destroy$ = new Subject<void>();
    public data = { TenantId: null, Seller: '', MarketPlace: '', Token: '' };
    @ViewChild('seller', { static: true }) Seller: ElementRef;
    @ViewChild('market', { static: true }) Market: ElementRef;
    @ViewChild('token', { static: true }) Token: ElementRef;

    constructor(
        private main: MainService,
        private onboardingService: OnboardingService,
        private fb: FormBuilder,
        private authService: AuthService,
        public dialogRef: MatDialogRef<ConnectMwsAccountComponent>
    ) { }

    ngOnInit() {
        this.authService.userInfo$.pipe(takeUntil(this.destroy$))
            .subscribe((usr: LoginModel) => {
                this.data.TenantId = usr.tenantId;
            });
        this.mwsAccountForm = this.fb.group({
            name: [this.isEdit ? this.account.name : null, Validators.required],
            oldName: [this.isEdit ? this.account.name : null],
            seller: [this.isEdit ? this.account.seller : null, Validators.required],
            marketPlace: [this.isEdit ? this.account.marketPlace : null, Validators.required],
            token: [this.isEdit ? this.account.token : null, Validators.required],
            domain: [this.isEdit ? this.account.domain : 'DomainTest', Validators.required],
        });
        this.onboardingService.AmazonLink().subscribe(value => this.amazonLink = value);
    }

    ngAfterViewInit() {
        const tempArray = [this.Seller, this.Market, this.Token];
        tempArray.forEach(data => this.inputDebounce(data.nativeElement));
    }

    public inputDebounce(input: any) {
        fromEvent(input, 'keyup').pipe(
            map(($e: any) => {
                let searched: string;
                if ($e.target.id === 'seller') {
                    searched = 'sellerID';
                } else if ($e.target.id === 'market') {
                    searched = 'marketplaceID';
                } else {
                    searched = 'tokenId';
                }
                if (this[searched] !== false) {
                    this.errorResolved = false;
                }
                this[searched] = { ...this[searched], ...{ searched: false } };
                return $e;
            })
            , filter(res => res.target.value.length > 0)
            , debounceTime(1000)
            , distinctUntilChanged()
            , takeUntil(this.destroy$)
        ).subscribe(($e) => this.checkVerifyStatus($e, $e.target.id));
    }


    onSaveClick() {
        this.showLoader1 = true;
        let request$: any;

        this.onboardingService.verifyCredential(this.data).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
            if (res !== null && res.length === 3) {
                this.sellerID = { ...this.sellerID, ...{ error: res[0] } };
                this.marketplaceID = { ...this.marketplaceID, ...{ error: res[1] } };
                this.tokenId = { ...this.tokenId, ...{ error: res[2] } };
                if (this.sellerID.error && this.marketplaceID.error && this.tokenId.error) {
                    this.errorResolved = true;
                    if (!this.isEdit) {
                        request$ = this.onboardingService.InsertAccount(this.mwsAccountForm.value as AccountModel);
                    } else {
                        request$ = this.onboardingService.UpdateAccount(this.mwsAccountForm.value as AccountModel);
                    }
                    request$.subscribe(() => {
                        // this.showLoader1 = false;
                        // this.dialogRef.close(true);
                    });
                    this.showLoader1 = false;
                    this.dialogRef.close(true);
                } else {
                    this.showLoader1 = false;
                }
            } else {
                this.showLoader1 = false;
            }
        });
    }

    checkVerifyStatus($e: any, type: string) {
        let searched: string;
        if (type === 'seller') {
            searched = 'sellerID';
            this.data = { ...this.data, ...{ Seller: $e.target.value } };
        } else if (type === 'market') {
            searched = 'marketplaceID';
            this.data = { ...this.data, ...{ MarketPlace: $e.target.value } };
        } else {
            searched = 'tokenId';
            this.data = { ...this.data, ...{ Token: $e.target.value } };
        }
        if (!this.data.Seller || !this.data.MarketPlace || !this.data.Token) { return; }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

}
