import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ch-history',
  templateUrl: './ch-history.component.html',
  styleUrls: ['./ch-history.component.scss']
})
export class ChHistoryComponent implements OnInit {
  public chHistoryType = 'sync';
  constructor() { }

  ngOnInit() {
  }

}
