import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import CryptoJS from 'crypto-js';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '../modules/shared/confirmation/confirmation.component';
import { StorageKeyEnum } from '../core/StorageKeyEnum';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  public filterChangeData: BehaviorSubject<string> = new BehaviorSubject('initial');
  public window = this.document.defaultView;
  public localStorageData = window.localStorage.getItem(StorageKeyEnum.DatavancedDetails);
  public bytes = this.localStorageData !== null ? CryptoJS.AES.decrypt(this.localStorageData.toString(), '123456') : null;
  public datavancedLoggedUser: any = JSON.parse(window.localStorage.getItem(StorageKeyEnum.User));
  public uiLoaderHideShow: Subject<boolean> = new Subject();
  public resetCommonSearch: Subject<any> = new Subject();
  public settingType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public inventoryType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public replenishType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public purchasingType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public orderType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public pricingType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public reportingType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public channelType$: BehaviorSubject<string> = new BehaviorSubject(null);
  public refreshGrid$: Subject<string> = new Subject();
  constructor(@Inject(DOCUMENT) public document: Document, public dialog: MatDialog) { }

  public filterData(query: string) {
    this.filterChangeData.next(query);
  }

  public openConfirmationPopup(text: string) {
    return this.dialog.open(ConfirmationComponent, {
      width: '399px',
      maxHeight: '80vh',
      maxWidth: '92vw',
      panelClass: 'confirmation_popup',
      data: { confirmation_text: text },
      disableClose: false,
      closeOnNavigation: true
    });
  }

  public setToLocalStorage(data: any) {
    this.window.localStorage.setItem(StorageKeyEnum.User, JSON.stringify(data));
  }

  public openBroadcastPopup(
    component: any, height: any, width: number, maxHeight: string, customClass: string,
    data?: any, disableClose: boolean = false) {
    this.dialog.closeAll();
    return this.dialog.open(component, {
      width: `${width}px`,
      height: height === 'auto' ? 'auto' : `${height}px`,
      maxHeight,
      maxWidth: '92vw',
      panelClass: customClass !== undefined ? customClass : '',
      data,
      disableClose,
      closeOnNavigation: true
    });
  }

  public openDD(e: any, dropdown: any, ddParent: any, additional = 15) {
    let style = `display: block;`;
    if ((this.window.innerHeight - e.pageY) <= this.window.innerHeight / 2) {
      style = `${style} bottom: ${this.window.innerHeight - (e.pageY - e.offsetY + (additional))}px;`;
    } else {
      if (e && e.pageY) {
        style = `${style} top: ${e.pageY - e.offsetY}px;`;
      } else {
        style = `${style} top: 20%;`;
      }
    }
    if ((this.window.innerWidth - e.pageX) <= this.window.innerWidth / 2) {
      style = `${style} right: ${this.window.innerWidth - (e.pageX + 5)}px;`;
    } else {
      if (e && e.pageY) {
        style = `${style} left: ${e.pageX - e.offsetX}px;`;
      } else {
        style = `${style} left: 50%;`;
      }
    }
    ddParent.style.cssText = style;
    if (dropdown !== null) { dropdown.style.cssText = `display: block`; }
  }


  public closeDD(dropdown: any, ddParent) {
    if (dropdown !== null) { dropdown.style.cssText = `display: none`; }
    ddParent.style.cssText = `display: none`;
  }

  public likeTooltip(e: any, globalTooltip: any, globalOverlay: any, isDefault = false) {
    if (!isDefault) {
      let css = `display: block; padding-top:${e.target.clientHeight + 5}px; top: ${e.pageY - e.offsetY}px;`;
      if ((this.window.innerWidth - e.pageX) <= this.window.innerWidth / 2) {
        css = `${css} right: ${this.window.innerWidth - (e.pageX + 5)}px;`;
      } else {
        css = `${css} left: ${e.pageX - e.offsetX}px;`;
      }
      globalTooltip.style.cssText = css;
    } else {
      globalTooltip.style.cssText = `display: block`;
    }
    globalOverlay.style.cssText = `display: block`;
  }

  public likeTooltipClose(globalTooltip: any, globalOverlay: any) {
    globalTooltip.style.cssText = globalOverlay.style.cssText = `display: none`;
  }

  public incDecValue(incDec: any, input: any) {
    const value = parseFloat(input.value);
    input.value = incDec === 'inc' ? input.value === '' ? 1 : value + 1 : input.value === '' ? 0 : value - 1;
  }

  public onlyNum(e: any) {
    if (e.key !== 'Backspace' && e.key !== '.') {
      const regex = /^[0-9]*$/gm;
      if (!regex.test((e.key))) {
        e.returnValue = false; e.preventDefault();
      }
    }
  }
}
