import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-popup-inventory-filter',
  templateUrl: './popup-inventory-filter.component.html',
  styleUrls: ['./popup-inventory-filter.component.scss']
})
export class PopupInventoryFilterComponent implements OnInit, OnDestroy {

  public setTimeoutInter: any;
  public search1 = '';
  public search2 = '';
  public radioOption = 'or';

  constructor(public main: MainService) { }

  ngOnInit() {
    this.search1 = ''; this.search2 = '';
  }

  public search() {
    if (this.search1 !== '' || this.search2 !== '') {
      clearTimeout(this.setTimeoutInter);
      this.setTimeoutInter = setTimeout(() => {
        const data: any[] = [this.search1, this.search2, this.radioOption];
        console.log(data);
        this.main.filterChangeData.next(JSON.stringify(data));
      }, 1000);
    } else {
      this.main.filterChangeData.next(JSON.stringify(''));
    }
  }

  ngOnDestroy() {
    this.main.filterChangeData.next('initial');
  }

}
