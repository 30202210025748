import { Component, OnInit, ViewChild, ElementRef, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MainService } from '../../../../services/main.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FbaMinManualSetPopupComponent } from '../../../shared/fba-min-manual-set-popup/fba-min-manual-set-popup.component';
import { SettingsService } from '../../../../services/settings.service';
import { InventoryService } from '../../../../services/inventory.service';

@Component({
  selector: 'app-ch-sku-unlisted',
  templateUrl: './ch-sku-unlisted.component.html',
  styleUrls: ['./ch-sku-unlisted.component.scss']
})
export class ChSkuUnlistedComponent implements OnInit, OnDestroy {

  public tableTab = 'history';
  public inventoryFilterData: any = [];
  public inventoryFilterSelected = '';
  public inventoryTableData: any = [];
  public untouchedInventoryTableData: any = [];
  public advancedFilterTableData: any = [];
  public max = 50;
  public checkUncheck = false;
  public firstTimeCheck = true;
  public selectInventoryTableData: number;
  public rightTableContent: any;
  public skuErrorCount: number;
  public shipmentHistoryData: any;
  public notesTypeAdd = false;
  public noteTypeData: any = {};
  public individualFilterType: string;
  public viewNoteData = '';
  public zoomedImage = '';
  public rightSideIsOpen = false;
  public isAdvFilterType = false;
  public tableHeading = ['SKU-ASIN', 'Image', 'Name', 'QTY', 'Status', 'Storefront', 'Listing Price', 'Date Added', 'Ship To FBA'];
  public showColumn: number[] = [...Array(this.tableHeading.length).fill(0).map((d, i) => i + 1)];
  public editNoteId: string;
  public noteForm: FormGroup = this.fb.group({
    Note: ['', Validators.required],
  });
  @ViewChild('imageWithSkuInfo') imageWithSkuInfo: ElementRef;
  @ViewChild('overlayCustomTooltip') overlayCustomTooltip: ElementRef;
  @ViewChild('imageZoomHover') imageZoomHover: ElementRef;
  @ViewChild('overlayImageZoomHover') overlayImageZoomHover: ElementRef;
  @ViewChildren('maxToSend') maxToSend: QueryList<any[]>;
  showAdvancedFilter = true;
  private destroy$ = new Subject<void>();

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public main: MainService,
    public fb: FormBuilder,
    private settingsService: SettingsService,
    private inventoryService: InventoryService) { }

  ngOnInit() {
    this.settingsService.GetAllAccounts().pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.inventoryFilterData = res;
      this.getInitialData();
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public getInventoryFilter($e) {
    if ($e.data) {
      if ($e.showAdvancedFilter !== undefined) {
        this.showAdvancedFilter = $e.showAdvancedFilter;
      }
      if ($e.inventoryFilterSelected !== undefined) {
        this.inventoryFilterSelected = $e.inventoryFilterSelected;
      }

      this.inventoryTableData = $e.data;
      const tempArray = $e.data.map(data => ({ ...data, ...{ checked: false, filter: true, adv_filter: true } }));
      this.inventoryTableData = tempArray;
      this.untouchedInventoryTableData = [...this.inventoryTableData];
      this.advancedFilterTableData = [...this.inventoryTableData];
      this.rightTableContent = this.inventoryTableData[0];
    } else {
      this.inventoryTableData = [];
      this.rightTableContent = [];
      this.untouchedInventoryTableData = [];
      this.advancedFilterTableData = [];
    }
    this.max = this.inventoryTableData.length > 50 ? 50 : this.inventoryTableData.length;
  }

  public getInitialData() {
    this.rightSideIsOpen = false;
    if (this.inventoryFilterData) {
      this.inventoryService.SkuByAccount(this.inventoryFilterData[0].name, false).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res !== null) {
          this.getInventoryFilter({ data: res });
        }
      });
    }
  }

  public increseMax() {
    if (this.inventoryTableData.length - this.max > 50) {
      this.max = this.max + 50;
    } else {
      this.max = this.max + this.inventoryTableData.length - this.max;
    }
  }

  public showColumnResult($e) {
    this.showColumn = $e;
  }

  opPopUp(e) {
    this.main.openBroadcastPopup(FbaMinManualSetPopupComponent, 'auto', 454, '90%', 'fba_check_settings');
    e.stopPropagation();
  }

}
