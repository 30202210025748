import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkuModel } from 'src/app/models/shipment.model';
import { PrintService } from '../../../services/print.service';
import { MainService } from '../../../services/main.service';

@Component({
  selector: 'app-popup-bulk-print-labels',
  templateUrl: './popup-bulk-print-labels.component.html',
  styleUrls: ['./popup-bulk-print-labels.component.scss']
})
export class PopupBulkPrintLabelsComponent implements OnInit {

  public skus: any[];
  public fromInventory: boolean;

  constructor(
    public dialogRef: MatDialogRef<PopupBulkPrintLabelsComponent>,
    private printService: PrintService,
    public main: MainService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    // this.skus = [...this.skus.filter(x => x.qty > 0)];
  }

  public onQprntChange(value: number, sku: SkuModel) {
    sku.qprnt = value;
    sku.qty = value;
  }

  public removeItem(sku: SkuModel) {
    this.skus.splice(this.skus.indexOf(sku), 1);
  }

  public onPrintClick() {
    this.skus.forEach(element => {
      element.qty = element.qty;
      element.qprnt = element.qty;
    });
    this.dialogRef.close();
    this.printService.setArrayData(this.skus);
    this.printService.printDocument('bulkskulabel');
  }

  public closePopup() {
    this.dialogRef.close();
  }
}
