import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-ch-connection-tab',
  templateUrl: './ch-connection-tab.component.html',
  styleUrls: ['./ch-connection-tab.component.scss']
})
export class ChConnectionTabComponent implements OnInit {
  @Output() closeEv = new EventEmitter();
  data = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dt) { }

  ngOnInit() {
    typeof this.dt === 'boolean' ? this.data = this.dt : this.data = false;
  }

  onCancel() {
    if (this.data) {
      this.closeEv.emit();
    } else {
      return;
    }
  }
}
