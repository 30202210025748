import { Component, OnDestroy } from '@angular/core';
import { ToastService } from './core/toast.service';
import { PrintService } from './services/print.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainService } from './services/main.service';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  public title = 'datavanced';
  public toast: boolean;
  public message: string;
  public toastInterval: any;
  public show = true;
  public isErr = false;
  private destroy$ = new Subject<void>();

  constructor(
    toastService: ToastService,
    public printService: PrintService,
    private titleService: Title,
    public main: MainService,
    public router: Router) {

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const index = this.getTitle(router.routerState, router.routerState.root).length - 1;
        const title = this.getTitle(router.routerState, router.routerState.root)[index];
        titleService.setTitle(`nineyard-${title}`);
      }
    });

    toastService.mtoast.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.toast = true;
          this.isErr = false;
          this.message = value;
          this.toastInterval = setTimeout(() => this.closeToast(), 3000);
        }
      });

    toastService.mtoastErr.pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value) {
          this.toast = true;
          this.isErr = true;
          this.message = value;
          this.toastInterval = setTimeout(() => this.closeToast(), 3000);
        }
      });

    main.uiLoaderHideShow.pipe(takeUntil(this.destroy$)).subscribe(show => {
      console.log(show);
      this.show = show;
    });

    router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.show) { this.show = true; }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onActivate(event) {
    window.scroll(0, 0);
    if (document.getElementsByClassName('cdk-overlay-backdrop-showing').length !== 0) {
      const backdrop = document.getElementsByClassName('cdk-overlay-backdrop-showing')[0] as HTMLElement;
      backdrop.click();
    }
  }

  public closeToast() {
    clearTimeout(this.toastInterval);
    this.toast = false;
    this.message = null;
  }

  private getTitle(state: any, parent: any) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
}
