import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-shipment-see-more',
  templateUrl: './shipment-see-more.component.html',
  styleUrls: ['./shipment-see-more.component.scss']
})
export class ShipmentSeeMoreComponent implements OnInit {

  ship: any;
  skuId: string;
  qty: string;

  constructor(public dialogRef: MatDialogRef<ShipmentSeeMoreComponent>) { }

  ngOnInit(): void {
  }

  public getState(shipTo: string) {
    if (shipTo) {
      const arr = shipTo.split(',');
      if (arr.length >= 3) {
        return arr[arr.length - 2];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public getCity(shipTo: string) {
    if (shipTo) {
      const arr = shipTo.split(',');
      if (arr.length >= 3) {
        return arr[arr.length - 3];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public closePopup() {
    this.dialogRef.close();
  }

}
