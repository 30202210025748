import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit {
  @Input() control: any;
  @Output() valueChange: EventEmitter<number> = new EventEmitter();
  public inputValue: number = null;
  constructor() { }

  ngOnInit() {
    this.inputValue = this.control.value;
  }

  public changeValue() {
    this.valueChange.emit(this.inputValue);
  }

  public incDecValue(incDec, model) {
    const value = this[model] !== null ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === null ? 1 : value + 1 : this[model] === null ? 0 : value > 0 ? value - 1 : 0;
    this.changeValue();
  }
}
