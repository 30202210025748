import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-ch-dashboard',
  templateUrl: './ch-dashboard.component.html',
  styleUrls: ['./ch-dashboard.component.scss']
})
export class ChDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.initLineChart();
    this.initPiChart();
  }

  initLineChart() {
    const canvas: any = document.getElementById('myChart');
    const ctx = canvas.getContext('2d');
  }

  initPiChart() {
    const canvas: any = document.getElementById('piChart');
    const ctx = canvas.getContext('2d');
  }
}
