import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// declare let Hammer: any;
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-h-recogniser',
  templateUrl: './h-recogniser.component.html',
  styleUrls: ['./h-recogniser.component.scss']
})
export class HRecogniserComponent implements OnInit {
  @Input() id: any;
  @Input() type: any;
  @Output() adjustWidthEmit: EventEmitter<any> = new EventEmitter();
  // public subscribe3;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    let pageX, currentWidth, allItem;
    let myElement = document.getElementById(this.id);
    let subscribe; let subscribe3; let subscribe2;

    let start = () => {
      let source = fromEvent(document, 'mousemove');
      let example = source.pipe(map(e => e['pageX'] - pageX));
      subscribe = example.subscribe(val => this.adjustWidthEmit.emit({ x: val, id: this.id, currentWidth: currentWidth, allItem: allItem }));
      if (subscribe2) subscribe2.unsubscribe();
      if (subscribe3) subscribe3.unsubscribe();
      // console.log('start');

    }

    let stop = () => {
      let source2 = fromEvent(document, 'mouseup');
      let example2 = source2.pipe(map(e => e['pageX'] - pageX));
      subscribe2 = example2.subscribe(val => {
        pageX = undefined;
        if (subscribe) subscribe.unsubscribe();
        if (subscribe2) subscribe2.unsubscribe();
        myElement.style.backgroundColor = 'transparent';

        // console.log('stop');
        ready();
      });

    }

    let ready = () => {
      let source3 = fromEvent(myElement, 'mousedown');
      let example3 = source3.pipe(map(e => e['pageX']));
      subscribe3 = example3.subscribe(val => {
        // console.log(this.type + this.id)
        myElement.style.backgroundColor = 'grey';
        let el = document.getElementById(this.type + this.id);
        currentWidth = el.clientWidth; allItem = document.querySelectorAll('.' + el.className.split(' ').join('.'));
        pageX = val;
        if (subscribe) subscribe.unsubscribe();
        if (subscribe3) subscribe3.unsubscribe();
        // console.log('ready');
        start(); stop();
      });
    }
    // ready();
  }


  ngOnDestroy() {

  }


}
