import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ch-sku',
  templateUrl: './ch-sku.component.html',
  styleUrls: ['./ch-sku.component.scss']
})
export class ChSkuComponent implements OnInit {
  public chSkuType = 'all';
  constructor() { }

  ngOnInit() {
  }

}
