import { Component, OnInit, Inject } from '@angular/core';

import { MainService } from '../../../services/main.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-new-map-price-schedule-pop-up',
  templateUrl: './edit-new-map-price-schedule-pop-up.component.html',
  styleUrls: ['./edit-new-map-price-schedule-pop-up.component.scss']
})
export class EditNewMapPriceSchedulePopUpComponent implements OnInit {
  public id;
  public model: number;
  public name: string;
  public type: number;
  public timeFrom: string;
  public timeTo: string;
  public ignore: any;
  public ignHide: any;
  public ison: any;
  public dateTo: any;
  public dateFrom: any;
  public wDays;
  public d = [];

  public sun: any;
  public mon: any;
  public tue: any;
  public wed: any;
  public thu: any;
  public fri: any;
  public sat: any;
  public allDays: any;
  public weekDays: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public main: MainService,
    private dialog: MatDialogRef<EditNewMapPriceSchedulePopUpComponent>) { }

  ngOnInit() {
    console.log(this.data);
    this.getParams();
  }

  closeDialog() {
    this.dialog.close();
  }

  getParams() {
    if (this.data != null) {
      const mapModel = this.data.mapModel[0];
      this.id = this.data.prcModels.id;
      this.model = mapModel.model;
      this.name = mapModel.name;
      this.type = mapModel.type;
      this.timeFrom = mapModel.timeFrom;
      this.timeTo = mapModel.timeTo;
      this.dateFrom = mapModel.dateFrom;
      this.dateTo = mapModel.dateTo;
      this.ignore = this.toNumber(mapModel.ignore);
      this.ignHide = this.toNumber(mapModel.ignHide);
      this.ison = this.toNumber(mapModel.ison);
      this.wDays = mapModel.wDays;
      this.wDays = this.wDays.trim();
      this.weekDays = this.wDays.match(/.{1,1}/g);
      this.weekDays.forEach((data) => {
        this.getSelectedDays(Number(data));
      });
    }
  }



  public save() {
    this.getSelectedWeekDays();
    /*this.priceService.savePrcModel(data).pipe(takeUntil(this.destroy$)).subscribe((value) => {
      console.log(value);
    });*/
  }


  public toBoolean(value: number) {
    return Boolean(value);
  }
  public toNumber(value: boolean) {
    return Number(value);
  }

  getSelectedWeekDays() {
    this.wDays = '';
    if (this.allDays) {
      this.wDays = '1234567';
    } else {
      if (this.sun) {
        this.wDays = '1';
      }
      if (this.mon) {
        this.wDays += '2';
      }
      if (this.tue) {
        this.wDays += '3';
      }
      if (this.wed) {
        this.wDays += '4';
      }
      if (this.thu) {
        this.wDays += '5';
      }
      if (this.fri) {
        this.wDays += '6';
      }
      if (this.sat) {
        this.wDays += '7';
      }
    }
  }

  getSelectedDays(dayNumber: number) {
    switch (dayNumber) {
      case 1:
        this.sun = true;
        break;
      case 2:
        this.mon = true;
        break;
      case 3:
        this.tue = true;
        break;
      case 4:
        this.wed = true;
        break;
      case 5:
        this.thu = true;
        break;
      case 6:
        this.fri = true;
        break;
      case 7:
        this.sat = true;
        break;
    }
  }

}
