import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SkuModel, GetBoxesRequestModel, SplitBoxSkuRequestModel } from 'src/app/models/shipment.model';
import { ShipmentService } from 'src/app/services/shipment.service';
import { ToastService } from 'src/app/core/toast.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popup-split-box',
  templateUrl: './popup-split-box.component.html',
  styleUrls: ['./popup-split-box.component.scss']
})
export class PopupSplitBoxComponent implements OnInit, OnDestroy {
  sku: SkuModel;
  account: string;
  isOverszd: number;
  ship: string;
  ddBoxesValues: number[];
  inputBox: string;
  inputQty: string;
  totalQty: number;
  remainQty: number;
  boxList = [];
  showdd: number;
  private destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<PopupSplitBoxComponent>,
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    const data: GetBoxesRequestModel = {
      account: this.account,
      isOverszd: this.isOverszd,
      ship: this.ship
    };
    this.shipmentService.GetListBoxes(data)
      .pipe(takeUntil(this.destroy$)).subscribe((value: number[]) => {
        if (value) {
          this.ddBoxesValues = [...value];
          this.ddBoxesValues.unshift(-1);
          this.ddBoxesValues.push(-2);
        }
      });

    this.totalQty = this.sku.qty;
    this.remainQty = this.sku.qty - this.totalQty;
    this.boxList.push({ name: `New Box`, qty: this.sku.qty });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onAddBoxesClick() {
    if (this.inputBox && this.inputQty) {

      let tmpIndex = 1;

      this.boxList.forEach(element => {
        element.name = `New Box (${tmpIndex++})`;
      });

      for (let i = 0; i < parseInt(this.inputBox, 10); i++) {
        this.boxList.push({ name: `New Box (${tmpIndex++})`, qty: parseInt(this.inputQty, 10) });
      }

      let cnt = 0;
      this.boxList.forEach(element => {
        cnt += parseInt(element.qty, 10);
      });
      this.totalQty = cnt;
      this.remainQty = this.sku.qty - cnt;
    }
  }

  public onAddSingleBox() {
    this.boxList.push({ name: `New Box`, qty: 0 });
  }

  public onReplaceClick() {
    this.boxList = [...[]];
    for (let i = 0; i < parseInt(this.inputBox, 10); i++) {
      this.boxList.push({ name: `New Box (${i + 1})`, qty: parseInt(this.inputQty, 10) });
    }

    let cnt = 0;
    this.boxList.forEach(element => {
      cnt += parseInt(element.qty, 10);
    });
    this.totalQty = cnt;
    this.remainQty = this.sku.qty - cnt;
  }

  public onQtyChange(item: any, value: any) {
    if (!value) {
      value = 0;
    }
    item.qty = parseInt(value, 10);
    let cnt = 0;
    this.boxList.forEach(element => {
      cnt += parseInt(element.qty, 10);
    });
    this.totalQty = cnt;
    this.remainQty = this.sku.qty - cnt;
  }

  public removeItem(item: any) {
    this.boxList.splice(this.boxList.indexOf(item), 1);
    let cnt = 0;
    this.boxList.forEach(element => {
      cnt += parseInt(element.qty, 10);
    });
    this.totalQty = cnt;
    this.remainQty = this.sku.qty - cnt;
  }

  public isOptionDesabled(bxNum: any) {
    if (bxNum) {
      return this.boxList.some(x => x.name === bxNum.toString());
    } else {
      return true;
    }
  }

  public onBoxDropdownChange(value: string, item: any) {
    if (value === '-1') {
      // this.boxList.push({ name: `New Box`, qty: 0 });
    } else if (value === '-2') {
      item.name = 'UnPack';
    } else {
      const tmpArray = this.boxList.filter(rec => {
        if (this.ddBoxesValues.filter(val => val ? val.toString() : '' === rec.name).length > 0) {
          return true;
        } else {
          return false;
        }
      });
      item.name = value;
      if (!tmpArray.some(x => x.name === value)) {
        tmpArray.push(item);
      }

      tmpArray.forEach(element => {
        this.boxList.splice(this.boxList.indexOf(element), 1);
      });

      let tmpIndex = 1;
      this.boxList.forEach(element => {
        element.name = `New Box (${tmpIndex++})`;
      });

      this.boxList = [...tmpArray.concat(this.boxList)];
    }
  }

  public onSaveClick(value: string) {
    if (this.boxList.length > 0) {
      let cnt = 0;
      this.boxList.forEach(element => {
        cnt += parseInt(element.qty, 10);
      });
      if (cnt > this.sku.qty) {
        this.toastService.showErrorToaster('Summary is more than Qty');
      } else {
        const names = this.boxList.map(e => e.name);
        const qtys = this.boxList.map(e => e.qty);
        const data: SplitBoxSkuRequestModel = {
          box: 421,
          toPrint: this.sku.qprnt,
          oldQty: this.sku.qty,
          summa: cnt,
          ship: this.ship,
          sku: this.sku.skuId,
          account: this.account,
          isOverszd: this.isOverszd,
          boxes: names,
          qtys
        };
        this.shipmentService.SplitBoxSku(data)
          .pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.toastService.showToaster('Operation Successful');
            this.dialogRef.close(value);
          });
      }
    }
  }

  public showDropdown(e: number) {
    this.showdd = e;
  }

  public hideDropdown() {
    this.showdd = null;
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public incDecValue(incDec, model) {
    const value = this[model] !== undefined ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === undefined ? 1 : value + 1 : this[model] === undefined ? 0 : value > 0 ? value - 1 : 0;
  }

}
