import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { LoginModel } from 'src/app/models/login.model';
import { AccountModel, WarehouseModel } from 'src/app/models/onboarding.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConnectMwsAccountComponent } from '../../shared/connect-mws-account/connect-mws-account.component';
import { StorageKeyEnum } from 'src/app/core/StorageKeyEnum';
import { CreditCardValidators } from 'angular-cc-library';

@Component({
  selector: 'app-onboarding-questions',
  templateUrl: './onboarding-questions.component.html',
  styleUrls: ['./onboarding-questions.component.scss']
})
export class OnboardingQuestionsComponent implements OnInit, OnDestroy {
  @ViewChild('mws') mwsComp: ConnectMwsAccountComponent;
  @ViewChild('btnSubmit') btnSubmit: ElementRef;
  public id = 1;
  public isLoading = false;
  public routerSubs: any;
  public warehouseForm: FormGroup;
  public username: string;
  public termsConditionsForm: FormGroup;
  private destroy$ = new Subject<void>();
  public i = 0;
  public ccForm: FormGroup;
  public stateStore: any[];
  public states: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private onboardingService: OnboardingService,
    private fb: FormBuilder) {
    this.routerSubs = this.router.events.pipe(takeUntil(this.destroy$)).subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
        if (this.id === 7) { this.timeOut(); }
      }
    });
  }

  ngOnInit() {
    if (!window.history.state.redirect) {
      this.router.navigate(['home']);
    }
    this.onboardingService.getStates().subscribe((data: any[]) => {
      this.stateStore = [...data];
      this.states = [...data];
    });
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
        this.btnSubmit.nativeElement.click();
      }
    });
    const user = JSON.parse(localStorage.getItem(StorageKeyEnum.User)) as LoginModel;
    this.username = user.username;

    this.warehouseForm = this.fb.group({
      Id: ['LCL'],
      Name: [null, Validators.required],
      Addressln1: [null, Validators.required],
      Addressln2: [null],
      City: [null, Validators.required],
      State: [null, Validators.required],
      Postal: [null, Validators.required],
      Country: ['United States', Validators.required],
      CountryCode: ['US', Validators.required],
      // Priority: [null, Validators.required],
      Isfba: [1, Validators.required],
      Isfbm: [1, Validators.required],
      Isvendor: [1, Validators.required],
    });

    this.termsConditionsForm = this.fb.group({
      IsAgree: [null, Validators.required]
    });

    this.ccForm = this.fb.group({
      name: ['', [Validators.required]],
      creditCard: ['', [Validators.required, CreditCardValidators.validateCCNumber]],
      expirationDate: ['', [Validators.required, CreditCardValidators.validateExpDate]],
      cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]]
    });
  }

  public filterStates(value: string) {
    if (value) {
      this.states = [...this.stateStore.filter(x => x.name.toLowerCase().indexOf(value) > -1)];
    } else {
      this.states = [...this.stateStore];
    }
  }

  timeOut() {
    setTimeout(() => {
      this.i = this.i + 1;
      if (this.i < 4) { this.timeOut(); }
    }, 5000);
  }

  ngOnDestroy(): void {
    this.routerSubs.unsubscribe();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public ccFormSubmit() {
    this.router.navigate(['/onboarding-question/', 6]);
  }

  public submitMwsAccountForm() {
    this.isLoading = true;
    this.onboardingService.verifyCredential(this.mwsComp.data).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      if (res !== null && res.length === 3) {
        this.mwsComp.sellerID = { ...this.mwsComp.sellerID, ...{ error: res[0] } };
        this.mwsComp.marketplaceID = { ...this.mwsComp.marketplaceID, ...{ error: res[1] } };
        this.mwsComp.tokenId = { ...this.mwsComp.tokenId, ...{ error: res[2] } };
        if (this.mwsComp.sellerID.error && this.mwsComp.marketplaceID.error && this.mwsComp.tokenId.error) {
          this.mwsComp.errorResolved = true;
          this.onboardingService.InsertAccount(this.mwsComp.mwsAccountForm.value as AccountModel)
            .pipe(takeUntil(this.destroy$)).subscribe(() => {
              // this.isLoading = false;
              // this.router.navigate(['/onboarding-question/', 4]);
            });
          this.isLoading = false;
          this.router.navigate(['/onboarding-question/', 4]);
        } else {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    }, () => this.isLoading = false);
  }

  public submitwarehouseForm() {
    const data = this.warehouseForm.value as WarehouseModel;
    this.onboardingService.InsertWarehouse(data).pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate(['/onboarding-question/', 5]));
  }

  public submitTermsConditionsForm() {
    const isAgree = this.termsConditionsForm.get('IsAgree').value;
    if (isAgree) {
      this.router.navigate(['/onboarding-question/', 7]);
    }
  }
}
