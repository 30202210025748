import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @ViewChild('delete', { static: true }) delete: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    private fb: FormBuilder,
    public main: MainService
  ) { }

  ngOnInit() {
    setTimeout(() => this.delete.nativeElement.focus(), 10);
  }

  closeDialog(result) {
    this.dialogRef.close(result);
  }

}
