import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-common-search',
  templateUrl: './common-search.component.html',
  styleUrls: ['./common-search.component.scss']
})
export class CommonSearchComponent implements OnInit, OnDestroy {
  public setTimeoutInter: any;
  public search1 = '';
  public search2 = '';
  public radioOption = 'or';
  public select1 = '==';
  public select2 = '==';
  public dataType: string;
  private destroy$ = new Subject<void>();
  @Output() closeColumnFilter: EventEmitter<any> = new EventEmitter();

  constructor(public main: MainService) { }

  ngOnInit() {
    this.main.resetCommonSearch.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data) {
        this.dataType = data;
        this.search1 = '';
        this.search2 = '';
        this.radioOption = 'or';
        this.select1 = '==';
        this.select2 = '==';
        this.main.filterChangeData.next(JSON.stringify(''));
      }
    });
  }

  public search() {
    if (this.search1 !== '' || this.search2 !== '') {
      clearTimeout(this.setTimeoutInter);
      this.setTimeoutInter = setTimeout(() => {
        const data: any[] = [this.search1, this.search2, this.radioOption, this.select1, this.select2];
        this.main.filterChangeData.next(JSON.stringify(data));
      }, 1000);
    } else {
      this.main.filterChangeData.next(JSON.stringify(''));
    }
  }

  ngOnDestroy(): void {
    this.main.filterChangeData.next('initial');
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  close() {
    this.closeColumnFilter.emit();
  }

}

