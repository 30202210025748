import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/authentication/login/login.component';
import { SignupComponent } from './modules/authentication/signup/signup.component';
import { ChangePasswordComponent } from './modules/authentication/change-password/change-password.component';
import { ForgotPasswordComponent } from './modules/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/authentication/reset-password/reset-password.component';
import { RegisterTeamMemberComponent } from './modules/authentication/register-team-member/register-team-member.component';
import { TwoFactorVerificationComponent } from './modules/authentication/two-factor-verification/two-factor-verification.component';
import { OnboardingQuestionsComponent } from './modules/authentication/onboarding-questions/onboarding-questions.component';
import { AuthGuard } from './core/auth.guard';
import { InviteTeamMembersComponent } from './modules/authentication/invite-team-members/invite-team-members.component';
import { PrintLayoutComponent } from './modules/print/print-layout/print-layout.component';
import { SkuLabelPrintComponent } from './modules/print/print-layout/sku-label-print/sku-label-print.component';
import { BoxLabelPrintComponent } from './modules/print/print-layout/box-label-print/box-label-print.component';
import { BulkSkuLabelPrintComponent } from './modules/print/print-layout/bulk-sku-label-print/bulk-sku-label-print.component';
import { AdvisorLoginComponent } from './modules/authentication/advisor-login/advisor-login.component';
import { AdvisorSignupComponent } from './modules/authentication/advisor-signup/advisor-signup.component';
import { TermsAndConditionsComponent } from './modules/authentication/terms-and-conditions/terms-and-conditions.component';
import { PickListPrintComponent } from './modules/print/print-layout/pick-list-print/pick-list-print.component';
import { PrivacyPolicyComponent } from './modules/authentication/privacy-policy/privacy-policy.component';
import { HelpPageComponent } from './modules/authentication/help-page/help-page.component';
import {BoxLabelPrint2dComponent} from './modules/print/print-layout/box-label-print-2d/box-label-print-2d.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'termsandconditions', component: TermsAndConditionsComponent, data: { title: 'Term And Condition' } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  { path: 'sign-up', component: SignupComponent, data: { title: 'Sign Up' } },
  { path: 'help-page', component: HelpPageComponent, data: { title: 'Help Page' } },
  { path: 'adviser-login', component: AdvisorLoginComponent, data: { title: 'Adviser Login' } },
  { path: 'adviser-signup', component: AdvisorSignupComponent, data: { title: 'Adviser Signup' } },
  { path: 'reset-password', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { title: 'Change Password' } },
  { path: 'invite-team-members', component: InviteTeamMembersComponent, canActivate: [AuthGuard], data: { title: 'Invite Team Members' } },
  {
    path: 'register-team-member', component: RegisterTeamMemberComponent, canActivate: [AuthGuard],
    data: { title: 'Register Team Members' }
  },
  { path: 'two-factor-verification', component: TwoFactorVerificationComponent, data: { title: 'Two Factor Verification' } },
  {
    path: 'onboarding-question/:id', component: OnboardingQuestionsComponent, canActivate: [AuthGuard],
    data: { title: 'Onboarding Question' }
  },
  {
    path: 'print', outlet: 'print', component: PrintLayoutComponent,
    children: [
      { path: 'skulabel/:data', component: SkuLabelPrintComponent },
      { path: 'boxlabel/:data', component: BoxLabelPrintComponent },
      { path: 'boxlabel2d/:data', component: BoxLabelPrint2dComponent },
      { path: 'bulkskulabel', component: BulkSkuLabelPrintComponent },
      { path: 'picklistprint', component: PickListPrintComponent },
    ]
  },
  {
    path: 'print-preview', component: BoxLabelPrintComponent
  },
  {
    path: 'logistics',
    loadChildren: () => import('./modules/logistics/logistics.module').then(m => m.LogisticsModule),
    canActivate: [AuthGuard],
    data: { title: 'Shipments' }
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard],
    data: { title: 'Home' }
  },
  {
    path: 'inventory',
    loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
    canActivate: [AuthGuard],
    data: { title: 'Inventory' }
  },
  {
    path: 'support',
    loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
    data: { title: 'Support' }
  },
  {
    path: 'order',
    loadChildren: () => import('./modules/order-new/order-new.module').then(m => m.OrderNewModule),
    data: { title: 'Order' }
  },
  {
    path: 'order-new',
    loadChildren: () => import('./modules/order-new/order-new.module').then(m => m.OrderNewModule),
    data: { title: 'Order' }
  },
  {
    path: 'purchasing',
    loadChildren: () => import('./modules/purchasing/purchasing.module').then(m => m.PurchasingModule),
    canActivate: [AuthGuard],
    data: { title: 'Purchasing' }
  },
  {
    path: 'replenish',
    loadChildren: () => import('./modules/replenish/replenish.module').then(m => m.ReplenishModule),
    canActivate: [AuthGuard],
    data: { title: 'Replenish' }
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuard],
    data: { title: 'Settings' }
  },
  {
    path: 'sku-imports',
    loadChildren: () => import('./modules/sku-imports/sku-imports.module').then(m => m.SkuImportsModule),
    canActivate: [AuthGuard],
    data: { title: 'Sku Imports' }
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule),
    data: { title: 'Report' }
  },
  {
    path: 'pricing',
    loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule),
    data: { title: 'Pricing' }
  },
  {
    path: 'channels',
    loadChildren: () => import('./modules/channels/channels.module').then(m => m.ChannelsModule),
    data: { title: 'Channels' }
  },
  {
    path: 'adviser',
    loadChildren: () => import('./modules/adviser/adviser.module').then(m => m.AdviserModule),
    data: { title: 'Adviser' }
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
