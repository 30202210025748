import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ch-settings',
  templateUrl: './ch-settings.component.html',
  styleUrls: ['./ch-settings.component.scss']
})
export class ChSettingsComponent implements OnInit {
  channelSettingsType = 'connection';
  data = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dt: boolean, private dialog: MatDialogRef<ChSettingsComponent>) { }

  ngOnInit() {
    typeof this.dt === 'boolean' ? this.data = this.dt : this.data = false;
  }

  closeDialog() {
    if (this.data) {
      this.dialog.close();
    } else {
      return;
    }
  }
}
