import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-history',
  templateUrl: './import-history.component.html',
  styleUrls: ['./import-history.component.scss']
})
export class ImportHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
