import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BoxesByFilterModel } from 'src/app/models/shipment.model';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-print-box-label',
  templateUrl: './print-box-label.component.html',
  styleUrls: ['./print-box-label.component.scss']
})
export class PrintBoxLabelComponent implements OnInit {

  public box: BoxesByFilterModel;
  public lastBox: BoxesByFilterModel;
  public isOverszd: number;
  public qty = 1;

  constructor(
    public dialogRef: MatDialogRef<PrintBoxLabelComponent>,
    private printService: PrintService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
  }

  public onPrintBoxLabel() {
    let data: string[];
    data = [
      this.box.ship,
      this.box.account,
      this.box.firstBox.toString(),
      this.lastBox ? this.lastBox.firstBox.toString() : this.box.secondBox.toString(),
      this.isOverszd.toString(),
    ];
    if (this.qty && this.qty > 0) {
      data.push(this.qty.toString());
      this.printService.printDocument('boxlabel', data);
      this.dialogRef.close();
    }
  }

  public closePopup() {
    this.dialogRef.close();
  }


  public incDecValue(incDec, model, box = 'box') {
    if (box !== null) {
      if (box === 'box') {
        const value = parseFloat(this.box[model]);
        this.box[model] =
          incDec === 'inc' ? this.box[model] === '' ? 1 : value + 1 : this.box[model] === '' ? 0 : value > 0 ? value - 1 : 0;
      } else if (box === 'lastBox') {
        const value = parseFloat(this.lastBox[model]);
        this.lastBox[model] =
          incDec === 'inc' ? this.lastBox[model] === '' ? 1 : value + 1 : this.lastBox[model] === '' ? 0 : value > 0 ? value - 1 : 0;
      }
    } else {
      const value = parseFloat(this[model]);
      this[model] = incDec === 'inc' ? this[model] === '' ? 1 : value + 1 : this[model] === '' ? 0 : value > 0 ? value - 1 : 0;
    }
  }
}
