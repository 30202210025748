import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-inventory-product-tab2',
  templateUrl: './popup-inventory-product-tab2.component.html',
  styleUrls: ['./popup-inventory-product-tab2.component.scss']
})
export class PopupInventoryProductTab2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
