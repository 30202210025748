import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { RegisterMemberModel, LoginModel } from 'src/app/models/login.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-register-team-member',
  templateUrl: './register-team-member.component.html',
  styleUrls: ['./register-team-member.component.scss']
})
export class RegisterTeamMemberComponent implements OnInit, OnDestroy {

  public registerTeamMemberForm: FormGroup;
  public emailErrorMessage: string;
  public usernameErrorMessage: string;
  private destroy$ = new Subject<void>();
  private userInfo: LoginModel;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.authService.userInfo$.subscribe(res => this.userInfo = res);
    this.registerTeamMemberForm = this.fb.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    }, { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public verifyUsername() {
    const username = this.registerTeamMemberForm.get('username').value;
    if (username) {
      this.authService.verifyUsername(username)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.usernameErrorMessage = null,
          (err) => this.usernameErrorMessage = err.error.message);

    } else {
      this.usernameErrorMessage = null;
    }
  }

  public verifyEmail() {
    const email = this.registerTeamMemberForm.get('email').value;
    if (email) {
      if (this.registerTeamMemberForm.get('email').valid) {
        // this.authService.verifyMemberEmail(email)
        //   .pipe(takeUntil(this.destroy$))
        //   .subscribe((value: boolean) => {
        //     if (value) {
        //       this.emailErrorMessage = null;
        //     } else {
        //       this.emailErrorMessage = 'Email is already registered with another user';
        //     }
        //   });
        this.authService.verifyEmail(email)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.emailErrorMessage = null;
          }, (err) => {
            if (err.status) {
              this.emailErrorMessage = 'Email is already registered with another user';
            }
          });
      }

    } else {
      this.emailErrorMessage = null;
    }
  }

  public onSubmit() {
    const data = this.registerTeamMemberForm.value as RegisterMemberModel;
    data.tenantId = this.userInfo.tenantId;
    this.authService.registerMember(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.router.navigate(['/login']));
  }

  private checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      const passwordInput = group.controls[passwordKey];
      const passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

}
