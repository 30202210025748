import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShipmentService } from 'src/app/services/shipment.service';
import { ShipmentModel } from 'src/app/models/shipment.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastService } from 'src/app/core/toast.service';

@Component({
  selector: 'app-popup-add-to-logistics',
  templateUrl: './popup-add-to-logistics.component.html',
  styleUrls: ['./popup-add-to-logistics.component.scss']
})
export class PopupAddToLogisticsComponent implements OnInit {

  public whs: string[];
  public wh: string;
  public shipment: ShipmentModel;
  private destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<PopupAddToLogisticsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private shipmentService: ShipmentService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.shipmentService.GetWhList().subscribe((whs: string[]) => this.whs = [...whs]);
    this.wh = this.shipment.wh;
  }

  public whChanged() {
    this.shipment.wh = this.wh;
    this.shipmentService.UpdateShipmentWh(this.shipment)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closePopup();
        this.toastService.showToaster('Warehouse updated successfully');
      });
  }

  public closePopup() {
    this.dialogRef.close();
  }
}
