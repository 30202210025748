import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-no-return-yet',
  templateUrl: './popup-no-return-yet.component.html',
  styleUrls: ['./popup-no-return-yet.component.scss']
})
export class PopupNoReturnYetComponent implements OnInit {
  public orderType = 'order';

  constructor() { }

  ngOnInit() {
  }

}
