import { Component, OnDestroy } from '@angular/core';
import { ShipmentService } from 'src/app/services/shipment.service';
import { ToastService } from 'src/app/core/toast.service';
import { UpdateSkuQtyRequestModel, UpdateBoxSkuQtyRequestModel } from 'src/app/models/shipment.model';
import { MatDialogRef } from '@angular/material/dialog';
import { MainService } from '../../../services/main.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popup-update-qty',
  templateUrl: './popup-update-qty.component.html',
  styleUrls: ['./popup-update-qty.component.scss']
})
export class PopupUpdateQtyComponent implements OnDestroy {
  public skuId: string;
  public oldQty: number;
  public account: string;
  public isOverszd: number;
  public ship: string;
  public qty: string;
  public box: any;
  public ddBoxesValues: number[];
  private destroy$ = new Subject<void>();

  constructor(
    private shipmentService: ShipmentService,
    private toastService: ToastService,
    public main: MainService,
    public dialogRef: MatDialogRef<PopupUpdateQtyComponent>) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onSaveClick() {
    if (this.box) {
      const data: UpdateBoxSkuQtyRequestModel = {
        account: this.account,
        sku: this.skuId,
        ship: this.ship,
        box: parseInt(this.box, 10),
        isOverszd: this.isOverszd,
        qty: parseInt(this.qty, 10),
        oldQty: this.oldQty
      };
      this.shipmentService.UpdateBoxSkuQty(data)
        .pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.toastService.showToaster('Quantity Updated Successfully.');
          this.dialogRef.close(true);
        });
    } else {
      const data: UpdateSkuQtyRequestModel = {
        account: this.account,
        sku: this.skuId,
        isOverszd: this.isOverszd,
        qty: parseInt(this.qty, 10)
      };
      this.shipmentService.UpdateSkuQty(data)
        .pipe(takeUntil(this.destroy$)).subscribe(() => {
          this.toastService.showToaster('Quantity Updated Successfully.');
          this.dialogRef.close(true);
        });
    }
  }

  public closePopup() {
    this.dialogRef.close();
  }

  public incDecValue(incDec, model) {
    const value = this[model] !== undefined ? parseFloat(this[model]) : 0;
    this[model] = incDec === 'inc' ? this[model] === undefined ? 1 : value + 1 : this[model] === undefined ? 0 : value > 0 ? value - 1 : 0;
  }

  public isQtyInvalid(qty: string) {
    return parseInt(qty, 10) < 0;
  }

}
